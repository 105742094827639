@import '~src/styles/ui-kit/variables';

ngx-hm-carousel.hol-carousel {
  .transition {
    transition: all 0.3s ease-in-out !important;
  }
  [ngx-hm-carousel-container] {
    display: flex;
    padding-bottom: 4 * $content-padding;
  }
  .ball {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    border: 1px solid $primary-color;

    &.selected {
      background: $primary-color;
    }
  }
  .direction {
    opacity: 0;
  }
  &:hover {
    .direction {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
}
