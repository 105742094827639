@import '~src/styles/ui-kit/variables';

button.more-small {
  margin-top: 5px;
  font-size: 12px;
  padding: 5px;
  height: 20px;
  line-height: 0px !important;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    span {
      margin-bottom: 1px;
    }
  }
}

mat-button-toggle,
button {
  &.indicator-button {
    $indicator-colors: (
      $highest-criticity-color,
      $high-criticity-color,
      $medium-criticity-color,
      $lowest-criticity-color,
      $no-info-criticity-color
    );

    height: 25px;
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 11px;

    color: nth($indicator-colors, 5);
    background-color: white;
    border: 1px solid nth($indicator-colors, 5);
    border-radius: 4px;

    font-weight: bolder;

    .mat-button-toggle-label-content {
      padding: 0 3px;
    }

    @for $i from 1 through length($indicator-colors) {
      &.indicator-color-#{$i - 1} {
        background-color: nth($indicator-colors, $i);
        border: 1px solid nth($indicator-colors, $i);
        color: white;
      }
      &.indicator-color-invert-#{$i - 1} {
        background-color: white;
        border: 1px solid nth($indicator-colors, $i);
        color: nth($indicator-colors, $i);
      }
    }
  }
}

button.mat-icon-button {
  &.add-button {
    background-color: $primary-color;
    color: white;
    width: 35px;
    height: 35px;
    margin-top: -8px;
    margin-right: 20px;

    &:hover {
      background-color: #110e4b;
    }

    mat-icon {
      color: white;
      line-height: 18px;
    }
  }
}

button.mat-stroked-button {
  &:not([disabled]) {
    border-color: currentColor;
  }
}

button.mat-button,
button.mat-raised-button {
  &.hol-archive-btn {
    color: grey !important;
  }

  &.hol-validate-btn {
    background-color: $done-color;
    color: white;
    border-radius: 60px;
  }

  &.hol-validate-btn-secondary {
    background-color: $done-color;
    color: white;
  }

  &.hol-archive-btn-secondary {
    background-color: $delete-color;
    color: white;
    border-radius: 2px;
    box-shadow: none;
  }

  &.hol-duplicate-btn {
    background-color: $primary-color;
    color: white;
    border-radius: 2px;
    box-shadow: none;
  }

  &.hol-cancel {
    @include colorButton($cancel-color);
  }

  &.hol-attachment-btn {
    &.has-content {
      color: $primary-color;
      background-color: #ffffff;
      border: 1px solid $primary-color;
    }

    background-color: $primary-color;
    color: #ffffff;
    min-width: 40px;
    padding: 0px 10px;

    .mat-button-wrapper {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .btn-label {
      margin-left: 4px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon-quit {
      margin-left: 4px;
    }
  }
}

button {
  &.hol-wide {
    width: 100%;
  }

  &.hol-rounded-btn {
    border-radius: 60px;
  }
}

mat-button-toggle-group {
  &.tab-buttons {
    height: 30px;
    text-align: center;
    vertical-align: middle;
    margin: 0 $content-padding;
    align-items: center;
    font-size: 11pt;
    line-height: 11pt;

    mat-button-toggle {
      min-width: 100px;
      background: $border-color;
      color: $primary-color;

      &.mat-button-toggle-checked {
        background: $primary-color;
        color: white;
      }
    }
  }

  &.roles-buttons {
    box-shadow: none;
    font-weight: bold;
    .mat-button-toggle {
      border-width: 2px;
      border-style: solid;
      height: 28px;
      border-radius: 14px;
      &:not(:last-child) {
        margin-right: $content-padding;
      }
      .mat-button-toggle-label-content {
        line-height: 24px;
        padding: 0 10px;
      }
      &.mat-button-toggle-disabled {
        opacity: 0.2;
      }
    }
  }
}

.upload-button {
  color: $primary-color;
  border: solid 1px $primary-color;
  margin: 0 5px;

  span {
    margin-left: 5px;
    text-transform: uppercase;
  }
}

.displayAllText {
  margin-left: 5px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 150px;
  align-content: center;

  label {
    display: flex;
    align-items: center;
    height: 10px;

    strong {
      margin-right: 5px;
    }

    md-checkbox.md-checked .md-icon {
      background-color: rgb(63, 97, 133) !important;
    }

    md-checkbox.md-checked .md-ink-ripple {
      color: rgb(63, 97, 133) !important;
    }

    .md-ripple-container {
      display: none !important;
    }

    .md-container:before {
      display: none !important;
    }
  }
}
