mat-expansion-panel {
  mat-expansion-panel-header {
    &.event-group-header {
      .mat-expansion-indicator {
        display: none;
        color: transparent;
        background: transparent;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}
