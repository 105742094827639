@import '../mixins.scss';

.tag-selector {
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0;
  }
  .filters-container {
    button {
      width: auto;
      margin-right: 5px;
      padding: 0 3px 0px 3px;
      min-height: 25px;
      max-height: 25px;
      display: flex;
      align-items: center;
    }
    .btn-group {
      margin-left: 10px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    button:not(.select-all-btn):not(.dropdown-btn) {
      @include filter-btn();
    }
    button.dropdown-btn {
      margin-top: 0;
      min-height: 28px;
      min-width: 0;
    }
  }
  .select-all-btn {
    margin: 0;
  }
}

.tag {
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  font-family: $default-font-family;
  font-size: 11px;
  line-height: 11px;
  color: white;
  padding: 5px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.tag-selector-container {
  margin-bottom: 20px;
}
