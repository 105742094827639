@import '../ui-kit/variables';
@import '../mixins';
@import '../crew/crew-variables';

.crew-todo-box {
  .hol-box__header {
    justify-content: normal;

    .title {
      flex: 1;

      .icon {
        padding: 3px 2px 2px 2px;
      }
    }

    .filters-container {
      display: flex;

      .filter-container {
        margin-left: $content-padding;

        button {
          min-width: 0px;
          min-height: 28px;
          text-overflow: ellipsis;
          max-width: 150px;
          overflow: hidden;

          span:not(.caret) {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .hol-search-box {
        input {
          max-width: 120px;
        }
      }
    }
  }

  .add-button {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    text-overflow: ellipsis;
  }

  .collapse-container {
    height: 2em;

    .btn-collapse {
      color: $primary-color;
      background: transparent;

      i {
        transform: rotateZ(180deg);
      }

      &.collapsed {
        i {
          transform: rotateZ(0deg);
        }
      }
    }
  }

  .hol-box__content .iscroll-wrapper {
    left: 0;
    right: 0;
    bottom: 0;
  }

  .main-content {
    display: flex;
    flex-direction: column;
  }
}

.todo-filter-dropdown-menu-mobile {
  right: 0 !important;

  li {
    a {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
