@import './variables';

.new-holis-theme {
  .mat-button-toggle-group {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .mat-button.back-link {
    .mat-button-wrapper {
      font-size: 16px;
      font-weight: 400;
      font-family: $default-font-family !important;
    }
  }
  button {
    letter-spacing: 0.09em;
    [class^='hol-icon-'],
    [class*=' hol-icon-'] {
      line-height: 1.25em;
      font-size: 1.25em;
      margin-right: 0.25em;
    }
    font-size: 14px !important;
    &:not(.back-link) {
      text-transform: uppercase;
    }
  }

  // Material
  mat-button-toggle-group {
    &.roles-buttons {
      box-shadow: none;
    }
  }

  /* Success styling */
  .mat-button.mat-success,
  .mat-stroked-button.mat-success {
    color: $hol-sucess !important;
  }
  .mat-button:not(:disabled).mat-success:hover,
  .mat-stroked-button:not(:disabled).mat-success:hover {
    background-color: rgba(88, 150, 14, 0.12);
  }

  .mat-raised-button.mat-success,
  .mat-flat-button.mat-success,
  .mat-fab.mat-success,
  .mat-mini-fab.mat-success {
    color: #fff;
    background-color: $hol-sucess;
  }

  .mat-icon-button.mat-success {
    color: $hol-sucess;
  }

  /* Mat-button-toggle styling */
  .mat-button-toggle {
    letter-spacing: 0.09em;
    [class^='hol-icon-'],
    [class*=' hol-icon-'] {
      line-height: 1.25em;
      font-size: 1.25em;
      margin-right: 0.25em;
    }
    &.mat-success {
      color: $hol-sucess !important;
      &:not(.mat-button-toggle-disabled):hover,
      &.mat-button-toggle-checked {
        background-color: $hol-sucess !important;
        color: #fff !important;
      }
    }
    &.mat-warn {
      color: $hol-warn !important;
      &:not(.mat-button-toggle-disabled):hover,
      &.mat-button-toggle-checked {
        background-color: $hol-warn !important;
        color: #fff !important;
      }
    }
    &.mat-basic {
      color: $hol-dark_grey !important;
      border-color: $hol-dark_grey !important;
    }
  }
}
