@import '../ui-kit/variables';
@import '../mixins';

.situation-item {
  background: white;
  margin-bottom: 5px;
  border-radius: $border-radius;
  padding: $content-padding $content-padding 0 $content-padding;
  &__title {
    .icon {
      margin-right: $content-padding/2;
    }
  }
  &__text {
    font-family: $condensed-font-family;
  }
  &__details {
    border-top: 1px solid $primary-color;
    font-family: $condensed-font-family;
    margin: 0 (-$content-padding);
    padding: 0 $content-padding;
    min-height: 26px;
    white-space: nowrap;
    .event-date {
      margin: 0 $content-padding/2;
    }
    .situation-event.date {
      background: none;
      text-align: right;
    }
    .status-indicator {
      margin-right: (-$content-padding);
      margin-left: $content-padding;
      align-self: flex-start;
      .icon {
        height: 25px;
        width: 25px;
        display: block;
        line-height: 25px;
        text-align: center;
      }
    }
    .status-time {
      &:before {
        content: '►';
      }
    }
  }

  .closed-by {
    text-align: right;
  }
}
