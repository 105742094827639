@import '../ui-kit/variables';

.modal-crew-add-event {
  .inline-form-group {
    @extend .hol-spaced-line;
    label {
      white-space: nowrap;
    }
    margin-bottom: $content-padding;
    .input-group {
      margin-left: 5px;
      &.full {
        flex: 1;
      }
    }
  }
  .search-user {
    position: relative;
    .auto-complete-loader {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .separator {
    border-bottom: 1px solid $border-color;
    width: 100%;
    margin-bottom: $content-padding;
  }

  .candidates-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .occ-table .iscroll-wrapper > * > .hol-list > li {
    font-weight: normal;
  }
  .users-list {
    .md-icon-button {
      margin: -10px;
    }
    .user-line {
      min-height: 25px;

      .ACCEPTED {
        color: green;
      }
      .REFUSED {
        color: red;
      }

      .WAITING {
        color: orange;
      }
    }
    .col-at {
      margin-right: $content-padding - 2px;
    }
    .created-by {
      margin-left: $content-padding / 2;
    }
    .status-indicator {
      margin-left: $content-padding - 2px;
      .icon {
        height: 25px;
        width: 25px;
        display: block;
        line-height: 25px;
        text-align: center;
      }
    }
    .col-info {
      padding: 5px $content-padding 3px $content-padding;
      margin: -2px 2px 0 2px;
      background: $background-secondary;
      line-height: 1em;
    }
    .archive-btn {
      position: absolute;
      bottom: $content-padding;
      right: $content-padding;
    }

    .tiredness-indicator {
      background: transparent;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      margin: 0 10px 0 0;
    }
  }

  .empty-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $background-secondary;
    min-height: 200px;
    padding: 15%;
    text-align: center;
    color: $text-color--light;
    font-weight: bold;
    .block-hint {
      margin: ($content-padding * 2) 0;
    }
  }

  //footer
  md-dialog-actions {
    border-top: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button.archive-btn.md-raised.md-button.hol-invert {
      color: grey !important;
      border: 2px solid grey !important;
      width: 120px;
      &.responsive {
        min-width: 50px;
        width: 50px;
      }
    }
    .dialog-actions {
      width: auto;
    }
  }
}

.md-dialog-container md-dialog.modal-crew-add-event md-dialog-content .checkbox {
  margin: 0;
  md-checkbox {
    margin: 0 0 5px 0;
  }
}
