@import 'variables';
@import '../mixins';

#iframeForPdf {
  position: absolute;
  left: -1000px;
}

.cdk-overlay-container {
  z-index: 10000;
  position: static;
}

.hol-row {
  > [class*='col-'] {
    height: 100%;
    padding: 0 ($content-padding / 2);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
  flex: 1;
  padding: ($content-padding / 2) $content-padding;

  &:first-of-type {
    padding-top: $content-padding;
  }

  &:last-of-type {
    padding-bottom: $content-padding;
  }
}

.hol-spaced-line {
  @include center-vertically-no-justify();
  justify-content: space-between;
}

.hol-centered-line {
  @include center-vertically();
}

ul.hol-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  li {
    padding: $content-padding 0;
    border-top: 1px solid $border-color;
    &.hol-clickable {
      margin: 2px;
      padding-left: $content-padding;
      padding-right: $content-padding;
    }
    &.not-seen {
      font-weight: bold;
    }
  }
}

.chips {
  display: inline-block;
  border-radius: 25px;
  width: 22px;
  height: 7px;
  max-height: 7px !important;
  margin-right: 4px;
  margin-bottom: 0px;
}

.md-chips {
  box-shadow: 0 0px !important;
}

.tag-container {
  button .md-button {
    max-height: 10px;
  }
}
