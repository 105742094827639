@import '../mixins';
@import '~src/styles/new-ui-kit/variables';

.md-dialog-container {
  z-index: 1000;
}

.uib-datepicker-popup {
  z-index: 1001;
}

.full-dialog {
  left: $nav_width;
  right: 0;
  top: 10%;
  bottom: 10%;
  margin: auto;
  position: absolute !important;
  max-width: 1024px !important;

  .mat-dialog-content {
    height: 100%;
  }
}

.mat-dialog-container {
  padding: 0 !important;

  .hol-dialog-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column nowrap;
    min-width: 630px;

    .mat-toolbar {
      background: #eeeeee;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
      padding: 16px 24px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-alt {
        background: #ffffff;
        box-shadow: none;
        padding: 16px 24px 16px 44px;
      }

      .mat-dialog-title {
        font-family: $condensed-font-family;
        font-size: 20px;
        font-weight: bold;
        margin: 3px 0 0 0;
        white-space: normal;
      }

      i {
        margin-right: 10px;
      }

      [mat-dialog-close] {
        color: $primary-color;
      }
    }

    .mat-dialog-content {
      margin: 0;
      padding: 24px;
      overflow: auto;

      .hol-dialog-section {
        margin-bottom: 20px;
        display: flex;
        flex-flow: row nowrap;

        .icon-container {
          width: 25px;
          text-align: center;
          margin-right: 10px;

          i {
            font-size: 24px;
          }
        }

        .title {
          margin-bottom: 14px;
          flex: 1 100%;
        }

        .mat-form-field-infix {
          border-top-width: 0;
        }
      }
    }

    .mat-dialog-actions {
      margin: 0;
      padding: 16px 24px;
      box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.2);
      z-index: 1;
      .acl-selector {
        width: 100%;
        border-bottom: 1px solid $hol-grey;
        margin: -16px -24px 16px -24px;
        padding: 8px 24px;
        box-sizing: content-box;
      }

      .fill-space {
        // This fills the remaining space, by using flexbox.
        // Every toolbar row uses a flexbox row layout.
        flex: 1 1 auto;
      }

      > *:not(:first-child) {
        margin-left: 14px;
      }
    }
  }
}

mat-dialog-container .file-and-image-upload figure img {
  max-width: 600px;
}
