@import '../ui-kit/variables';
@import '../mixins';

#page-crew-history {
  .hol-box__header {
    .hol-radio-btn-group {
      margin-bottom: 0;
    }
    .form-group {
      margin-bottom: 0;
      &.date {
        width: 190px;
        .uib-datepicker-popup {
          right: 0;
          left: auto !important;
        }
      }
    }
  }

  .hol-box__content {
    .col-action {
    }
    .col-todo {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
    }
    .col-result {
      .situation {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
      }
      .event-container {
        max-width: 300px;
      }

      .status-text {
        padding-left: $content-padding;
      }
      .status-info {
        padding-top: $content-padding;
      }
    }
    .col-todo,
    .col-result {
      .situation {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
      }
      .icon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.glyphicon-time {
          padding: 2px 2px 2px 3px;
        }
        &.hol-primary {
          background-color: $background-secondary;
        }
      }
      .crew-event-item {
        .icon {
          display: inline;
        }
      }
      .comment {
        margin-left: $content-padding;
      }
    }
  }
}

.hol-history-table {
  font-family: $condensed-font-family;
  .header {
    display: flex;
    .time {
      min-width: 45px;
      text-align: center;
    }
    > *:not(.time) {
      flex: 1;
      text-align: center;
      display: block;
    }
  }

  .iscroll-wrapper {
    top: 30px !important;
    ul.time-slot-list {
      margin-top: $content-padding;
      list-style: none;
      padding: 0;
      > li {
        border-top: 2px solid darken($border-color, 10%);
        position: relative;
        &.new-day-line {
          border-top-width: 20px;
          color: white;
          .time {
            margin-top: -20px !important;
            color: $text-color;
          }
        }
        .new-day {
          width: 100%;
          padding-top: 3px;
          font-weight: bold;
          margin-top: -22px;
          position: absolute;
          i.fa {
            height: 16px;
            font-size: 0.9em;
          }
        }
        .time-slot {
          display: flex;
          align-items: flex-start;
          .time {
            min-width: 35px;
            margin-top: -10px;
            background: white;
          }
          .col-type {
            width: 65px;
            align-self: center;
            margin-left: $content-padding;
            text-align: center;
            font-weight: bold;
          }

          ul.time-slot-inner-list {
            list-style: none;
            padding: 0;
            width: 100%;
            margin-left: 10px;
            color: initial;
            li {
              display: flex;
              &:not(:first-child) {
                border-top: 1px solid $border-color;
              }
              .col-direction {
                margin: 0 ($content-padding / 2);
              }
              > * {
                flex: 1;
                border-left: 1px solid $border-color;
                padding: $content-padding;
              }
            }
          }
        }
      }
    }
  }
}

.modal-crew-search-event {
  min-height: 50%;
  max-height: 80% !important;
  .flight-number-search {
    width: 180px;
  }
  .results-container {
    position: relative;
  }
  .inline-form-group {
    @extend .hol-spaced-line;
    label {
      white-space: nowrap;
    }
    margin-bottom: $content-padding;
    .input-group {
      margin-left: 5px;
      &.full {
        flex: 1;
        .date {
          width: 110px;
          margin-right: 10px;
        }
      }
    }
  }
  .form-group {
    position: relative;
  }
  ul.hol-list li {
    border-top: none;
    padding: 0;
    &.ng-enter-stagger,
    &.ng-leave-stagger {
      transition-delay: 0.02s;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
