@import '../ui-kit/variables';
@import '../mixins';
@import '../crew/crew-variables';

.crew-event-filter-box {
  height: auto;
  padding: $content-padding;
  .filters-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    label {
      margin-right: $content-padding;
    }

    .function-container {
      .function-filter {
        .md-button {
          margin-top: 0;
          min-height: 28px;
          min-width: 0;
        }
      }
    }

    .impact-container {
      margin-right: $content-padding;
      .impact-filter {
        display: inline-block;
        white-space: nowrap;
        button.md-button {
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          @for $i from 1 through length($filters-colors) {
            $filter-colors: nth($filters-colors, $i);
            &:nth-child(#{$i - 1}) {
              @include filter-btn(nth($filter-colors, 1), nth($filter-colors, 2));
            }
          }
        }
      }
    }

    ul.crew-event-filter {
      min-width: 0;

      @for $i from 1 through length($filters-colors) {
        $filter-colors: nth($filters-colors, $i);
        li:not(.select-all):nth-child(#{$i - 1}) button.md-button {
          @include filter-btn(nth($filter-colors, 1), nth($filter-colors, 2));
          min-width: 60px;
          width: calc(100% - 15px);
        }
      }
      .select-all-btn {
        min-width: 60px;
        width: calc(100% - 15px);
      }
    }
  }
}
