@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'icomoon';
  src: url("/assets/fonts/icomoon.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-clip:before {
  content: '\e92e';
  color: #fff; }

.icon-picture:before {
  content: '\e92f';
  color: #fff; }

.icon-icon_hotevent:before {
  content: '\e92a'; }

.icon-icon_incall:before {
  content: '\e92b'; }

.icon-icon_outcall:before {
  content: '\e92c'; }

.icon-icon_plane:before {
  content: '\e92d'; }

.icon-icon-people:before {
  content: '\e927'; }

.icon-aircraft-landing:before {
  content: '\e925'; }

.icon-aircraft-take-off:before {
  content: '\e926'; }

.icon-expand:before {
  content: '\e900'; }

.icon-arrow_bottom:before {
  content: '\e901'; }

.icon-arrow_right:before {
  content: '\e902'; }

.icon-arrow_top:before {
  content: '\e903'; }

.icon-check:before {
  content: '\e904'; }

.icon-checkbox_vide:before {
  content: '\e905'; }

.icon-checklist:before {
  content: '\e906'; }

.icon-confirm:before {
  content: '\e907'; }

.icon-dashboard:before {
  content: '\e908'; }

.icon-edit:before {
  content: '\e909'; }

.icon-epingle:before {
  content: '\e90a'; }

.icon-event_data:before {
  content: '\e90b'; }

.icon-facetime:before {
  content: '\e90c'; }

.icon-file:before {
  content: '\e90d'; }

.icon-freeze:before {
  content: '\e90e'; }

.icon-glass:before {
  content: '\e90f'; }

.icon-history:before {
  content: '\e910'; }

.icon-library:before {
  content: '\e911'; }

.icon-mail:before {
  content: '\e912'; }

.icon-map:before {
  content: '\e913'; }

.icon-megaphone:before {
  content: '\e914'; }

.icon-newspaper:before {
  content: '\e915'; }

.icon-note:before {
  content: '\e916'; }

.icon-phone:before {
  content: '\e917'; }

.icon-quit:before {
  content: '\e918'; }

.icon-lightning1:before {
  content: '\e919'; }

.icon-see_more:before {
  content: '\e91a'; }

.icon-settings:before {
  content: '\e91b'; }

.icon-team:before {
  content: '\e91c'; }

.icon-cancel-circle:before {
  content: '\e91d'; }

.icon-logout:before {
  content: '\e91e'; }

.icon-shutdown:before {
  content: '\e91f'; }

.icon-start:before {
  content: '\e920'; }

.icon-plus:before {
  content: '\e921'; }

.icon-arrow_select:before {
  content: '\e922'; }

.icon-reinstate:before {
  content: '\e923'; }

.icon-keyboard_arrow_right:before {
  content: '\e928'; }

.icon-add:before {
  content: '\e929'; }

.icon-alert:before {
  content: '\e924'; }

.icon-blocked:before {
  content: '\ea0e'; }

.icon-link:before {
  content: '\e157';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px; }

.hol-clickable, .hol-panel-md {
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(23, 23, 23, 0.2);
  border: none;
  transition: box-shadow 0.2s, opacity 0.2s; }
  .hol-clickable.ng-leave, .ng-leave.hol-panel-md {
    transition: none; }
  .hol-clickable:hover, .hol-panel-md:hover {
    box-shadow: 0px 1px 4px rgba(23, 23, 23, 0.3); }
  .hol-clickable.ng-click-active, .ng-click-active.hol-panel-md {
    box-shadow: 0px 1px 4px rgba(23, 23, 23, 0.3);
    opacity: 0.7; }
  .hol-clickable[disabled], .hol-panel-md[disabled] {
    pointer-events: none;
    box-shadow: none;
    border: 1px solid #c9cbcb; }

.hol-animate-enter-left.ng-enter-stagger {
  transition-delay: 0.02s;
  transition-duration: 0s; }

.hol-animate-enter-left.ng-enter, .hol-animate-enter-left.ng-enter-prepare {
  transition: transform 0.3s ease;
  transform: translateX(-100%); }
  .hol-animate-enter-left.ng-enter.ng-enter-active, .hol-animate-enter-left.ng-enter-prepare.ng-enter-active {
    transform: translateX(0); }

.hol-animate-leave-right.ng-leave-stagger {
  transition-delay: 0.02s;
  transition-duration: 0s; }

.hol-animate-leave-right.ng-leave {
  transition: transform 0.3s ease;
  transform: translateX(0); }
  .hol-animate-leave-right.ng-leave.ng-leave-active {
    transform: translateX(100%); }

.hol-animate-pop {
  animation: pop 0.3s cubic-bezier(0.1, 0.7, 0.74, 1.3); }

@keyframes pop {
  from {
    transform: scale(0); }
  to {
    transform: scale(1); } }

.hol-primary {
  color: #3f6185 !important; }
  .hol-primary button.md-raised, .hol-primary.full {
    background: #3f6185 !important;
    color: white !important; }

.hol-crisis {
  color: #ff0825 !important; }
  .hol-crisis button.md-raised, .hol-crisis.full {
    background: #ff0825 !important;
    color: white !important; }

.hol-training {
  color: #f4902f !important; }
  .hol-training button.md-raised, .hol-training.full {
    background: #f4902f !important;
    color: white !important; }

.hol-occ {
  color: #0030bf !important; }
  .hol-occ button.md-raised, .hol-occ.full {
    background: #0030bf !important;
    color: white !important; }

.hol-ecl {
  color: #e35700 !important; }
  .hol-ecl button.md-raised, .hol-ecl.full {
    background: #e35700 !important;
    color: white !important; }

.hol-ops {
  color: #0b84b0 !important; }
  .hol-ops button.md-raised, .hol-ops.full {
    background: #0b84b0 !important;
    color: white !important; }

.hol-crew {
  color: #008568 !important; }
  .hol-crew button.md-raised, .hol-crew.full {
    background: #008568 !important;
    color: white !important; }

.hol-mcc {
  color: #bb00ff !important; }
  .hol-mcc button.md-raised, .hol-mcc.full {
    background: #bb00ff !important;
    color: white !important; }

.hol-goc {
  color: #8a4801 !important; }
  .hol-goc button.md-raised, .hol-goc.full {
    background: #8a4801 !important;
    color: white !important; }

.hol-todo {
  color: #707070 !important; }
  .hol-todo button.md-raised, .hol-todo.full {
    background: #707070 !important;
    color: white !important; }

.hol-done {
  color: #00c179 !important; }
  .hol-done button.md-raised, .hol-done.full {
    background: #00c179 !important;
    color: white !important; }

.hol-archive {
  color: #7f7f7f !important; }
  .hol-archive button.md-raised, .hol-archive.full {
    background: #7f7f7f !important;
    color: white !important; }

.hol-online {
  color: #00c179 !important; }
  .hol-online button.md-raised, .hol-online.full {
    background: #00c179 !important;
    color: white !important; }

.hol-frozen {
  color: #ff0036 !important; }
  .hol-frozen button.md-raised, .hol-frozen.full {
    background: #ff0036 !important;
    color: white !important; }

.hol-offline {
  color: #ff0036 !important; }
  .hol-offline button.md-raised, .hol-offline.full {
    background: #ff0036 !important;
    color: white !important; }

.hol-error {
  color: #ff0036 !important; }
  .hol-error button.md-raised, .hol-error.full {
    background: #ff0036 !important;
    color: white !important; }

.hol-warning {
  color: #f4902f !important; }
  .hol-warning button.md-raised, .hol-warning.full {
    background: #f4902f !important;
    color: white !important; }

.hol-backtodo {
  color: #f4902f !important; }
  .hol-backtodo button.md-raised, .hol-backtodo.full {
    background: #f4902f !important;
    color: white !important; }

.hol-event {
  color: #007aff !important; }
  .hol-event button.md-raised, .hol-event.full {
    background: #007aff !important;
    color: white !important; }

.hol-available-offline {
  color: #f4902f !important; }
  .hol-available-offline button.md-raised, .hol-available-offline.full {
    background: #f4902f !important;
    color: white !important; }

.hol-notapplicable {
  color: #aaaaaa !important; }
  .hol-notapplicable button.md-raised, .hol-notapplicable.full {
    background: #aaaaaa !important;
    color: white !important; }

.hol-affected {
  color: #3f84cf !important; }
  .hol-affected button.md-raised, .hol-affected.full {
    background: #3f84cf !important;
    color: white !important; }

.hol-validate {
  color: #00c179 !important; }
  .hol-validate button.md-raised, .hol-validate.full {
    background: #00c179 !important;
    color: white !important; }

.hol-padding {
  padding: 10px; }

.hol-margin {
  margin: 10px; }

.hol-no-padding {
  padding: 0 !important; }

.hol-no-margin {
  margin: 0 !important; }

.hol-pre {
  white-space: pre-line; }

#iframeForPdf {
  position: absolute;
  left: -1000px; }

.cdk-overlay-container {
  z-index: 10000;
  position: static; }

.hol-row {
  flex: 1;
  padding: 5px 10px; }
  .hol-row > [class*='col-'] {
    height: 100%;
    padding: 0 5px; }
    .hol-row > [class*='col-']:first-child {
      padding-left: 0; }
    .hol-row > [class*='col-']:last-child {
      padding-right: 0; }
  .hol-row:first-of-type {
    padding-top: 10px; }
  .hol-row:last-of-type {
    padding-bottom: 10px; }

.hol-spaced-line, .crew-event-item .event-item__title, .header-component .user-group button.header-button.button-logout, .modal-crew-add-event .inline-form-group, .modal-crew-search-event .inline-form-group {
  display: flex;
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-flex-align: center;
  -webkit-box-align: center;
  justify-content: space-between; }

.hol-centered-line {
  display: flex;
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-flex-align: center;
  -webkit-box-align: center;
  justify-content: center;
  -webkit-justify-content: center; }

ul.hol-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0; }
  ul.hol-list li {
    padding: 10px 0;
    border-top: 1px solid #c9cbcb; }
    ul.hol-list li.hol-clickable, ul.hol-list li.hol-panel-md {
      margin: 2px;
      padding-left: 10px;
      padding-right: 10px; }
    ul.hol-list li.not-seen {
      font-weight: bold; }

.chips {
  display: inline-block;
  border-radius: 25px;
  width: 22px;
  height: 7px;
  max-height: 7px !important;
  margin-right: 4px;
  margin-bottom: 0px; }

.md-chips {
  box-shadow: 0 0px !important; }

.tag-container button .md-button {
  max-height: 10px; }

.hol-box {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border: 1px solid #eaeaea;
  overflow: hidden;
  height: 100%;
  position: relative; }
  .hol-box .hol-box__header {
    color: #3f6185;
    min-height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 10px 0 10px; }
    .hol-box .hol-box__header--col {
      flex-flow: column nowrap; }
    .hol-box .hol-box__header .hol-box__subtitle {
      font-weight: normal;
      font-size: 14px; }
    .hol-box .hol-box__header button {
      margin-top: 0;
      margin-bottom: 0; }
    .hol-box .hol-box__header .md-icon-button {
      font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif;
      background-color: #3f6185;
      color: white;
      margin-left: 20px;
      margin-right: 0; }
      .hol-box .hol-box__header .md-icon-button:hover, .hol-box .hol-box__header .md-icon-button:active, .hol-box .hol-box__header .md-icon-button:focus {
        background-color: #3f6185;
        color: white; }
      .hol-box .hol-box__header .md-icon-button .open-modal {
        color: #008568;
        background-color: white; }
      .hol-box .hol-box__header .md-icon-button:hover, .hol-box .hol-box__header .md-icon-button:active, .hol-box .hol-box__header .md-icon-button:focus {
        background-color: #008568;
        color: white; }
    .hol-box .hol-box__header .open-modal-text {
      color: white; }
  .hol-box .hol-box__content {
    padding: 10px;
    flex: 1;
    position: relative; }
    .hol-box .hol-box__content .iscroll-wrapper {
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch; }
    .hol-box .hol-box__content.hol-no-padding .iscroll-wrapper {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .hol-box .hol-box__footer {
    padding: 0 10px 10px 10px;
    min-height: 40px;
    position: relative; }

.hol-panel-md .panel-heading {
  border-radius: 4px;
  padding: 0; }
  .hol-panel-md .panel-heading .panel-title {
    text-transform: none; }
    .hol-panel-md .panel-heading .panel-title a:hover, .hol-panel-md .panel-heading .panel-title a:focus {
      text-decoration: none; }

.hol-panel-md.panel-open .panel-heading {
  background-color: #2a4057;
  color: white;
  border-radius: 4px 4px 0 0; }

.hol-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: solid 1px #3f6185;
  margin-bottom: 10px;
  border-radius: 4px; }
  .hol-panel__header {
    padding: 10px;
    display: flex;
    background: #3f6185;
    color: white; }
  .hol-panel__content {
    padding: 10px;
    display: flex;
    flex-direction: column; }

.hol-duty-indicator {
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
  content: ' ';
  width: 10px;
  height: 10px;
  border-radius: 50%; }
  .hol-duty-indicator.online {
    background-color: #00c179; }
  .hol-duty-indicator.available-offline {
    background-color: #f4902f; }
  .hol-duty-indicator.offline {
    background-color: #ff0036; }

/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.hol-search-box {
  position: relative; }
  .hol-search-box input {
    padding-right: 44px;
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #3f6185;
    border-radius: 0; }
    .hol-search-box input:focus {
      outline: none !important; }
  .hol-search-box .form-control-feedback {
    height: 100%;
    text-align: right;
    width: auto;
    pointer-events: initial; }
    .hol-search-box .form-control-feedback i {
      padding: 3px;
      vertical-align: super; }

.hol-search-container {
  height: 50px;
  max-width: 300px;
  margin: auto;
  position: relative; }
  .hol-search-container--align-left {
    margin-left: 20px; }
  .hol-search-container.sm {
    height: 32px;
    max-width: 250px; }
    .hol-search-container.sm input {
      border-radius: 20px; }
  .hol-search-container input {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    border: 1px solid #3f6185;
    padding-left: 10px;
    padding-right: 35px;
    background: #f2f2f2; }
    .hol-search-container input:focus:not(.mat-input-element) {
      outline: none !important; }
    .hol-search-container input:not(:placeholder-shown), .hol-search-container input:focus {
      background: white; }
    .hol-search-container input::placeholder {
      color: #3f6185;
      font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .hol-search-container mat-icon,
  .hol-search-container .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    color: #3f6185;
    margin: auto 13px auto 0; }

.data-time-input {
  position: relative; }
  .data-time-input input.form-control {
    display: inline-block; }
  .data-time-input table {
    vertical-align: middle;
    display: inline-block; }
  .data-time-input .uib-daypicker button.uib-title,
  .data-time-input .uib-daypicker td.uib-day button {
    border: none; }

.date-time-input-responsive {
  display: inline-flex;
  margin-bottom: 32px; }
  .date-time-input-responsive .date {
    max-width: 130px;
    min-widht: 130px;
    margin-right: 10px; }
  .date-time-input-responsive .time {
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    margin-right: 10px; }

.ng-invalid.ng-dirty:not(:focus), .ng-invalid.ng-touched:not(:focus) {
  border-color: #ff0036 !important; }

.hol-show-errors .ng-invalid:not(:focus) {
  border-color: #ff0036 !important; }

textarea {
  resize: none; }

input[type='time']::-webkit-clear-button,
input[type='date']::-webkit-clear-button {
  /* Removes blue cross */
  -webkit-appearance: none;
  margin: 0; }

input[type='time']::-webkit-inner-spin-button,
input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none; }

md-radio-group.md-focused:not(:empty) .md-checked .md-container:before {
  background-color: rgba(0, 0, 0, 0.12); }

md-radio-group:not([disabled]) md-radio-button .md-container .md-off {
  border-color: rgba(0, 0, 0, 0.54); }

md-radio-group:not([disabled]) md-radio-button .md-container .md-on {
  background-color: #3f6185; }

md-radio-group:not([disabled]) md-radio-button[disabled] {
  cursor: not-allowed;
  opacity: 0.7; }
  md-radio-group:not([disabled]) md-radio-button[disabled] .md-container {
    cursor: not-allowed; }

md-radio-group.hol-radio-btn-group {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap; }
  md-radio-group.hol-radio-btn-group.ng-not-empty md-radio-button:not(.md-checked):not(:hover):not([disabled]) {
    opacity: 0.7; }
  md-radio-group.hol-radio-btn-group md-radio-button[disabled]:not(.md-checked) {
    opacity: 0.3;
    cursor: not-allowed; }
  md-radio-group.hol-radio-btn-group > * {
    margin: 0 0 5px 0; }
  md-radio-group.hol-radio-btn-group > *:not(:last-child) {
    margin-right: 10px; }
  md-radio-group.hol-radio-btn-group md-radio-button {
    min-height: 1em;
    background: #f1f5f5;
    margin-top: 0; }
    md-radio-group.hol-radio-btn-group md-radio-button .md-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-transform: none;
      text-align: left;
      line-height: 1.5em;
      margin: 0; }
      md-radio-group.hol-radio-btn-group md-radio-button .md-label .text-container {
        margin: 5px 0 5px 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
        md-radio-group.hol-radio-btn-group md-radio-button .md-label .text-container * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
    md-radio-group.hol-radio-btn-group md-radio-button .md-container {
      display: none; }
    md-radio-group.hol-radio-btn-group md-radio-button.md-raised.md-checked {
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5); }

input.hol-invisible-input:not(.ng-invalid),
textarea.hol-invisible-input:not(.ng-invalid) {
  border-width: 0 !important;
  box-shadow: none !important;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ''; }
  input.hol-invisible-input:not(.ng-invalid):focus,
  textarea.hol-invisible-input:not(.ng-invalid):focus {
    border-width: 1px !important;
    background: white; }

button.md-button.md-primary {
  color: #3f6185; }

.form-group.with-icon {
  position: relative; }
  .form-group.with-icon .icon {
    font-size: 2em;
    position: absolute;
    top: 5px; }
  .form-group.with-icon label,
  .form-group.with-icon .form-control,
  .form-group.with-icon .form-value {
    margin-left: 30px;
    width: calc(100% - 30px); }

.checkbox {
  margin-top: 10px;
  margin-bottom: 10px; }
  .checkbox label:not(.form-check-label) {
    padding: 0; }
    .checkbox label:not(.form-check-label) md-checkbox {
      margin-left: 5px; }
      .checkbox label:not(.form-check-label) md-checkbox.md-checked .md-icon {
        background-color: #254c6e; }
        .checkbox label:not(.form-check-label) md-checkbox.md-checked .md-icon:after {
          border-color: white; }

button.md-raised.md-button:not(.md-icon-button),
md-radio-button.md-raised.md-button:not(.md-icon-button) {
  padding: 0 10px; }
  button.md-raised.md-button:not(.md-icon-button) md-icon,
  md-radio-button.md-raised.md-button:not(.md-icon-button) md-icon {
    margin-right: 5px; }

button.md-raised.md-button, button.md-raised.md-icon-button,
md-radio-button.md-raised.md-button,
md-radio-button.md-raised.md-icon-button {
  font-weight: 600; }
  button.md-raised.md-button:not(.ng-leave), button.md-raised.md-icon-button:not(.ng-leave),
  md-radio-button.md-raised.md-button:not(.ng-leave),
  md-radio-button.md-raised.md-icon-button:not(.ng-leave) {
    transition: opacity 0.2s, background-color 0.2s; }
  button.md-raised.md-button, button.md-raised.md-button:not([disabled]):hover, button.md-raised.md-button:not([disabled]).md-focused, button.md-raised.md-icon-button, button.md-raised.md-icon-button:not([disabled]):hover, button.md-raised.md-icon-button:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button,
  md-radio-button.md-raised.md-button:not([disabled]):hover,
  md-radio-button.md-raised.md-button:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button,
  md-radio-button.md-raised.md-icon-button:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button:not([disabled]).md-focused {
    color: white;
    background-color: #3f6185; }
  button.md-raised.md-button:not([disabled]):hover, button.md-raised.md-icon-button:not([disabled]):hover,
  md-radio-button.md-raised.md-button:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button:not([disabled]):hover {
    background-color: #2f4862; }
  button.md-raised.md-button.inactive, button.md-raised.md-button:disabled, button.md-raised.md-icon-button.inactive, button.md-raised.md-icon-button:disabled,
  md-radio-button.md-raised.md-button.inactive,
  md-radio-button.md-raised.md-button:disabled,
  md-radio-button.md-raised.md-icon-button.inactive,
  md-radio-button.md-raised.md-icon-button:disabled {
    opacity: 0.3; }
  button.md-raised.md-button.hol-crisis, button.md-raised.md-button.hol-crisis:not([disabled]):hover, button.md-raised.md-button.hol-crisis:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-crisis, button.md-raised.md-icon-button.hol-crisis:not([disabled]):hover, button.md-raised.md-icon-button.hol-crisis:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-crisis,
  md-radio-button.md-raised.md-button.hol-crisis:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-crisis:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-crisis,
  md-radio-button.md-raised.md-icon-button.hol-crisis:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-crisis:not([disabled]).md-focused {
    color: white !important;
    background-color: #ff0825 !important; }
  button.md-raised.md-button.hol-crisis:not([disabled]):hover, button.md-raised.md-icon-button.hol-crisis:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-crisis:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-crisis:not([disabled]):hover {
    background-color: #d40019 !important; }
  button.md-raised.md-button.hol-training, button.md-raised.md-button.hol-training:not([disabled]):hover, button.md-raised.md-button.hol-training:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-training, button.md-raised.md-icon-button.hol-training:not([disabled]):hover, button.md-raised.md-icon-button.hol-training:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-training,
  md-radio-button.md-raised.md-button.hol-training:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-training:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-training,
  md-radio-button.md-raised.md-icon-button.hol-training:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-training:not([disabled]).md-focused {
    color: white !important;
    background-color: #f4902f !important; }
  button.md-raised.md-button.hol-training:not([disabled]):hover, button.md-raised.md-icon-button.hol-training:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-training:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-training:not([disabled]):hover {
    background-color: #e4760c !important; }
  button.md-raised.md-button.hol-invert, button.md-raised.md-icon-button.hol-invert,
  md-radio-button.md-raised.md-button.hol-invert,
  md-radio-button.md-raised.md-icon-button.hol-invert {
    border: 1px solid #3f6185; }
    button.md-raised.md-button.hol-invert, button.md-raised.md-button.hol-invert:not([disabled]):hover, button.md-raised.md-button.hol-invert:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-invert, button.md-raised.md-icon-button.hol-invert:not([disabled]):hover, button.md-raised.md-icon-button.hol-invert:not([disabled]).md-focused,
    md-radio-button.md-raised.md-button.hol-invert,
    md-radio-button.md-raised.md-button.hol-invert:not([disabled]):hover,
    md-radio-button.md-raised.md-button.hol-invert:not([disabled]).md-focused,
    md-radio-button.md-raised.md-icon-button.hol-invert,
    md-radio-button.md-raised.md-icon-button.hol-invert:not([disabled]):hover,
    md-radio-button.md-raised.md-icon-button.hol-invert:not([disabled]).md-focused {
      color: #3f6185 !important;
      background-color: white !important; }
    button.md-raised.md-button.hol-invert:not([disabled]):hover, button.md-raised.md-icon-button.hol-invert:not([disabled]):hover,
    md-radio-button.md-raised.md-button.hol-invert:not([disabled]):hover,
    md-radio-button.md-raised.md-icon-button.hol-invert:not([disabled]):hover {
      background-color: #e6e5e5 !important; }
  button.md-raised.md-button.hol-invert-no-border, button.md-raised.md-button.hol-invert-no-border:not([disabled]):hover, button.md-raised.md-button.hol-invert-no-border:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-invert-no-border, button.md-raised.md-icon-button.hol-invert-no-border:not([disabled]):hover, button.md-raised.md-icon-button.hol-invert-no-border:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-invert-no-border,
  md-radio-button.md-raised.md-button.hol-invert-no-border:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-invert-no-border:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-invert-no-border,
  md-radio-button.md-raised.md-icon-button.hol-invert-no-border:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-invert-no-border:not([disabled]).md-focused {
    color: #3f6185 !important;
    background-color: #f1f5f5 !important; }
  button.md-raised.md-button.hol-invert-no-border:not([disabled]):hover, button.md-raised.md-icon-button.hol-invert-no-border:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-invert-no-border:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-invert-no-border:not([disabled]):hover {
    background-color: #d3e0e0 !important; }
  button.md-raised.md-button.hol-frozen, button.md-raised.md-button.hol-frozen:not([disabled]):hover, button.md-raised.md-button.hol-frozen:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-frozen, button.md-raised.md-icon-button.hol-frozen:not([disabled]):hover, button.md-raised.md-icon-button.hol-frozen:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-frozen,
  md-radio-button.md-raised.md-button.hol-frozen:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-frozen:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-frozen,
  md-radio-button.md-raised.md-icon-button.hol-frozen:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-frozen:not([disabled]).md-focused {
    color: white !important;
    background-color: #ff0036 !important; }
  button.md-raised.md-button.hol-frozen:not([disabled]):hover, button.md-raised.md-icon-button.hol-frozen:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-frozen:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-frozen:not([disabled]):hover {
    background-color: #cc002b !important; }
  button.md-raised.md-button.hol-done, button.md-raised.md-button.hol-done:not([disabled]):hover, button.md-raised.md-button.hol-done:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-done, button.md-raised.md-icon-button.hol-done:not([disabled]):hover, button.md-raised.md-icon-button.hol-done:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-done,
  md-radio-button.md-raised.md-button.hol-done:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-done:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-done,
  md-radio-button.md-raised.md-icon-button.hol-done:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-done:not([disabled]).md-focused {
    color: white !important;
    background-color: #00c179 !important; }
  button.md-raised.md-button.hol-done:not([disabled]):hover, button.md-raised.md-icon-button.hol-done:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-done:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-done:not([disabled]):hover {
    background-color: #008e59 !important; }
  button.md-raised.md-button.hol-todo, button.md-raised.md-button.hol-todo:not([disabled]):hover, button.md-raised.md-button.hol-todo:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-todo, button.md-raised.md-icon-button.hol-todo:not([disabled]):hover, button.md-raised.md-icon-button.hol-todo:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-todo,
  md-radio-button.md-raised.md-button.hol-todo:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-todo:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-todo,
  md-radio-button.md-raised.md-icon-button.hol-todo:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-todo:not([disabled]).md-focused {
    color: white !important;
    background-color: #707070 !important; }
  button.md-raised.md-button.hol-todo:not([disabled]):hover, button.md-raised.md-icon-button.hol-todo:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-todo:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-todo:not([disabled]):hover {
    background-color: #575656 !important; }
  button.md-raised.md-button.hol-backtodo, button.md-raised.md-button.hol-backtodo:not([disabled]):hover, button.md-raised.md-button.hol-backtodo:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-backtodo, button.md-raised.md-icon-button.hol-backtodo:not([disabled]):hover, button.md-raised.md-icon-button.hol-backtodo:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-backtodo,
  md-radio-button.md-raised.md-button.hol-backtodo:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-backtodo:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-backtodo,
  md-radio-button.md-raised.md-icon-button.hol-backtodo:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-backtodo:not([disabled]).md-focused {
    color: white !important;
    background-color: #f4902f !important; }
  button.md-raised.md-button.hol-backtodo:not([disabled]):hover, button.md-raised.md-icon-button.hol-backtodo:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-backtodo:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-backtodo:not([disabled]):hover {
    background-color: #e4760c !important; }
  button.md-raised.md-button.hol-online, button.md-raised.md-button.hol-online:not([disabled]):hover, button.md-raised.md-button.hol-online:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-online, button.md-raised.md-icon-button.hol-online:not([disabled]):hover, button.md-raised.md-icon-button.hol-online:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-online,
  md-radio-button.md-raised.md-button.hol-online:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-online:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-online,
  md-radio-button.md-raised.md-icon-button.hol-online:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-online:not([disabled]).md-focused {
    color: white !important;
    background-color: #00c179 !important; }
  button.md-raised.md-button.hol-online:not([disabled]):hover, button.md-raised.md-icon-button.hol-online:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-online:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-online:not([disabled]):hover {
    background-color: #008e59 !important; }
  button.md-raised.md-button.hol-offline, button.md-raised.md-button.hol-offline:not([disabled]):hover, button.md-raised.md-button.hol-offline:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-offline, button.md-raised.md-icon-button.hol-offline:not([disabled]):hover, button.md-raised.md-icon-button.hol-offline:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-offline,
  md-radio-button.md-raised.md-button.hol-offline:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-offline:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-offline,
  md-radio-button.md-raised.md-icon-button.hol-offline:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-offline:not([disabled]).md-focused {
    color: white !important;
    background-color: #ff0036 !important; }
  button.md-raised.md-button.hol-offline:not([disabled]):hover, button.md-raised.md-icon-button.hol-offline:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-offline:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-offline:not([disabled]):hover {
    background-color: #cc002b !important; }
  button.md-raised.md-button.hol-available-offline, button.md-raised.md-button.hol-available-offline:not([disabled]):hover, button.md-raised.md-button.hol-available-offline:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-available-offline, button.md-raised.md-icon-button.hol-available-offline:not([disabled]):hover, button.md-raised.md-icon-button.hol-available-offline:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-available-offline,
  md-radio-button.md-raised.md-button.hol-available-offline:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-available-offline:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-available-offline,
  md-radio-button.md-raised.md-icon-button.hol-available-offline:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-available-offline:not([disabled]).md-focused {
    color: white !important;
    background-color: #f4902f !important; }
  button.md-raised.md-button.hol-available-offline:not([disabled]):hover, button.md-raised.md-icon-button.hol-available-offline:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-available-offline:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-available-offline:not([disabled]):hover {
    background-color: #e4760c !important; }
  button.md-raised.md-button.hol-validate, button.md-raised.md-button.hol-validate:not([disabled]):hover, button.md-raised.md-button.hol-validate:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-validate, button.md-raised.md-icon-button.hol-validate:not([disabled]):hover, button.md-raised.md-icon-button.hol-validate:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-validate,
  md-radio-button.md-raised.md-button.hol-validate:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-validate:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-validate,
  md-radio-button.md-raised.md-icon-button.hol-validate:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-validate:not([disabled]).md-focused {
    color: white !important;
    background-color: #00c179 !important; }
  button.md-raised.md-button.hol-validate:not([disabled]):hover, button.md-raised.md-icon-button.hol-validate:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-validate:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-validate:not([disabled]):hover {
    background-color: #008e59 !important; }
  button.md-raised.md-button.hol-cancel, button.md-raised.md-button.hol-cancel:not([disabled]):hover, button.md-raised.md-button.hol-cancel:not([disabled]).md-focused, button.md-raised.md-icon-button.hol-cancel, button.md-raised.md-icon-button.hol-cancel:not([disabled]):hover, button.md-raised.md-icon-button.hol-cancel:not([disabled]).md-focused,
  md-radio-button.md-raised.md-button.hol-cancel,
  md-radio-button.md-raised.md-button.hol-cancel:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-cancel:not([disabled]).md-focused,
  md-radio-button.md-raised.md-icon-button.hol-cancel,
  md-radio-button.md-raised.md-icon-button.hol-cancel:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-cancel:not([disabled]).md-focused {
    color: white !important;
    background-color: #ff0036 !important; }
  button.md-raised.md-button.hol-cancel:not([disabled]):hover, button.md-raised.md-icon-button.hol-cancel:not([disabled]):hover,
  md-radio-button.md-raised.md-button.hol-cancel:not([disabled]):hover,
  md-radio-button.md-raised.md-icon-button.hol-cancel:not([disabled]):hover {
    background-color: #cc002b !important; }

button.hol-delete {
  color: #ff0036;
  font-size: 22px; }

button.hol-save {
  background-color: #00c179;
  color: white; }

.dropdown .caret {
  margin-left: 5px;
  transition: 0.3s;
  transform-origin: 50% 50%;
  transform: scaleY(1); }

.dropdown.open .caret {
  transform: scaleY(-1); }

.tag-selector button.mat-raised-button {
  margin-bottom: 10px; }

.tag-selector button.mat-button {
  align-self: start; }

.mat-icon-button:not(.mat-calendar-previous-button).mat-icon-button:not(.mat-calendar-previous-button),
.mat-icon-button:not(.mat-calendar-next-button).mat-icon-button:not(.mat-calendar-next-button),
.md-icon-button.md-icon-button {
  width: 31px;
  height: 31px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: auto;
  border-radius: 50%; }

.mat-calendar-previous-button.mat-calendar-previous-button,
.mat-calendar-next-button.mat-calendar-next-button {
  width: 40px !important;
  height: 40px !important; }

.md-dialog-container {
  margin-top: 0;
  display: flex;
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-flex-align: center;
  -webkit-box-align: center;
  justify-content: center;
  -webkit-justify-content: center; }
  .md-dialog-container md-dialog {
    min-width: 70%;
    max-width: 70%;
    width: 70%;
    margin-left: 80px;
    max-height: 100%;
    margin-top: 0;
    margin-bottom: 0; }
    .md-dialog-container md-dialog.long-title md-toolbar .md-toolbar-tools {
      max-height: none;
      height: auto; }
      .md-dialog-container md-dialog.long-title md-toolbar .md-toolbar-tools h2 {
        text-transform: none; }
    .md-dialog-container md-dialog.hol-modal-alert, .md-dialog-container md-dialog.hol-modal-confirm {
      max-width: 50%;
      min-width: 25%;
      width: auto; }
    .md-dialog-container md-dialog md-toolbar {
      min-height: 64px; }
      .md-dialog-container md-dialog md-toolbar .md-toolbar-tools {
        color: #3f6185;
        min-height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        padding: 10px 20px 0 20px;
        background: white; }
        .md-dialog-container md-dialog md-toolbar .md-toolbar-tools h2 small {
          font-size: 85%;
          font-weight: normal;
          color: inherit; }
        .md-dialog-container md-dialog md-toolbar .md-toolbar-tools button.md-button {
          font-size: 22px;
          margin: 0 0 0 10px;
          padding: 0;
          color: inherit;
          min-width: 40px; }
    .md-dialog-container md-dialog .header-text-no-overflow {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .md-dialog-container md-dialog md-dialog-content {
      padding: 10px 20px;
      flex: 1;
      position: relative; }
      .md-dialog-container md-dialog md-dialog-content md-list,
      .md-dialog-container md-dialog md-dialog-content md-list-item {
        padding: 0; }
      .md-dialog-container md-dialog md-dialog-content input,
      .md-dialog-container md-dialog md-dialog-content textarea,
      .md-dialog-container md-dialog md-dialog-content select {
        width: 100%;
        border: 1px solid #ccc;
        font-weight: 300;
        border-radius: 0;
        padding: 6px; }
      .md-dialog-container md-dialog md-dialog-content .input-group-addon {
        border-radius: 0; }
      .md-dialog-container md-dialog md-dialog-content .checkbox-group .checkbox {
        margin: 0;
        display: block; }
      .md-dialog-container md-dialog md-dialog-content .checkbox-group md-checkbox {
        margin-bottom: 5px; }
      .md-dialog-container md-dialog md-dialog-content .next-info {
        float: right;
        font-weight: bold; }
      .md-dialog-container md-dialog md-dialog-content .next-info-container {
        margin-bottom: 10px; }
        .md-dialog-container md-dialog md-dialog-content .next-info-container .time {
          color: #787878; }
        .md-dialog-container md-dialog md-dialog-content .next-info-container .message {
          width: 100%;
          word-break: break-all; }
    .md-dialog-container md-dialog md-dialog-actions {
      padding: 0 20px 10px 20px;
      position: relative;
      background: white;
      flex-shrink: 0; }
      .md-dialog-container md-dialog md-dialog-actions .dialog-actions {
        width: 100%;
        display: flex;
        display: -moz-box;
        /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;
        /* TWEENER - IE 10 */
        display: -webkit-box;
        /* OLD - iOS 6-, Safari 3.1-6 */
        display: -webkit-flex;
        align-items: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-flex-align: center;
        -webkit-box-align: center;
        justify-content: center;
        -webkit-justify-content: center; }
    .md-dialog-container md-dialog.modal-secondary md-toolbar .md-toolbar-tools {
      background: #eeeeee;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
      color: #2d2d2d;
      padding-top: 0; }
      .md-dialog-container md-dialog.modal-secondary md-toolbar .md-toolbar-tools small {
        text-transform: none; }
      .md-dialog-container md-dialog.modal-secondary md-toolbar .md-toolbar-tools button {
        color: #3f6185; }
    .md-dialog-container md-dialog.modal-secondary md-dialog-actions {
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
      padding-bottom: 0; }
      .md-dialog-container md-dialog.modal-secondary md-dialog-actions .dialog-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .md-dialog-container md-dialog.modal-secondary md-dialog-actions .dialog-actions md-checkbox {
          margin-bottom: 0; }
        .md-dialog-container md-dialog.modal-secondary md-dialog-actions .dialog-actions button {
          margin-top: 15px;
          margin-bottom: 15px; }

.popupbutton {
  text-align: center; }
  .popupbutton button {
    margin: 0 auto !important;
    height: 80px;
    width: 80px;
    min-width: 80px;
    border-radius: 50px;
    padding: 0;
    display: block;
    background-color: #00c179 !important;
    font-size: 19px;
    text-align: center; }
    .popupbutton button i {
      color: white;
      display: inline-block;
      font-size: 35px;
      line-height: 80px;
      font-weight: 100;
      margin: 0; }
    .popupbutton button[disabled] {
      opacity: 0.3; }
  .popupbutton .button-label {
    color: #6f6f6f;
    font-weight: 200;
    margin-top: 9px;
    display: inline-block; }

.cdk-overlay-container .hol-full-modal {
  margin-left: 78px;
  max-width: 946px !important;
  width: 946px; }

@keyframes view_enter_animation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

[ui-view].ng-enter {
  opacity: 0;
  animation: 0.5s view_enter_animation; }

/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.new-holis-theme {
  height: 100%; }
  .new-holis-theme a,
  .new-holis-theme a:visited {
    color: #3f50b5; }
  .new-holis-theme small,
  .new-holis-theme strong {
    font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .new-holis-theme .hol-content-box {
    height: 100%;
    width: 100%;
    font-size: 16px;
    color: #171717; }
    .new-holis-theme .hol-content-box .disabled {
      opacity: 0.6; }
    .new-holis-theme .hol-content-box label {
      font-family: Avenir Next Condensed Demi, Helvetica, Arial, sans-serif;
      font-size: 14px;
      opacity: 0.8; }
      .new-holis-theme .hol-content-box label:not(.mat-checkbox-layout) {
        margin-bottom: 16px; }
    .new-holis-theme .hol-content-box .info-label {
      font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif;
      margin-bottom: 16px; }
    .new-holis-theme .hol-content-box__header {
      max-height: 2.5em;
      min-height: 2.5em;
      margin-bottom: 5px; }
    .new-holis-theme .hol-content-box__content {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;
      background-color: #ffffff; }
      .new-holis-theme .hol-content-box__content__col {
        display: flex;
        flex-direction: column;
        padding: 1em;
        padding-right: 24px; }
        .new-holis-theme .hol-content-box__content__col__header {
          max-height: 3em;
          min-height: 3em; }
        .new-holis-theme .hol-content-box__content__col__content {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column; }
          .new-holis-theme .hol-content-box__content__col__content.tabs {
            height: 100%; }
          .new-holis-theme .hol-content-box__content__col__content--background-title {
            background-color: rgba(201, 201, 201, 0.2);
            padding: 0.5em;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;
            font-family: Avenir Next Condensed Demi, Helvetica, Arial, sans-serif; }
          .new-holis-theme .hol-content-box__content__col__content--container {
            flex: 1;
            overflow: hidden; }
          .new-holis-theme .hol-content-box__content__col__content .decimal-rounded-list {
            counter-reset: count; }
            .new-holis-theme .hol-content-box__content__col__content .decimal-rounded-list .count {
              margin-left: 3em;
              margin-bottom: 4em; }
              .new-holis-theme .hol-content-box__content__col__content .decimal-rounded-list .count:before {
                content: counter(count);
                counter-increment: count;
                position: relative;
                left: -3em;
                background: #e8eaf6;
                height: 2em;
                width: 2em;
                line-height: 2em;
                text-align: center;
                border-radius: 2em;
                display: inline-block;
                font-family: Avenir Next Condensed Demi, Helvetica, Arial, sans-serif;
                font-size: 14px;
                opacity: 0.8; }
              .new-holis-theme .hol-content-box__content__col__content .decimal-rounded-list .count .title {
                font-family: Avenir Next Condensed Demi, Helvetica, Arial, sans-serif;
                font-size: 20px;
                margin-right: 1em;
                margin-left: -1.5em;
                margin-bottom: 1.2em;
                display: inline-block; }
              .new-holis-theme .hol-content-box__content__col__content .decimal-rounded-list .count .description-md {
                margin-top: -3em; }
                .new-holis-theme .hol-content-box__content__col__content .decimal-rounded-list .count .description-md ul {
                  margin-left: 0; }
                .new-holis-theme .hol-content-box__content__col__content .decimal-rounded-list .count .description-md ngx-md * {
                  font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif !important; }
                .new-holis-theme .hol-content-box__content__col__content .decimal-rounded-list .count .description-md ngx-md p:first-child {
                  font-family: Avenir Next Condensed Demi, Helvetica, Arial, sans-serif !important;
                  font-size: 20px;
                  margin-right: 1em; }
      .new-holis-theme .hol-content-box__content .btn-group-actions button {
        margin: 0.5em 1em; }
        .new-holis-theme .hol-content-box__content .btn-group-actions button:first-child {
          margin-left: 0; }
  .new-holis-theme .hol-row {
    display: flex;
    flex-direction: row;
    padding: 0 5px; }
    .new-holis-theme .hol-row > * {
      flex: 1; }
  .new-holis-theme .hol-column {
    display: flex;
    flex-direction: column; }
  .new-holis-theme .scroll-container {
    overflow: auto;
    padding: 1em 0;
    background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
    overflow-y: scroll;
    min-height: 100%;
    height: 100%;
    max-height: 100%; }
  .new-holis-theme mat-form-field input {
    font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif; }

.mat-form-field-appearance-fill .mat-form-field-underline {
  display: none; }

.iscroll-scroller {
  overflow: auto;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
  overflow-y: scroll;
  min-height: 100%;
  height: 100%;
  max-height: 100%; }

/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.hol-warn {
  color: #d51d1d !important; }

.hol-accent {
  color: #e8621c; }

.hol-success {
  color: #58960e !important; }

.hol-info {
  color: #3f50b5; }

.font14 {
  font-size: 14px;
  font-family: Nunito, Helvetica, Arial, sans-serif; }

/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.new-holis-theme {
  /* Success styling */
  /* Mat-button-toggle styling */ }
  .new-holis-theme .mat-button-toggle-group {
    margin-top: 16px;
    margin-bottom: 16px; }
  .new-holis-theme .mat-button.back-link .mat-button-wrapper {
    font-size: 16px;
    font-weight: 400;
    font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif !important; }
  .new-holis-theme button {
    letter-spacing: 0.09em;
    font-size: 14px !important; }
    .new-holis-theme button [class^='hol-icon-'],
    .new-holis-theme button [class*=' hol-icon-'] {
      line-height: 1.25em;
      font-size: 1.25em;
      margin-right: 0.25em; }
    .new-holis-theme button:not(.back-link) {
      text-transform: uppercase; }
  .new-holis-theme mat-button-toggle-group.roles-buttons {
    box-shadow: none; }
  .new-holis-theme .mat-button.mat-success,
  .new-holis-theme .mat-stroked-button.mat-success {
    color: #58960e !important; }
  .new-holis-theme .mat-button:not(:disabled).mat-success:hover,
  .new-holis-theme .mat-stroked-button:not(:disabled).mat-success:hover {
    background-color: rgba(88, 150, 14, 0.12); }
  .new-holis-theme .mat-raised-button.mat-success,
  .new-holis-theme .mat-flat-button.mat-success,
  .new-holis-theme .mat-fab.mat-success,
  .new-holis-theme .mat-mini-fab.mat-success {
    color: #fff;
    background-color: #58960e; }
  .new-holis-theme .mat-icon-button.mat-success {
    color: #58960e; }
  .new-holis-theme .mat-button-toggle {
    letter-spacing: 0.09em; }
    .new-holis-theme .mat-button-toggle [class^='hol-icon-'],
    .new-holis-theme .mat-button-toggle [class*=' hol-icon-'] {
      line-height: 1.25em;
      font-size: 1.25em;
      margin-right: 0.25em; }
    .new-holis-theme .mat-button-toggle.mat-success {
      color: #58960e !important; }
      .new-holis-theme .mat-button-toggle.mat-success:not(.mat-button-toggle-disabled):hover, .new-holis-theme .mat-button-toggle.mat-success.mat-button-toggle-checked {
        background-color: #58960e !important;
        color: #fff !important; }
    .new-holis-theme .mat-button-toggle.mat-warn {
      color: #d51d1d !important; }
      .new-holis-theme .mat-button-toggle.mat-warn:not(.mat-button-toggle-disabled):hover, .new-holis-theme .mat-button-toggle.mat-warn.mat-button-toggle-checked {
        background-color: #d51d1d !important;
        color: #fff !important; }
    .new-holis-theme .mat-button-toggle.mat-basic {
      color: #848484 !important;
      border-color: #848484 !important; }

@font-face {
  font-family: 'holis-icon';
  src: url("/assets/fonts/holis-icon.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^='hol-icon-'],
[class*=' hol-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'holis-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.hol-icon-refresh:before {
  content: '\e941'; }

.hol-icon-down:before {
  content: '\e900'; }

.hol-icon-left:before {
  content: '\e901'; }

.hol-icon-right:before {
  content: '\e902'; }

.hol-icon-up:before {
  content: '\e903'; }

.hol-icon-add-shopping-cart:before {
  content: '\e904'; }

.hol-icon-add:before {
  content: '\e905'; }

.hol-icon-airplane-mode-active:before {
  content: '\e906'; }

.hol-icon-align-center:before {
  content: '\e907'; }

.hol-icon-align-left:before {
  content: '\e908'; }

.hol-icon-align-right:before {
  content: '\e909'; }

.hol-icon-archive:before {
  content: '\e90a'; }

.hol-icon-breaking-news:before {
  content: '\e90b'; }

.hol-icon-calendar:before {
  content: '\e90c'; }

.hol-icon-cancel:before {
  content: '\e90d'; }

.hol-icon-chat:before {
  content: '\e90e'; }

.hol-icon-check-circle:before {
  content: '\e90f'; }

.hol-icon-circle:before {
  content: '\e910'; }

.hol-icon-close:before {
  content: '\e911'; }

.hol-icon-create-folder:before {
  content: '\e912'; }

.hol-icon-dashboard:before {
  content: '\e913'; }

.hol-icon-delete:before {
  content: '\e914'; }

.hol-icon-document:before {
  content: '\e915'; }

.hol-icon-done:before {
  content: '\e916'; }

.hol-icon-download:before {
  content: '\e917'; }

.hol-icon-drag:before {
  content: '\e918'; }

.hol-icon-duplicate:before {
  content: '\e919'; }

.hol-icon-edit:before {
  content: '\e91a'; }

.hol-icon-error:before {
  content: '\e91b'; }

.hol-icon-favorite:before {
  content: '\e91c'; }

.hol-icon-file:before {
  content: '\e91d'; }

.hol-icon-filter:before {
  content: '\e91e'; }

.hol-icon-fingerprint:before {
  content: '\e91f'; }

.hol-icon-flight-Land:before {
  content: '\e920'; }

.hol-icon-flight-takeoff:before {
  content: '\e921'; }

.hol-icon-folder:before {
  content: '\e922'; }

.hol-icon-fullscreen-exit:before {
  content: '\e923'; }

.hol-icon-fullscreen:before {
  content: '\e924'; }

.hol-icon-group:before {
  content: '\e925'; }

.hol-icon-history:before {
  content: '\e926'; }

.hol-icon-info:before {
  content: '\e927'; }

.hol-icon-link:before {
  content: '\e928'; }

.hol-icon-location-on:before {
  content: '\e929'; }

.hol-icon-mail:before {
  content: '\e92a'; }

.hol-icon-menu:before {
  content: '\e92b'; }

.hol-icon-more:before {
  content: '\e92c'; }

.hol-icon-notifications:before {
  content: '\e92d'; }

.hol-icon-open:before {
  content: '\e92e'; }

.hol-icon-picture:before {
  content: '\e92f'; }

.hol-icon-pin:before {
  content: '\e930'; }

.hol-icon-poll:before {
  content: '\e931'; }

.hol-icon-profile:before {
  content: '\e932'; }

.hol-icon-redo:before {
  content: '\e933'; }

.hol-icon-save:before {
  content: '\e934'; }

.hol-icon-search:before {
  content: '\e935'; }

.hol-icon-send:before {
  content: '\e936'; }

.hol-icon-share:before {
  content: '\e937'; }

.hol-icon-shopping-cart:before {
  content: '\e938'; }

.hol-icon-thumbs-up:before {
  content: '\e939'; }

.hol-icon-time:before {
  content: '\e93a'; }

.hol-icon-translate:before {
  content: '\e93b'; }

.hol-icon-tree:before {
  content: '\e93c'; }

.hol-icon-tune:before {
  content: '\e93d'; }

.hol-icon-visibility-off:before {
  content: '\e93e'; }

.hol-icon-visibility:before {
  content: '\e93f'; }

.hol-icon-whatshot:before {
  content: '\e940'; }

.hol-menu-panel {
  margin: 0 0 2px 0 !important;
  border-radius: 0 !important;
  background: #787878; }
  .hol-menu-panel .panel-heading {
    text-align: left;
    text-transform: initial;
    padding: 0; }
    .hol-menu-panel .panel-heading .panel-title {
      font-size: 15px; }
      .hol-menu-panel .panel-heading .panel-title .hol-menu-panel-title {
        padding: 10px 5px;
        display: flex;
        display: -moz-box;
        /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;
        /* TWEENER - IE 10 */
        display: -webkit-box;
        /* OLD - iOS 6-, Safari 3.1-6 */
        display: -webkit-flex;
        align-items: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-flex-align: center;
        -webkit-box-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        justify-content: space-between;
        font-weight: bold; }
      .hol-menu-panel .panel-heading .panel-title a:hover, .hol-menu-panel .panel-heading .panel-title a:focus {
        text-decoration: none; }
    .hol-menu-panel .panel-heading .hol-menu-panel-icon {
      position: absolute;
      right: 0;
      font-size: 16px;
      font-weight: bold;
      transform: rotateZ(0deg); }
  .hol-menu-panel .panel-body {
    padding: 0;
    border-top: none !important; }
  .hol-menu-panel.panel-open .hol-menu-panel-icon.arrow {
    transform: rotateZ(90deg); }

@keyframes blink-animation {
  to {
    visibility: hidden; } }

.clock-blink {
  animation: blink-animation 1s steps(5, start) infinite; }

.flights-status-graph__zone:nth-child(1) {
  fill: #ffd479; }

.flights-status-graph__zone:nth-child(2) {
  fill: #ffa03a; }

.flights-status-graph__zone:nth-child(3) {
  fill: #fb9190; }

.flights-status-graph__zone:nth-child(4) {
  fill: #ff2600; }

.flights-status-graph__zone:nth-child(5) {
  fill: #cd2072; }

.flights-status-graph__line {
  stroke-width: 1px;
  stroke: #3f6185; }
  .flights-status-graph__line-outer {
    stroke-width: 3px;
    stroke: white; }

.flights-status-graph__point {
  fill: white;
  stroke: black;
  stroke-width: 1px; }

.flights-status-graph__axis {
  font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif;
  font-size: 13px; }

.events-box .hol-box__content .event-item {
  padding: 0; }
  .events-box .hol-box__content .event-item:not(:last-child) {
    margin-bottom: 4px; }

.events-box a.external-document {
  float: none; }

.button-row {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .button-row.to-right {
    justify-content: flex-end; }

.file-upload-btn-container {
  display: inline-block; }

.uploadedFile {
  border: 1px solid #dddddd;
  padding: 8px 16px;
  display: inline-block; }
  .uploadedFile .deleteButton {
    display: inline-block; }
    .uploadedFile .deleteButton i {
      display: flex;
      display: -moz-box;
      /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;
      /* TWEENER - IE 10 */
      display: -webkit-box;
      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -webkit-flex;
      align-items: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -webkit-flex-align: center;
      -webkit-box-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      width: 40px;
      height: 40px;
      color: #ed3b4b;
      font-size: 2em; }

a.external-document {
  border: 1px solid #e7e7e7;
  padding: 8px;
  background-color: white;
  background-image: url(/assets/images/right-arrow.svg);
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: center;
  background-size: 20px 20px;
  display: block;
  float: right;
  font-weight: 400;
  padding-right: 25px; }

a.external-attachment .icon:before {
  color: #2a4057; }

.file-image-display-full {
  display: flex; }
  .file-image-display-full a.external-attachment figure {
    text-align: center;
    overflow: hidden;
    position: relative; }
    .file-image-display-full a.external-attachment figure img {
      max-height: 50vh;
      max-width: 100%; }
    .file-image-display-full a.external-attachment figure figcaption {
      background-color: black;
      color: white;
      transition: 0.3s;
      transform: translateY(0);
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0; }
      .file-image-display-full a.external-attachment figure figcaption .icon:before {
        color: white; }
    .file-image-display-full a.external-attachment figure:hover figcaption {
      opacity: 1;
      transform: translateY(-100%); }

.row.has-attachments {
  margin-top: -10px; }
  .row.has-attachments .file-upload:last-child button {
    margin-right: 0; }

.file-upload-btn-container.no-text button {
  width: 40px;
  min-width: 40px;
  padding: 0 !important; }
  .file-upload-btn-container.no-text button md-icon {
    margin-right: 0 !important; }

.connected-users-box li {
  font-weight: bold; }
  .connected-users-box li .hol-duty-indicator {
    width: 6px;
    height: 6px;
    margin: 0 5px 10px -5px; }

.situation-item {
  background: white;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 10px 10px 0 10px; }
  .situation-item__title .icon {
    margin-right: 5px; }
  .situation-item__text {
    font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }
  .situation-item__details {
    border-top: 1px solid #3f6185;
    font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif;
    margin: 0 -10px;
    padding: 0 10px;
    min-height: 26px;
    white-space: nowrap; }
    .situation-item__details .event-date {
      margin: 0 5px; }
    .situation-item__details .situation-event.date {
      background: none;
      text-align: right; }
    .situation-item__details .status-indicator {
      margin-right: -10px;
      margin-left: 10px;
      align-self: flex-start; }
      .situation-item__details .status-indicator .icon {
        height: 25px;
        width: 25px;
        display: block;
        line-height: 25px;
        text-align: center; }
    .situation-item__details .status-time:before {
      content: '►'; }
  .situation-item .closed-by {
    text-align: right; }

.crew-event-filter-box {
  height: auto;
  padding: 10px; }
  .crew-event-filter-box .filters-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap; }
    .crew-event-filter-box .filters-container label {
      margin-right: 10px; }
    .crew-event-filter-box .filters-container .function-container .function-filter .md-button {
      margin-top: 0;
      min-height: 28px;
      min-width: 0; }
    .crew-event-filter-box .filters-container .impact-container {
      margin-right: 10px; }
      .crew-event-filter-box .filters-container .impact-container .impact-filter {
        display: inline-block;
        white-space: nowrap; }
        .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:first-child {
          margin-left: 0; }
        .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:last-child {
          margin-right: 0; }
        .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(0) {
          border: 2px solid #00c179;
          color: #00c179;
          background: #e6f9f2;
          min-width: 0;
          min-height: 28px;
          margin-top: 0;
          box-shadow: none;
          line-height: 12px;
          text-transform: uppercase; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(0):hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(0):focus {
            background: #e6f9f2;
            color: #00c179; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(0).active {
            background: #00c179;
            color: white; }
            .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(0).active:hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(0).active:focus {
              background: #00c179;
              color: white; }
        .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(1) {
          border: 2px solid #ff2600;
          color: #ff2600;
          background: #ffe9e6;
          min-width: 0;
          min-height: 28px;
          margin-top: 0;
          box-shadow: none;
          line-height: 12px;
          text-transform: uppercase; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(1):hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(1):focus {
            background: #ffe9e6;
            color: #ff2600; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(1).active {
            background: #ff2600;
            color: white; }
            .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(1).active:hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(1).active:focus {
              background: #ff2600;
              color: white; }
        .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(2) {
          border: 2px solid #ca8214;
          color: #ca8214;
          background: #faf3e8;
          min-width: 0;
          min-height: 28px;
          margin-top: 0;
          box-shadow: none;
          line-height: 12px;
          text-transform: uppercase; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(2):hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(2):focus {
            background: #faf3e8;
            color: #ca8214; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(2).active {
            background: #ca8214;
            color: white; }
            .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(2).active:hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(2).active:focus {
              background: #ca8214;
              color: white; }
        .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(3) {
          border: 2px solid #faa916;
          color: #faa916;
          background: #fff6e8;
          min-width: 0;
          min-height: 28px;
          margin-top: 0;
          box-shadow: none;
          line-height: 12px;
          text-transform: uppercase; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(3):hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(3):focus {
            background: #fff6e8;
            color: #faa916; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(3).active {
            background: #faa916;
            color: white; }
            .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(3).active:hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(3).active:focus {
              background: #faa916;
              color: white; }
        .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(4) {
          border: 2px solid #2c7684;
          color: #2c7684;
          background: #eaf1f3;
          min-width: 0;
          min-height: 28px;
          margin-top: 0;
          box-shadow: none;
          line-height: 12px;
          text-transform: uppercase; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(4):hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(4):focus {
            background: #eaf1f3;
            color: #2c7684; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(4).active {
            background: #2c7684;
            color: white; }
            .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(4).active:hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(4).active:focus {
              background: #2c7684;
              color: white; }
        .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(5) {
          border: 2px solid #3f6185;
          color: #3f6185;
          background: #eceff3;
          min-width: 0;
          min-height: 28px;
          margin-top: 0;
          box-shadow: none;
          line-height: 12px;
          text-transform: uppercase; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(5):hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(5):focus {
            background: #eceff3;
            color: #3f6185; }
          .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(5).active {
            background: #3f6185;
            color: white; }
            .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(5).active:hover, .crew-event-filter-box .filters-container .impact-container .impact-filter button.md-button:nth-child(5).active:focus {
              background: #3f6185;
              color: white; }
    .crew-event-filter-box .filters-container ul.crew-event-filter {
      min-width: 0; }
      .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(0) button.md-button {
        border: 2px solid #00c179;
        color: #00c179;
        background: #e6f9f2;
        min-width: 0;
        min-height: 28px;
        margin-top: 0;
        box-shadow: none;
        line-height: 12px;
        text-transform: uppercase;
        min-width: 60px;
        width: calc(100% - 15px); }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(0) button.md-button:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(0) button.md-button:focus {
          background: #e6f9f2;
          color: #00c179; }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(0) button.md-button.active {
          background: #00c179;
          color: white; }
          .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(0) button.md-button.active:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(0) button.md-button.active:focus {
            background: #00c179;
            color: white; }
      .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(1) button.md-button {
        border: 2px solid #ff2600;
        color: #ff2600;
        background: #ffe9e6;
        min-width: 0;
        min-height: 28px;
        margin-top: 0;
        box-shadow: none;
        line-height: 12px;
        text-transform: uppercase;
        min-width: 60px;
        width: calc(100% - 15px); }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(1) button.md-button:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(1) button.md-button:focus {
          background: #ffe9e6;
          color: #ff2600; }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(1) button.md-button.active {
          background: #ff2600;
          color: white; }
          .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(1) button.md-button.active:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(1) button.md-button.active:focus {
            background: #ff2600;
            color: white; }
      .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(2) button.md-button {
        border: 2px solid #ca8214;
        color: #ca8214;
        background: #faf3e8;
        min-width: 0;
        min-height: 28px;
        margin-top: 0;
        box-shadow: none;
        line-height: 12px;
        text-transform: uppercase;
        min-width: 60px;
        width: calc(100% - 15px); }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(2) button.md-button:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(2) button.md-button:focus {
          background: #faf3e8;
          color: #ca8214; }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(2) button.md-button.active {
          background: #ca8214;
          color: white; }
          .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(2) button.md-button.active:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(2) button.md-button.active:focus {
            background: #ca8214;
            color: white; }
      .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(3) button.md-button {
        border: 2px solid #faa916;
        color: #faa916;
        background: #fff6e8;
        min-width: 0;
        min-height: 28px;
        margin-top: 0;
        box-shadow: none;
        line-height: 12px;
        text-transform: uppercase;
        min-width: 60px;
        width: calc(100% - 15px); }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(3) button.md-button:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(3) button.md-button:focus {
          background: #fff6e8;
          color: #faa916; }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(3) button.md-button.active {
          background: #faa916;
          color: white; }
          .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(3) button.md-button.active:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(3) button.md-button.active:focus {
            background: #faa916;
            color: white; }
      .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(4) button.md-button {
        border: 2px solid #2c7684;
        color: #2c7684;
        background: #eaf1f3;
        min-width: 0;
        min-height: 28px;
        margin-top: 0;
        box-shadow: none;
        line-height: 12px;
        text-transform: uppercase;
        min-width: 60px;
        width: calc(100% - 15px); }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(4) button.md-button:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(4) button.md-button:focus {
          background: #eaf1f3;
          color: #2c7684; }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(4) button.md-button.active {
          background: #2c7684;
          color: white; }
          .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(4) button.md-button.active:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(4) button.md-button.active:focus {
            background: #2c7684;
            color: white; }
      .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(5) button.md-button {
        border: 2px solid #3f6185;
        color: #3f6185;
        background: #eceff3;
        min-width: 0;
        min-height: 28px;
        margin-top: 0;
        box-shadow: none;
        line-height: 12px;
        text-transform: uppercase;
        min-width: 60px;
        width: calc(100% - 15px); }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(5) button.md-button:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(5) button.md-button:focus {
          background: #eceff3;
          color: #3f6185; }
        .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(5) button.md-button.active {
          background: #3f6185;
          color: white; }
          .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(5) button.md-button.active:hover, .crew-event-filter-box .filters-container ul.crew-event-filter li:not(.select-all):nth-child(5) button.md-button.active:focus {
            background: #3f6185;
            color: white; }
      .crew-event-filter-box .filters-container ul.crew-event-filter .select-all-btn {
        min-width: 60px;
        width: calc(100% - 15px); }

.crew-event-line-color.filter-color-0 .line-color {
  height: 2px;
  margin: 10px 0px;
  background-color: #00c179; }

.crew-event-line-color.filter-color-1 .line-color {
  height: 2px;
  margin: 10px 0px;
  background-color: #ff2600; }

.crew-event-line-color.filter-color-2 .line-color {
  height: 2px;
  margin: 10px 0px;
  background-color: #ca8214; }

.crew-event-line-color.filter-color-3 .line-color {
  height: 2px;
  margin: 10px 0px;
  background-color: #faa916; }

.crew-event-line-color.filter-color-4 .line-color {
  height: 2px;
  margin: 10px 0px;
  background-color: #2c7684; }

.crew-event-line-color.filter-color-5 .line-color {
  height: 2px;
  margin: 10px 0px;
  background-color: #3f6185; }

.crew-event-item {
  padding: 10px;
  font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif;
  background-color: #f1f5f5;
  width: 100%; }
  .crew-event-item.filter-color-0 .event-item__details {
    border-color: #00c179; }
  .crew-event-item.filter-color-1 .event-item__details {
    border-color: #ff2600; }
  .crew-event-item.filter-color-2 .event-item__details {
    border-color: #ca8214; }
  .crew-event-item.filter-color-3 .event-item__details {
    border-color: #faa916; }
  .crew-event-item.filter-color-4 .event-item__details {
    border-color: #2c7684; }
  .crew-event-item.filter-color-5 .event-item__details {
    border-color: #3f6185; }
  .crew-event-item .event-item__title {
    white-space: nowrap;
    flex-wrap: wrap; }
    .crew-event-item .event-item__title .flight-number {
      color: #2d2d2d; }
    .crew-event-item .event-item__title .text-right {
      flex: 1;
      margin-left: 5px; }
    .crew-event-item .event-item__title .text-center {
      align-self: center; }
  .crew-event-item .event-item__details {
    border-top: 2px solid #c9cbcb;
    margin-top: 5px;
    padding-top: 10px;
    font-weight: normal;
    flex-wrap: wrap; }
  .crew-event-item .almost-elapsed {
    font-weight: bold;
    color: #f4902f; }
  .crew-event-item .elapsed {
    font-weight: bold;
    color: #ff0036; }
  .crew-event-item .last-info {
    text-align: right;
    color: #787878;
    margin-bottom: -5px; }

.crew-todo-box .hol-box__header {
  justify-content: normal; }
  .crew-todo-box .hol-box__header .title {
    flex: 1; }
    .crew-todo-box .hol-box__header .title .icon {
      padding: 3px 2px 2px 2px; }
  .crew-todo-box .hol-box__header .filters-container {
    display: flex; }
    .crew-todo-box .hol-box__header .filters-container .filter-container {
      margin-left: 10px; }
      .crew-todo-box .hol-box__header .filters-container .filter-container button {
        min-width: 0px;
        min-height: 28px;
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden; }
        .crew-todo-box .hol-box__header .filters-container .filter-container button span:not(.caret) {
          overflow: hidden;
          text-overflow: ellipsis; }
    .crew-todo-box .hol-box__header .filters-container .hol-search-box input {
      max-width: 120px; }

.crew-todo-box .add-button {
  text-align: left;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  text-overflow: ellipsis; }

.crew-todo-box .collapse-container {
  height: 2em; }
  .crew-todo-box .collapse-container .btn-collapse {
    color: #3f6185;
    background: transparent; }
    .crew-todo-box .collapse-container .btn-collapse i {
      transform: rotateZ(180deg); }
    .crew-todo-box .collapse-container .btn-collapse.collapsed i {
      transform: rotateZ(0deg); }

.crew-todo-box .hol-box__content .iscroll-wrapper {
  left: 0;
  right: 0;
  bottom: 0; }

.crew-todo-box .main-content {
  display: flex;
  flex-direction: column; }

.todo-filter-dropdown-menu-mobile {
  right: 0 !important; }
  .todo-filter-dropdown-menu-mobile li a {
    text-overflow: ellipsis;
    overflow: hidden; }

[filter-color].filter-color-0,
[appfiltercolor].filter-color-0 {
  color: #00c179;
  background-color: #e6f9f2; }

[filter-color].filter-color-1,
[appfiltercolor].filter-color-1 {
  color: #ff2600;
  background-color: #ffe9e6; }

[filter-color].filter-color-2,
[appfiltercolor].filter-color-2 {
  color: #ca8214;
  background-color: #faf3e8; }

[filter-color].filter-color-3,
[appfiltercolor].filter-color-3 {
  color: #faa916;
  background-color: #fff6e8; }

[filter-color].filter-color-4,
[appfiltercolor].filter-color-4 {
  color: #2c7684;
  background-color: #eaf1f3; }

[filter-color].filter-color-5,
[appfiltercolor].filter-color-5 {
  color: #3f6185;
  background-color: #eceff3; }

.header-component {
  background: white;
  border-bottom: 1px solid #c9cbcb;
  height: 60px; }
  .header-component .menu-icon {
    color: #3f6185; }
  .header-component .content-group {
    flex: 1;
    display: flex; }
    .header-component .content-group .mcc-filters-container {
      display: flex;
      align-items: center; }
    .header-component .content-group .mcc-text {
      color: #bb00ff;
      font-weight: bold;
      text-align: center;
      font-size: 26px;
      margin-left: 10px; }
    .header-component .content-group .crew-text {
      color: #008568;
      font-weight: bold;
      text-align: center;
      font-size: 26px; }
    .header-component .content-group .occ-text {
      color: #0030bf;
      font-weight: bold;
      text-align: center;
      font-size: 26px;
      padding-right: 10px; }
    .header-component .content-group .ecl-text {
      color: #e35700;
      font-weight: bold;
      text-align: center;
      font-size: 26px;
      padding-right: 10px; }
  .header-component button.hamburger-button {
    border: none;
    background: white; }
    .header-component button.hamburger-button:hover {
      /* Darker background on mouse-over */
      background: white; }
  .header-component .user-group {
    display: flex;
    align-self: stretch;
    color: #2d2d2d; }
    .header-component .user-group button.header-button {
      padding: 0 10px;
      background: transparent;
      border-width: 0 0 0 1px;
      border-color: #c9cbcb; }
      .header-component .user-group button.header-button:hover {
        /* Darker background on mouse-over */
        background: #c9cbcb; }
      .header-component .user-group button.header-button.button-logout {
        max-width: 160px; }
        .header-component .user-group button.header-button.button-logout .user-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-right: 5px; }

.breaking-news-container {
  background: #ff0825;
  color: white;
  font-weight: bold;
  padding: 5px 0;
  width: 100%;
  display: block; }
  .breaking-news-container .news {
    white-space: nowrap; }
    .breaking-news-container .news .separator {
      margin: 0 10px; }

.img-icon {
  height: 25px;
  width: 25px; }

.chat-button {
  height: 100%; }
  .chat-button .icon-container {
    position: relative; }
    .chat-button .icon-container .hol-error {
      position: absolute;
      top: 20px;
      right: 5px;
      font-size: 1em; }
  .chat-button.unread {
    animation: blink-background 1s ease-in-out infinite alternate; }

@keyframes blink-background {
  to {
    background: #ff0036; }
  to {
    color: white; } }

.chat-component {
  background: lightgoldenrodyellow;
  position: absolute;
  right: 0;
  top: 60px;
  width: 300px;
  bottom: 0;
  z-index: 75;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.36); }
  .chat-component .hol-box {
    border: none; }
  .chat-component .hol-box__header {
    background: #f1f5f5; }
    .chat-component .hol-box__header.subheader {
      padding: 10px 0 0 0;
      min-height: 0; }
      .chat-component .hol-box__header.subheader .dropdown-container {
        width: 100%; }
      .chat-component .hol-box__header.subheader .btn-group {
        border-top: 1px solid #c9cbcb;
        width: 100%; }
      .chat-component .hol-box__header.subheader .crew-event-item,
      .chat-component .hol-box__header.subheader .occ-event-item {
        background: none;
        width: calc(100% - 30px);
        display: inline-block;
        font-weight: initial;
        color: initial;
        font-size: 14px; }
      .chat-component .hol-box__header.subheader .dropdown-toggle {
        cursor: pointer; }
        .chat-component .hol-box__header.subheader .dropdown-toggle:hover {
          background: #c9cbcb; }
      .chat-component .hol-box__header.subheader .channel-dropdown {
        right: 0;
        padding: 0;
        border-radius: 0;
        margin-top: 0;
        max-height: 400px;
        overflow: auto; }
        .chat-component .hol-box__header.subheader .channel-dropdown li:not(:first-child) {
          border-top: 1px solid #c9cbcb; }
        .chat-component .hol-box__header.subheader .channel-dropdown li:hover, .chat-component .hol-box__header.subheader .channel-dropdown li:focus {
          background: #c9cbcb; }
        .chat-component .hol-box__header.subheader .channel-dropdown li .unread-indicator {
          background: red;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          color: white;
          font-size: 12px;
          display: inline-block;
          text-align: center;
          line-height: 20px; }
        .chat-component .hol-box__header.subheader .channel-dropdown li.active {
          background: #337ab7; }
          .chat-component .hol-box__header.subheader .channel-dropdown li.active .crew-event-item {
            color: white; }
            .chat-component .hol-box__header.subheader .channel-dropdown li.active .crew-event-item .flight-number {
              color: white; }
          .chat-component .hol-box__header.subheader .channel-dropdown li.active .occ-event-item {
            color: white; }
            .chat-component .hol-box__header.subheader .channel-dropdown li.active .occ-event-item .event-code,
            .chat-component .hol-box__header.subheader .channel-dropdown li.active .occ-event-item .event-title {
              color: white; }

.chat-channel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  padding: 0;
  background: #e4e9ee; }
  .chat-channel .chat-error {
    background: #e4e9ee;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .chat-channel .chat-messages {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 114px;
    right: 0;
    left: 0; }
    .chat-channel .chat-messages.no-messages {
      background: #e4e9ee; }
    .chat-channel .chat-messages ul.hol-list {
      display: flex;
      flex-direction: column; }
    .chat-channel .chat-messages li {
      padding: 10px;
      transition: border-color 0.3s ease; }
      .chat-channel .chat-messages li:before {
        color: #ff0036;
        transition: opacity 0.3s ease;
        content: 'new message';
        margin: -10px 0;
        font-size: 0.8em;
        position: absolute;
        padding: 0 4px;
        right: 0;
        opacity: 0; }
      .chat-channel .chat-messages li.new {
        border-top-color: #ff0036; }
        .chat-channel .chat-messages li.new:before {
          opacity: 1; }
      .chat-channel .chat-messages li .message-header {
        text-align: left; }
        .chat-channel .chat-messages li .message-header .username {
          color: #2d2d2d;
          font-style: italic;
          font-weight: bold;
          font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }
        .chat-channel .chat-messages li .message-header .time {
          color: #787878;
          margin-left: 10px; }
      .chat-channel .chat-messages li .message {
        font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }
    .chat-channel .chat-messages .chat-no-messages {
      text-align: center;
      opacity: 0.5;
      display: flex;
      margin: auto;
      flex-direction: column; }
      .chat-channel .chat-messages .chat-no-messages .icon {
        height: 80px;
        width: 74px;
        color: #babdbf;
        margin: auto; }
      .chat-channel .chat-messages .chat-no-messages .text {
        color: #2d2d2d;
        font-weight: bold;
        padding: 10px;
        font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }
  .chat-channel .chat-input {
    background: #e4e9ee;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5); }
    .chat-channel .chat-input .form-group {
      margin-bottom: 0; }
      .chat-channel .chat-input .form-group .form-control {
        width: 100%; }

.tag-selector {
  display: flex;
  align-items: center; }
  .tag-selector label {
    margin-bottom: 0; }
  .tag-selector .filters-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap; }
    .tag-selector .filters-container button {
      width: auto;
      margin-right: 5px;
      padding: 0 3px 0px 3px;
      min-height: 25px;
      max-height: 25px;
      display: flex;
      align-items: center; }
    .tag-selector .filters-container .btn-group {
      margin-left: 10px; }
    .tag-selector .filters-container button:not(.select-all-btn):not(.dropdown-btn) {
      border: 2px solid #3f6185;
      color: #3f6185;
      background: #eceff3;
      min-width: 0;
      min-height: 28px;
      margin-top: 0;
      box-shadow: none;
      line-height: 12px;
      text-transform: uppercase; }
      .tag-selector .filters-container button:not(.select-all-btn):not(.dropdown-btn):hover, .tag-selector .filters-container button:not(.select-all-btn):not(.dropdown-btn):focus {
        background: #eceff3;
        color: #3f6185; }
      .tag-selector .filters-container button:not(.select-all-btn):not(.dropdown-btn).active {
        background: #3f6185;
        color: white; }
        .tag-selector .filters-container button:not(.select-all-btn):not(.dropdown-btn).active:hover, .tag-selector .filters-container button:not(.select-all-btn):not(.dropdown-btn).active:focus {
          background: #3f6185;
          color: white; }
    .tag-selector .filters-container button.dropdown-btn {
      margin-top: 0;
      min-height: 28px;
      min-width: 0; }
  .tag-selector .select-all-btn {
    margin: 0; }

.tag {
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 11px;
  color: white;
  padding: 5px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden; }

.tag-selector-container {
  margin-bottom: 20px; }

.md-sidenav-left .header {
  height: 16.67%; }
  .md-sidenav-left .header .company-logo {
    margin-left: 24px;
    margin-top: 10px;
    margin-bottom: 5px;
    height: calc(100% - 15px); }
  .md-sidenav-left .header .close-button {
    position: absolute;
    top: 20px;
    right: 10px;
    color: #3f6185;
    padding: 0; }
    .md-sidenav-left .header .close-button:hover {
      background-color: #cccccc; }
    .md-sidenav-left .header .close-button .mat-icon {
      font-size: 40px;
      height: 40px;
      width: 40px; }

.md-sidenav-left .line {
  background: #d8d8d8;
  width: 85%;
  height: 1px;
  margin: 0 auto; }

.md-sidenav-left .vertical-menu {
  padding: 12px 0; }
  .md-sidenav-left .vertical-menu a {
    display: block;
    /* Make the links appear below each other */
    padding: 12px 48px;
    /* Add some padding */
    text-decoration: none;
    /* Remove underline from links */
    font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 22px;
    height: 48px; }

.md-sidenav-left .menu-occ {
  color: #0030bf; }

.md-sidenav-left .menu-ecl {
  color: #e35700; }

.md-sidenav-left .menu-ops {
  color: #0b84b0; }

.md-sidenav-left .menu-crew {
  color: #008568; }

.md-sidenav-left .menu-mcc {
  color: #bb00ff; }

.md-sidenav-left a:hover {
  background: #bdbdbd;
  /* Dark grey background on mouse-over */ }

.md-sidenav-left a.active {
  background-color: #4caf50;
  /* Add a green color to the "active/current" link */
  color: white; }

.md-sidenav-left .vertical-menu-settings a {
  display: flex;
  /* Make the links appear below each other */
  align-items: center;
  padding: 14px 48px;
  /* Add some padding */
  text-decoration: none;
  /* Remove underline from links */
  font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif;
  font-weight: lighter;
  font-size: 13px;
  color: #2d2d2d;
  height: 43px; }
  .md-sidenav-left .vertical-menu-settings a i,
  .md-sidenav-left .vertical-menu-settings a img {
    position: absolute;
    right: 40px; }
    .md-sidenav-left .vertical-menu-settings a i.managers,
    .md-sidenav-left .vertical-menu-settings a img.managers {
      right: 33px; }

md-sidenav,
md-backdrop {
  position: fixed; }

.isOpen {
  overflow: hidden; }

md-dialog.md-content-overflow .md-actions,
md-dialog.md-content-overflow md-dialog-actions {
  border-top-width: 0px;
  padding-top: 10px; }

.md-dialog-container ~ .md-dialog-container {
  animation: backdropAnim 0.45s;
  animation-fill-mode: forwards; }

@keyframes backdropAnim {
  from {
    background: transparent; }
  to {
    background: rgba(33, 33, 33, 0.48); } }

.breakingNewsModal .wrapper {
  display: flex;
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-flex-align: center;
  -webkit-box-align: center;
  justify-content: center;
  -webkit-justify-content: center; }

.breakingNewsModal .breakingNews {
  list-style: none;
  margin: 0;
  padding: 0; }
  .breakingNewsModal .breakingNews li.news-item {
    padding: 0;
    border-bottom: 1px solid #cecece;
    position: relative;
    transition: background-color 0.2s ease-in; }
    .breakingNewsModal .breakingNews li.news-item.ng-enter-stagger {
      transition-delay: 0.1s;
      transition-duration: 0s; }
    .breakingNewsModal .breakingNews li.news-item.ng-enter, .breakingNewsModal .breakingNews li.news-item.ng-enter-prepare {
      transition: opacity, border-bottom-color, transform 0.5s ease;
      opacity: 0;
      transform: translateX(-100px);
      border-bottom-color: transparent; }
      .breakingNewsModal .breakingNews li.news-item.ng-enter.ng-enter-active, .breakingNewsModal .breakingNews li.news-item.ng-enter-prepare.ng-enter-active {
        opacity: 1;
        transform: translateX(0);
        border-bottom-color: #cecece; }
    .breakingNewsModal .breakingNews li.news-item.ng-click-active, .breakingNewsModal .breakingNews li.news-item.clickable:hover {
      background-color: #cecece;
      opacity: 1 !important; }
    .breakingNewsModal .breakingNews li.news-item .news,
    .breakingNewsModal .breakingNews li.news-item .deleteButton {
      display: inline-block; }
    .breakingNewsModal .breakingNews li.news-item .news {
      width: calc(100% - 60px);
      color: black;
      font-weight: 400; }
      .breakingNewsModal .breakingNews li.news-item .news .news-text {
        padding: 20px 10px; }
    .breakingNewsModal .breakingNews li.news-item .deleteButton i {
      display: flex;
      display: -moz-box;
      /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;
      /* TWEENER - IE 10 */
      display: -webkit-box;
      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -webkit-flex;
      align-items: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -webkit-flex-align: center;
      -webkit-box-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      width: 60px;
      height: 60px;
      color: #ed3b4b;
      font-size: 2em; }
    .breakingNewsModal .breakingNews li.news-item.item-infiniscroll {
      text-align: center;
      border: none;
      padding: 20px;
      background-color: transparent; }

.breakingNewsModal .news-edit {
  margin-bottom: 10px;
  margin-top: 10px; }

.breakingNewsModal .buttons {
  text-align: center;
  margin-top: 7px; }

.breakingNewsModal .form-group.add {
  padding: 20px;
  margin-top: 20px; }

.md-dialog-container md-dialog.changeUserStatusModal {
  width: 300px;
  min-width: 300px;
  font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }
  .md-dialog-container md-dialog.changeUserStatusModal .dialog-content {
    text-align: center;
    font-size: 1.15em; }
    .md-dialog-container md-dialog.changeUserStatusModal .dialog-content p {
      margin: 0; }
    .md-dialog-container md-dialog.changeUserStatusModal .dialog-content .an-half:first-child,
    .md-dialog-container md-dialog.changeUserStatusModal .dialog-content .an-half-forced {
      margin-bottom: 34px; }
    .md-dialog-container md-dialog.changeUserStatusModal .dialog-content .help {
      font-size: 0.8em;
      font-style: italic; }
    .md-dialog-container md-dialog.changeUserStatusModal .dialog-content .md-button {
      width: 100%;
      margin: 6px 0; }
  .md-dialog-container md-dialog.changeUserStatusModal .dialog-actions {
    font-size: 1.15em;
    justify-content: space-between; }

.modal-crew-add-event .inline-form-group {
  margin-bottom: 10px; }
  .modal-crew-add-event .inline-form-group label {
    white-space: nowrap; }
  .modal-crew-add-event .inline-form-group .input-group {
    margin-left: 5px; }
    .modal-crew-add-event .inline-form-group .input-group.full {
      flex: 1; }

.modal-crew-add-event .search-user {
  position: relative; }
  .modal-crew-add-event .search-user .auto-complete-loader {
    height: 100%;
    display: flex;
    align-items: center; }

.modal-crew-add-event .separator {
  border-bottom: 1px solid #c9cbcb;
  width: 100%;
  margin-bottom: 10px; }

.modal-crew-add-event .candidates-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.modal-crew-add-event .occ-table .iscroll-wrapper > * > .hol-list > li {
  font-weight: normal; }

.modal-crew-add-event .users-list .md-icon-button {
  margin: -10px; }

.modal-crew-add-event .users-list .user-line {
  min-height: 25px; }
  .modal-crew-add-event .users-list .user-line .ACCEPTED {
    color: green; }
  .modal-crew-add-event .users-list .user-line .REFUSED {
    color: red; }
  .modal-crew-add-event .users-list .user-line .WAITING {
    color: orange; }

.modal-crew-add-event .users-list .col-at {
  margin-right: 8px; }

.modal-crew-add-event .users-list .created-by {
  margin-left: 5px; }

.modal-crew-add-event .users-list .status-indicator {
  margin-left: 8px; }
  .modal-crew-add-event .users-list .status-indicator .icon {
    height: 25px;
    width: 25px;
    display: block;
    line-height: 25px;
    text-align: center; }

.modal-crew-add-event .users-list .col-info {
  padding: 5px 10px 3px 10px;
  margin: -2px 2px 0 2px;
  background: #f1f5f5;
  line-height: 1em; }

.modal-crew-add-event .users-list .archive-btn {
  position: absolute;
  bottom: 10px;
  right: 10px; }

.modal-crew-add-event .users-list .tiredness-indicator {
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0 10px 0 0; }

.modal-crew-add-event .empty-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f5f5;
  min-height: 200px;
  padding: 15%;
  text-align: center;
  color: #787878;
  font-weight: bold; }
  .modal-crew-add-event .empty-block .block-hint {
    margin: 20px 0; }

.modal-crew-add-event md-dialog-actions {
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .modal-crew-add-event md-dialog-actions button.archive-btn.md-raised.md-button.hol-invert {
    color: grey !important;
    border: 2px solid grey !important;
    width: 120px; }
    .modal-crew-add-event md-dialog-actions button.archive-btn.md-raised.md-button.hol-invert.responsive {
      min-width: 50px;
      width: 50px; }
  .modal-crew-add-event md-dialog-actions .dialog-actions {
    width: auto; }

.md-dialog-container md-dialog.modal-crew-add-event md-dialog-content .checkbox {
  margin: 0; }
  .md-dialog-container md-dialog.modal-crew-add-event md-dialog-content .checkbox md-checkbox {
    margin: 0 0 5px 0; }

/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
app-ocl-decisions-list-item > div,
app-ocl-logbook-list-item > div,
app-ops-decisions-list-item > div,
app-ops-logbook-list-item > div,
app-goc-decisions-list-item > div,
app-goc-logbook-list-item > div {
  position: relative;
  display: block; }
  app-ocl-decisions-list-item > div .occ-linked-button,
  app-ocl-logbook-list-item > div .occ-linked-button,
  app-ops-decisions-list-item > div .occ-linked-button,
  app-ops-logbook-list-item > div .occ-linked-button,
  app-goc-decisions-list-item > div .occ-linked-button,
  app-goc-logbook-list-item > div .occ-linked-button {
    top: 2px;
    right: 2px; }

app-ocl-event-item > div,
app-ops-event-item > div,
app-goc-event-item > div {
  position: relative;
  display: block;
  padding-right: 20px; }
  app-ocl-event-item > div .occ-linked-button,
  app-ops-event-item > div .occ-linked-button,
  app-goc-event-item > div .occ-linked-button {
    top: 5px;
    right: 5px; }

.occ-linked-button {
  background: white;
  border: solid 1px #80808096;
  position: absolute;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .occ-linked-button.selected {
    border: solid 1px #3f6185;
    background: #3f6185; }
  .occ-linked-button:disabled {
    border: solid 1px #80808096;
    background: #80808096; }
  .occ-linked-button .occ-linked-icon {
    width: 13px !important;
    height: 13px !important;
    transform: rotate(-90deg);
    transform-origin: center center; }

.pinned-item-button {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #eaeaea; }
  .pinned-item-button .mat-checkbox-inner-container {
    display: none !important; }
  .pinned-item-button .pinned-item-icon {
    width: 27px;
    margin-left: 3px;
    margin-top: 5px; }

.pinned-item-btn {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #848484;
  font-size: 18px; }
  .pinned-item-btn .mat-checkbox-inner-container {
    display: none !important; }
  .pinned-item-btn label {
    margin-bottom: 0; }
  .pinned-item-btn.isPinned {
    color: #1a237e;
    background-color: #e8eaf6; }
  .pinned-item-btn .pinned-item-icon {
    width: 27px;
    margin-left: 3px;
    margin-top: 5px; }

#page-occ-dashboard .status-decision-container,
#page-flt-dashboard .status-decision-container {
  display: block;
  height: calc(50% - 10px / 2);
  margin-bottom: 10px; }

#page-occ-dashboard .operation-logbook-container,
#page-flt-dashboard .operation-logbook-container {
  display: block;
  height: calc(50% - 10px / 2); }

#page-occ-dashboard .page-content,
#page-ecl-dashboard .page-content,
#page-flt-dashboard .page-content {
  display: flex;
  flex-direction: column;
  flex: 1; }
  #page-occ-dashboard .page-content .occ-header .upload-flight,
  #page-ecl-dashboard .page-content .occ-header .upload-flight,
  #page-flt-dashboard .page-content .occ-header .upload-flight {
    color: #3f6185;
    border: solid 1px #3f6185;
    margin: 0 5px; }
    #page-occ-dashboard .page-content .occ-header .upload-flight span,
    #page-ecl-dashboard .page-content .occ-header .upload-flight span,
    #page-flt-dashboard .page-content .occ-header .upload-flight span {
      margin-left: 5px;
      text-transform: uppercase; }
  #page-occ-dashboard .page-content .occ-header .displayAllText,
  #page-ecl-dashboard .page-content .occ-header .displayAllText,
  #page-flt-dashboard .page-content .occ-header .displayAllText {
    margin-left: 5px;
    display: flex;
    align-items: center;
    height: 100%;
    width: 150px;
    align-content: center; }
    #page-occ-dashboard .page-content .occ-header .displayAllText label,
    #page-ecl-dashboard .page-content .occ-header .displayAllText label,
    #page-flt-dashboard .page-content .occ-header .displayAllText label {
      display: flex;
      align-items: center;
      height: 10px; }
      #page-occ-dashboard .page-content .occ-header .displayAllText label strong,
      #page-ecl-dashboard .page-content .occ-header .displayAllText label strong,
      #page-flt-dashboard .page-content .occ-header .displayAllText label strong {
        margin-right: 5px; }
      #page-occ-dashboard .page-content .occ-header .displayAllText label md-checkbox.md-checked .md-icon,
      #page-ecl-dashboard .page-content .occ-header .displayAllText label md-checkbox.md-checked .md-icon,
      #page-flt-dashboard .page-content .occ-header .displayAllText label md-checkbox.md-checked .md-icon {
        background-color: #3f6185 !important; }
      #page-occ-dashboard .page-content .occ-header .displayAllText label md-checkbox.md-checked .md-ink-ripple,
      #page-ecl-dashboard .page-content .occ-header .displayAllText label md-checkbox.md-checked .md-ink-ripple,
      #page-flt-dashboard .page-content .occ-header .displayAllText label md-checkbox.md-checked .md-ink-ripple {
        color: #3f6185 !important; }
      #page-occ-dashboard .page-content .occ-header .displayAllText label .md-ripple-container,
      #page-ecl-dashboard .page-content .occ-header .displayAllText label .md-ripple-container,
      #page-flt-dashboard .page-content .occ-header .displayAllText label .md-ripple-container {
        display: none !important; }
      #page-occ-dashboard .page-content .occ-header .displayAllText label .md-container:before,
      #page-ecl-dashboard .page-content .occ-header .displayAllText label .md-container:before,
      #page-flt-dashboard .page-content .occ-header .displayAllText label .md-container:before {
        display: none !important; }
  #page-occ-dashboard .page-content .content-addon,
  #page-ecl-dashboard .page-content .content-addon,
  #page-flt-dashboard .page-content .content-addon {
    height: 100%; }
    #page-occ-dashboard .page-content .content-addon .hol-row,
    #page-ecl-dashboard .page-content .content-addon .hol-row,
    #page-flt-dashboard .page-content .content-addon .hol-row {
      height: 100%; }

#page-occ-dashboard .hol-search-container .icon,
#page-ecl-dashboard .hol-search-container .icon,
#page-flt-dashboard .hol-search-container .icon {
  height: 12px; }

#page-occ-dashboard .item-text-content,
#page-ecl-dashboard .item-text-content,
#page-flt-dashboard .item-text-content {
  flex: 1;
  margin-top: 2px; }

#page-occ-dashboard .on-duty-managers-title,
#page-ecl-dashboard .on-duty-managers-title,
#page-flt-dashboard .on-duty-managers-title {
  font-weight: bold; }

#page-occ-dashboard .box-container,
#page-ecl-dashboard .box-container,
#page-flt-dashboard .box-container {
  height: 100%;
  padding: 0; }
  #page-occ-dashboard .box-container:not(:last-child),
  #page-ecl-dashboard .box-container:not(:last-child),
  #page-flt-dashboard .box-container:not(:last-child) {
    padding-right: 10px; }

.occ-table {
  font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif;
  min-height: 50px;
  width: 100%; }
  .occ-table .col-at {
    line-height: 1em;
    margin-right: 5px;
    color: #787878;
    text-align: center; }
    .occ-table .col-at > * {
      white-space: pre; }
  .occ-table .col-info {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .occ-table .col-next-info {
    line-height: 1em;
    margin-left: 5px;
    text-align: center; }
    .occ-table .col-next-info.almost-elapsed {
      font-weight: bold;
      color: #f4902f; }
    .occ-table .col-next-info.elapsed {
      font-weight: bold;
      color: #ff0036; }
    .occ-table .col-next-info > * {
      white-space: pre; }
      .occ-table .col-next-info > *:before {
        content: '►'; }
  .occ-table .occ-table-header {
    padding: 0 12px; }
  .occ-table .col-done {
    color: #ababab;
    transition: color 0.2s; }
    .occ-table .col-done button {
      padding: 0;
      height: 40px;
      line-height: 0;
      min-height: 0;
      margin: -20px 0; }
    .occ-table .col-done.hol-done button i {
      font-weight: bold; }
  .occ-table .iscroll-wrapper > * > .hol-list > li:first-child .col-info {
    white-space: normal;
    word-break: normal; }
  .occ-table .col-attachements {
    text-align: right; }
    .occ-table .col-attachements.next-info {
      display: flex;
      align-items: center; }
  .occ-table .col-linked-event {
    color: #3f6185;
    background: #f1f5f5;
    padding: 5px 10px 3px 10px;
    margin-top: -5px;
    margin-right: 5px;
    line-height: 1em; }

.modal-dashboard-event-add .disabled-form {
  opacity: 0.1; }

.modal-dashboard-event-add .scenario-container {
  align-items: flex-end; }
  .modal-dashboard-event-add .scenario-container .md-button {
    margin-bottom: 0; }

.modal-dashboard-event-edit .dialog-content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  position: relative; }
  .modal-dashboard-event-edit .dialog-content .iscroll-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: auto; }
  .modal-dashboard-event-edit .dialog-content .occ-table {
    position: relative; }
  .modal-dashboard-event-edit .dialog-content .description {
    font-weight: bold;
    font-size: 1.5em; }

.modal-dashboard-event-edit .row {
  display: flex;
  width: 100;
  justify-content: center; }
  .modal-dashboard-event-edit .row .archive {
    font-weight: bold;
    display: block;
    margin: 2em auto 1em;
    padding: 3em;
    background-color: #f1f5f5; }

.occ-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px; }
  .occ-header tag-selector {
    width: calc(100% - 400px); }
  .occ-header app-tag-selector {
    width: calc(100% - 400px); }
  .occ-header .md-button {
    width: 100px;
    margin: 0; }
  .occ-header md-button {
    margin-top: 0;
    height: 36px; }

.modal-dashboard-occ-status-decisions .linked-flight,
.modal-dashboard-occ-logbook .linked-flight {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }
  .modal-dashboard-occ-status-decisions .linked-flight .top,
  .modal-dashboard-occ-logbook .linked-flight .top {
    display: flex; }
  .modal-dashboard-occ-status-decisions .linked-flight .bottom,
  .modal-dashboard-occ-logbook .linked-flight .bottom {
    display: flex;
    align-items: center;
    align-content: center; }
    .modal-dashboard-occ-status-decisions .linked-flight .bottom .date,
    .modal-dashboard-occ-logbook .linked-flight .bottom .date {
      width: 200px;
      display: flex;
      margin-right: 50px; }
      .modal-dashboard-occ-status-decisions .linked-flight .bottom .date .input-group-addon,
      .modal-dashboard-occ-logbook .linked-flight .bottom .date .input-group-addon {
        height: 34px;
        width: 44px; }
    .modal-dashboard-occ-status-decisions .linked-flight .bottom .flight,
    .modal-dashboard-occ-logbook .linked-flight .bottom .flight {
      display: flex;
      width: 300px; }
      .modal-dashboard-occ-status-decisions .linked-flight .bottom .flight .input-group-addon,
      .modal-dashboard-occ-logbook .linked-flight .bottom .flight .input-group-addon {
        width: 44px;
        display: flex;
        align-items: center; }
      .modal-dashboard-occ-status-decisions .linked-flight .bottom .flight md-autocomplete[disabled],
      .modal-dashboard-occ-logbook .linked-flight .bottom .flight md-autocomplete[disabled] {
        background: whitesmoke; }
      .modal-dashboard-occ-status-decisions .linked-flight .bottom .flight md-autocomplete,
      .modal-dashboard-occ-logbook .linked-flight .bottom .flight md-autocomplete {
        width: 250px;
        background: white; }
        .modal-dashboard-occ-status-decisions .linked-flight .bottom .flight md-autocomplete md-autocomplete-wrap,
        .modal-dashboard-occ-logbook .linked-flight .bottom .flight md-autocomplete md-autocomplete-wrap {
          box-shadow: none; }

.item-dasboard-occ-flight-linked {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px; }
  .item-dasboard-occ-flight-linked span {
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px;
    height: 20px;
    line-height: 12px;
    border: 1px solid #3f6185;
    color: #3f6185;
    font-weight: bold;
    font-size: 12px; }

.linked-section {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .linked-section .title-read-only {
    margin-bottom: 5px;
    font-weight: bold;
    display: flex; }
  .linked-section__top {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between; }
    .linked-section__top .left {
      width: 90%; }
      .linked-section__top .left mat-form-field {
        width: 100%; }
    .linked-section__top .right {
      width: 30px; }
  .linked-section__bottom {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .linked-section__bottom__row {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      border: solid 1px #c9cbcb;
      padding: 0 5px;
      margin-bottom: 5px;
      min-height: 35px; }
      .linked-section__bottom__row .left {
        width: 90%;
        color: #3f6185; }
      .linked-section__bottom__row .right {
        width: 30px; }
        .linked-section__bottom__row .right mat-icon {
          font-size: 18px; }

.item-dashboard-applicability {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px; }
  .item-dashboard-applicability app-flt-applicability-summary > div {
    text-transform: uppercase;
    display: table;
    padding: 5px;
    height: 20px;
    line-height: 12px;
    border: 1px solid #3f6185;
    background-color: #fff;
    color: #3f6185;
    font-weight: bold;
    font-size: 12px; }
  .item-dashboard-applicability app-flt-applicability-summary.selected > div {
    background-color: #3f6185;
    color: #fff; }

#page-occ-history .history-box .hol-box__header .form-group {
  display: inline-block;
  width: 190px;
  vertical-align: middle;
  margin-bottom: 0; }

#page-occ-history .history-box .occ-table .iscroll-wrapper {
  top: 30px !important; }

#page-occ-history .history-box .log-nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

#page-occ-history .history-box .log-muted {
  font-size: 0.9em;
  color: #787878; }

#page-occ-history .history-box .histories {
  margin-top: 10px;
  font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }
  #page-occ-history .history-box .histories > li {
    padding: 0;
    font-weight: initial;
    border-top: 2px solid #afb2b2; }
    #page-occ-history .history-box .histories > li.new-day-line {
      border-top-width: 20px; }
      #page-occ-history .history-box .histories > li.new-day-line .time {
        margin-top: -20px; }

#page-occ-history .history-box .time-slot {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between; }
  #page-occ-history .history-box .time-slot .time {
    min-width: 85px;
    margin-top: -10px;
    background: white; }
  #page-occ-history .history-box .time-slot .logs {
    flex: 1;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 80px); }
    #page-occ-history .history-box .time-slot .logs .new-supervisor {
      width: 100%;
      background: #3f84cf;
      margin-top: 0;
      padding-top: 3px;
      color: white;
      font-weight: bold; }
      #page-occ-history .history-box .time-slot .logs .new-supervisor.empty-supervisor {
        background-color: #ff0036; }
      #page-occ-history .history-box .time-slot .logs .new-supervisor i.fa {
        height: 16px;
        font-size: 0.9em; }
    #page-occ-history .history-box .time-slot .logs .new-day {
      width: 100%;
      padding-top: 3px;
      color: white;
      font-weight: bold;
      margin-top: -22px; }
      #page-occ-history .history-box .time-slot .logs .new-day i.fa {
        height: 16px;
        font-size: 0.9em; }
    #page-occ-history .history-box .time-slot .logs .col-decision,
    #page-occ-history .history-box .time-slot .logs .col-event,
    #page-occ-history .history-box .time-slot .logs .col-logbook {
      width: calc((100% - 10px) / 3);
      display: flex;
      justify-content: stretch;
      align-items: center;
      border-left: 1px solid #c9cbcb; }
      #page-occ-history .history-box .time-slot .logs .col-decision .empty-log,
      #page-occ-history .history-box .time-slot .logs .col-event .empty-log,
      #page-occ-history .history-box .time-slot .logs .col-logbook .empty-log {
        margin: 10px 0;
        flex: 1;
        text-align: center; }
      #page-occ-history .history-box .time-slot .logs .col-decision .hol-list,
      #page-occ-history .history-box .time-slot .logs .col-event .hol-list,
      #page-occ-history .history-box .time-slot .logs .col-logbook .hol-list {
        flex: 1;
        height: 100%;
        width: 100%; }
        #page-occ-history .history-box .time-slot .logs .col-decision .hol-list li,
        #page-occ-history .history-box .time-slot .logs .col-event .hol-list li,
        #page-occ-history .history-box .time-slot .logs .col-logbook .hol-list li {
          padding: 10px;
          display: flex;
          justify-content: stretch;
          align-items: flex-start; }
          #page-occ-history .history-box .time-slot .logs .col-decision .hol-list li .time,
          #page-occ-history .history-box .time-slot .logs .col-event .hol-list li .time,
          #page-occ-history .history-box .time-slot .logs .col-logbook .hol-list li .time {
            margin-top: 0; }
            #page-occ-history .history-box .time-slot .logs .col-decision .hol-list li .time .status .full,
            #page-occ-history .history-box .time-slot .logs .col-event .hol-list li .time .status .full,
            #page-occ-history .history-box .time-slot .logs .col-logbook .hol-list li .time .status .full {
              border-radius: 3px;
              display: block;
              text-align: center;
              padding-top: 2px; }
            #page-occ-history .history-box .time-slot .logs .col-decision .hol-list li .time .attachments,
            #page-occ-history .history-box .time-slot .logs .col-event .hol-list li .time .attachments,
            #page-occ-history .history-box .time-slot .logs .col-logbook .hol-list li .time .attachments {
              margin-top: 5px; }
          #page-occ-history .history-box .time-slot .logs .col-decision .hol-list li .comment,
          #page-occ-history .history-box .time-slot .logs .col-event .hol-list li .comment,
          #page-occ-history .history-box .time-slot .logs .col-logbook .hol-list li .comment {
            margin-left: 10px;
            max-width: calc(100% - 45px);
            overflow: hidden;
            text-overflow: ellipsis; }
            #page-occ-history .history-box .time-slot .logs .col-decision .hol-list li .comment .event-title,
            #page-occ-history .history-box .time-slot .logs .col-event .hol-list li .comment .event-title,
            #page-occ-history .history-box .time-slot .logs .col-logbook .hol-list li .comment .event-title {
              color: #3f6185; }
        #page-occ-history .history-box .time-slot .logs .col-decision .hol-list li:first-child,
        #page-occ-history .history-box .time-slot .logs .col-event .hol-list li:first-child,
        #page-occ-history .history-box .time-slot .logs .col-logbook .hol-list li:first-child {
          border-top: none; }
  #page-occ-history .history-box .time-slot.header .time {
    margin-top: 0;
    text-align: center; }
  #page-occ-history .history-box .time-slot.header .col-decision,
  #page-occ-history .history-box .time-slot.header .col-event,
  #page-occ-history .history-box .time-slot.header .col-logbook {
    border-left: none;
    justify-content: center; }

#page-occ-history .history-box .long-status {
  padding: 5px 5px 0px 5px; }

#page-tasks .event-filter,
#page-tasks .function-filter {
  display: inline-block; }

#page-tasks .task-content {
  height: 100%; }
  #page-tasks .task-content .hol-box__content {
    height: 100%; }

#page-crew-dashboard app-ocl-global-instruction-box {
  height: 100%; }

#page-crew-dashboard .acl-filter {
  display: flex;
  align-items: center; }

#page-crew-dashboard .col-container {
  display: flex;
  flex-direction: column; }
  #page-crew-dashboard .col-container .custom-tabs .tab-content {
    padding: 0 !important; }
  #page-crew-dashboard .col-container .connected-users-container {
    display: block;
    flex: 1;
    margin-top: 10px;
    max-height: 170px; }
  #page-crew-dashboard .col-container .logs-container {
    display: block;
    flex: 1;
    margin-top: 10px; }
  #page-crew-dashboard .col-container .todos-container {
    display: block;
    flex: 1; }
    #page-crew-dashboard .col-container .todos-container.responsive {
      margin-right: 0;
      margin-left: 0; }
  #page-crew-dashboard .col-container .indicators-container {
    background-color: #cecece; }
    #page-crew-dashboard .col-container .indicators-container .hol-box__content {
      padding: 0; }

.md-dialog-container md-dialog.modal-crew-choose-function {
  min-width: 50%;
  max-width: 50%;
  width: 50%; }

#page-crew-team .col-container {
  display: flex;
  flex-direction: column; }
  #page-crew-team .col-container .teams-container {
    flex: 1;
    position: relative;
    overflow: auto; }
    #page-crew-team .col-container .teams-container .hol-box:not(:last-child) {
      margin-right: 10px; }
    #page-crew-team .col-container .teams-container .team-col {
      min-width: 280px; }
    #page-crew-team .col-container .teams-container .user-item {
      margin: 2px 2px 5px 2px; }
      #page-crew-team .col-container .teams-container .user-item .status-indicator {
        margin-right: 5px;
        margin-left: 5px; }
        #page-crew-team .col-container .teams-container .user-item .status-indicator .icon {
          height: 25px;
          width: 25px;
          display: block;
          line-height: 25px;
          text-align: center; }
      #page-crew-team .col-container .teams-container .user-item .panel-heading {
        padding: 5px; }
        #page-crew-team .col-container .teams-container .user-item .panel-heading .panel-title {
          font-size: 14px;
          font-weight: normal; }
          #page-crew-team .col-container .teams-container .user-item .panel-heading .panel-title .chevron {
            font-size: xx-small;
            width: 12px;
            font-weight: bold;
            display: inline-block;
            text-align: center; }
            #page-crew-team .col-container .teams-container .user-item .panel-heading .panel-title .chevron.icon-arrow_bottom {
              font-size: 6px; }
      #page-crew-team .col-container .teams-container .user-item .panel-body {
        padding: 10px; }
        #page-crew-team .col-container .teams-container .user-item .panel-body .events-container {
          display: flex;
          flex-direction: row;
          margin: -5px;
          flex-wrap: wrap; }
          #page-crew-team .col-container .teams-container .user-item .panel-body .events-container .user-event {
            margin: 5px;
            display: inline-block;
            text-align: center;
            padding: 0 5px; }
            #page-crew-team .col-container .teams-container .user-item .panel-body .events-container .user-event .status-indicator {
              margin-left: 0; }
            #page-crew-team .col-container .teams-container .user-item .panel-body .events-container .user-event .impact {
              color: #2d2d2d; }

#page-crew-checklist .col-container {
  display: flex;
  flex-direction: column; }

#page-crew-checklist .events-container {
  height: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  display: flex;
  position: relative; }
  #page-crew-checklist .events-container .hol-warning {
    flex: 1; }
  #page-crew-checklist .events-container .hol-box {
    width: 30%;
    flex-shrink: 0;
    margin-right: 10px; }
    #page-crew-checklist .events-container .hol-box .hol-box__header {
      font-size: 14px;
      position: relative; }
      #page-crew-checklist .events-container .hol-box .hol-box__header .event-item {
        width: 100%; }
      #page-crew-checklist .events-container .hol-box .hol-box__header .crew-event-item {
        width: calc(100% + 10px);
        overflow: hidden;
        white-space: normal;
        margin: -5px -5px -5px;
        padding-bottom: 15px; }
      #page-crew-checklist .events-container .hol-box .hol-box__header .floating-btn {
        position: absolute;
        bottom: -25px;
        margin-left: 10px; }
    #page-crew-checklist .events-container .hol-box .hol-box__content {
      margin-top: 25px; }
      #page-crew-checklist .events-container .hol-box .hol-box__content .occ-table .iscroll-wrapper > * > .hol-list > li {
        font-weight: normal; }
      #page-crew-checklist .events-container .hol-box .hol-box__content .user-line {
        min-height: 25px; }
      #page-crew-checklist .events-container .hol-box .hol-box__content .col-at {
        margin-right: 8px; }
      #page-crew-checklist .events-container .hol-box .hol-box__content .created-by {
        margin-left: 5px; }
      #page-crew-checklist .events-container .hol-box .hol-box__content .status-indicator {
        margin-left: 8px; }
        #page-crew-checklist .events-container .hol-box .hol-box__content .status-indicator .icon {
          height: 25px;
          width: 25px;
          display: block;
          line-height: 25px;
          text-align: center; }
      #page-crew-checklist .events-container .hol-box .hol-box__content .col-info {
        padding: 5px 10px 3px 10px;
        margin: -2px 2px 0 2px;
        background: #f1f5f5;
        line-height: 1em; }
      #page-crew-checklist .events-container .hol-box .hol-box__content .archive-btn {
        position: absolute;
        bottom: 10px;
        right: 10px; }

#page-crew-history .hol-box__header .hol-radio-btn-group {
  margin-bottom: 0; }

#page-crew-history .hol-box__header .form-group {
  margin-bottom: 0; }
  #page-crew-history .hol-box__header .form-group.date {
    width: 190px; }
    #page-crew-history .hol-box__header .form-group.date .uib-datepicker-popup {
      right: 0;
      left: auto !important; }

#page-crew-history .hol-box__content .col-todo {
  display: flex;
  justify-content: flex-start;
  align-items: baseline; }

#page-crew-history .hol-box__content .col-result .situation {
  display: flex;
  justify-content: flex-start;
  align-items: baseline; }

#page-crew-history .hol-box__content .col-result .event-container {
  max-width: 300px; }

#page-crew-history .hol-box__content .col-result .status-text {
  padding-left: 10px; }

#page-crew-history .hol-box__content .col-result .status-info {
  padding-top: 10px; }

#page-crew-history .hol-box__content .col-todo .situation,
#page-crew-history .hol-box__content .col-result .situation {
  display: flex;
  justify-content: flex-start;
  align-items: baseline; }

#page-crew-history .hol-box__content .col-todo .icon,
#page-crew-history .hol-box__content .col-result .icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }
  #page-crew-history .hol-box__content .col-todo .icon.glyphicon-time,
  #page-crew-history .hol-box__content .col-result .icon.glyphicon-time {
    padding: 2px 2px 2px 3px; }
  #page-crew-history .hol-box__content .col-todo .icon.hol-primary,
  #page-crew-history .hol-box__content .col-result .icon.hol-primary {
    background-color: #f1f5f5; }

#page-crew-history .hol-box__content .col-todo .crew-event-item .icon,
#page-crew-history .hol-box__content .col-result .crew-event-item .icon {
  display: inline; }

#page-crew-history .hol-box__content .col-todo .comment,
#page-crew-history .hol-box__content .col-result .comment {
  margin-left: 10px; }

.hol-history-table {
  font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }
  .hol-history-table .header {
    display: flex; }
    .hol-history-table .header .time {
      min-width: 45px;
      text-align: center; }
    .hol-history-table .header > *:not(.time) {
      flex: 1;
      text-align: center;
      display: block; }
  .hol-history-table .iscroll-wrapper {
    top: 30px !important; }
    .hol-history-table .iscroll-wrapper ul.time-slot-list {
      margin-top: 10px;
      list-style: none;
      padding: 0; }
      .hol-history-table .iscroll-wrapper ul.time-slot-list > li {
        border-top: 2px solid #afb2b2;
        position: relative; }
        .hol-history-table .iscroll-wrapper ul.time-slot-list > li.new-day-line {
          border-top-width: 20px;
          color: white; }
          .hol-history-table .iscroll-wrapper ul.time-slot-list > li.new-day-line .time {
            margin-top: -20px !important;
            color: #2d2d2d; }
        .hol-history-table .iscroll-wrapper ul.time-slot-list > li .new-day {
          width: 100%;
          padding-top: 3px;
          font-weight: bold;
          margin-top: -22px;
          position: absolute; }
          .hol-history-table .iscroll-wrapper ul.time-slot-list > li .new-day i.fa {
            height: 16px;
            font-size: 0.9em; }
        .hol-history-table .iscroll-wrapper ul.time-slot-list > li .time-slot {
          display: flex;
          align-items: flex-start; }
          .hol-history-table .iscroll-wrapper ul.time-slot-list > li .time-slot .time {
            min-width: 35px;
            margin-top: -10px;
            background: white; }
          .hol-history-table .iscroll-wrapper ul.time-slot-list > li .time-slot .col-type {
            width: 65px;
            align-self: center;
            margin-left: 10px;
            text-align: center;
            font-weight: bold; }
          .hol-history-table .iscroll-wrapper ul.time-slot-list > li .time-slot ul.time-slot-inner-list {
            list-style: none;
            padding: 0;
            width: 100%;
            margin-left: 10px;
            color: initial; }
            .hol-history-table .iscroll-wrapper ul.time-slot-list > li .time-slot ul.time-slot-inner-list li {
              display: flex; }
              .hol-history-table .iscroll-wrapper ul.time-slot-list > li .time-slot ul.time-slot-inner-list li:not(:first-child) {
                border-top: 1px solid #c9cbcb; }
              .hol-history-table .iscroll-wrapper ul.time-slot-list > li .time-slot ul.time-slot-inner-list li .col-direction {
                margin: 0 5px; }
              .hol-history-table .iscroll-wrapper ul.time-slot-list > li .time-slot ul.time-slot-inner-list li > * {
                flex: 1;
                border-left: 1px solid #c9cbcb;
                padding: 10px; }

.modal-crew-search-event {
  min-height: 50%;
  max-height: 80% !important; }
  .modal-crew-search-event .flight-number-search {
    width: 180px; }
  .modal-crew-search-event .results-container {
    position: relative; }
  .modal-crew-search-event .inline-form-group {
    margin-bottom: 10px; }
    .modal-crew-search-event .inline-form-group label {
      white-space: nowrap; }
    .modal-crew-search-event .inline-form-group .input-group {
      margin-left: 5px; }
      .modal-crew-search-event .inline-form-group .input-group.full {
        flex: 1; }
        .modal-crew-search-event .inline-form-group .input-group.full .date {
          width: 110px;
          margin-right: 10px; }
  .modal-crew-search-event .form-group {
    position: relative; }
  .modal-crew-search-event ul.hol-list li {
    border-top: none;
    padding: 0; }
    .modal-crew-search-event ul.hol-list li.ng-enter-stagger, .modal-crew-search-event ul.hol-list li.ng-leave-stagger {
      transition-delay: 0.02s; }
    .modal-crew-search-event ul.hol-list li:not(:last-child) {
      margin-bottom: 10px; }

.modal-crew-user {
  min-height: 80%;
  max-height: 80% !important; }
  .modal-crew-user .user-modal-search {
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    position: absolute;
    width: 100%; }
    .modal-crew-user .user-modal-search.ng-enter {
      transform: translateY(-100%); }
      .modal-crew-user .user-modal-search.ng-enter.ng-enter-active {
        transform: translateY(0); }
    .modal-crew-user .user-modal-search.ng-leave {
      transform: translateY(0); }
      .modal-crew-user .user-modal-search.ng-leave.ng-leave-active {
        transform: translateY(-100%); }
    .modal-crew-user .user-modal-search .search-user {
      position: relative; }
      .modal-crew-user .user-modal-search .search-user .auto-complete-loader {
        height: 100%;
        display: flex;
        align-items: center; }
  .modal-crew-user .user-modal-details {
    display: flex;
    flex-direction: row;
    flex: 1; }
    .modal-crew-user .user-modal-details.ng-enter, .modal-crew-user .user-modal-details.ng-enter-prepare {
      transition-duration: 0.3s; }
      .modal-crew-user .user-modal-details.ng-enter .user-panel, .modal-crew-user .user-modal-details.ng-enter-prepare .user-panel {
        transition: transform 0.3s ease;
        transform: translateX(-100%); }
      .modal-crew-user .user-modal-details.ng-enter .situation-panel, .modal-crew-user .user-modal-details.ng-enter-prepare .situation-panel {
        transition: transform 0.3s ease;
        transform: translateX(100%); }
      .modal-crew-user .user-modal-details.ng-enter.ng-enter-active .user-panel,
      .modal-crew-user .user-modal-details.ng-enter.ng-enter-active .situation-panel, .modal-crew-user .user-modal-details.ng-enter-prepare.ng-enter-active .user-panel,
      .modal-crew-user .user-modal-details.ng-enter-prepare.ng-enter-active .situation-panel {
        transform: translateX(0); }
    .modal-crew-user .user-modal-details.ng-leave {
      transition-duration: 0.3s; }
      .modal-crew-user .user-modal-details.ng-leave .user-panel,
      .modal-crew-user .user-modal-details.ng-leave .situation-panel {
        transition: transform 0.3s ease;
        transform: translateX(0);
        position: absolute; }
      .modal-crew-user .user-modal-details.ng-leave.ng-leave-active .user-panel {
        left: 0;
        transform: translateX(-100%); }
      .modal-crew-user .user-modal-details.ng-leave.ng-leave-active .situation-panel {
        right: 0;
        transform: translateX(100%); }
    .modal-crew-user .user-modal-details .hol-box {
      height: auto; }
      .modal-crew-user .user-modal-details .hol-box .hol-box__header {
        padding: 0; }
        .modal-crew-user .user-modal-details .hol-box .hol-box__header .md-icon-button {
          background: none; }
          .modal-crew-user .user-modal-details .hol-box .hol-box__header .md-icon-button:hover {
            color: #3f6185; }
      .modal-crew-user .user-modal-details .hol-box .hol-box__content {
        padding: 10px 0; }
        .modal-crew-user .user-modal-details .hol-box .hol-box__content .iscroll-wrapper {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0; }
    .modal-crew-user .user-modal-details .item-selected {
      background: #d6d6d6; }
    .modal-crew-user .user-modal-details .item-unselected {
      background: #eeeeee; }
    .modal-crew-user .user-modal-details .user-panel {
      flex: 4;
      flex-shrink: 1;
      background: #eeeeee;
      display: flex;
      flex-direction: column;
      min-width: 40%;
      max-width: 40%; }
      .modal-crew-user .user-modal-details .user-panel .user-container {
        display: flex;
        align-items: center;
        margin-top: 10px; }
        .modal-crew-user .user-modal-details .user-panel .user-container app-tiredness-indicator {
          vertical-align: middle; }
        .modal-crew-user .user-modal-details .user-panel .user-container .icon {
          margin-right: 10px; }
      .modal-crew-user .user-modal-details .user-panel .md-toolbar-tools {
        font-size: 14px;
        display: block;
        font-weight: normal;
        background: #eeeeee;
        color: #2d2d2d;
        height: auto;
        max-height: none;
        min-height: 120px; }
        .modal-crew-user .user-modal-details .user-panel .md-toolbar-tools .btn-change-user {
          margin-right: -10px;
          font-size: 12px; }
          .modal-crew-user .user-modal-details .user-panel .md-toolbar-tools .btn-change-user .icon {
            padding: 5px;
            background: #787878;
            border-radius: 50%;
            color: white;
            margin-left: 1px; }
        .modal-crew-user .user-modal-details .user-panel .md-toolbar-tools .user-name {
          font-weight: bold;
          font-size: 1.4em; }
          .modal-crew-user .user-modal-details .user-panel .md-toolbar-tools .user-name span {
            vertical-align: middle; }
          .modal-crew-user .user-modal-details .user-panel .md-toolbar-tools .user-name .tiredness-indicator {
            width: 20px;
            height: 20px;
            background: transparent;
            display: inline-block;
            border-radius: 50%; }
      .modal-crew-user .user-modal-details .user-panel li {
        margin: 2px 10px; }
      .modal-crew-user .user-modal-details .user-panel li.disabled {
        opacity: 0.3; }
        .modal-crew-user .user-modal-details .user-panel li.disabled.hol-clickable:hover, .modal-crew-user .user-modal-details .user-panel li.disabled.hol-panel-md:hover {
          opacity: 0.55; }
      .modal-crew-user .user-modal-details .user-panel li.highlight {
        animation-duration: 1s;
        animation-name: highlight;
        animation-iteration-count: infinite; }

@keyframes highlight {
  0% {
    box-shadow: 0 1px 2px 0 rgba(63, 97, 133, 0.5); }
  50% {
    box-shadow: 0 1px 2px 3px rgba(63, 97, 133, 0.5); }
  0% {
    box-shadow: 0 1px 2px 0 rgba(63, 97, 133, 0.5); } }
      .modal-crew-user .user-modal-details .user-panel li.separator {
        border-bottom: 1px solid #787878;
        margin: 15px 2px; }
      .modal-crew-user .user-modal-details .user-panel li.selected.hol-clickable, .modal-crew-user .user-modal-details .user-panel li.selected.hol-panel-md {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
        position: relative; }
        .modal-crew-user .user-modal-details .user-panel li.selected.hol-clickable .situation-item .item-selected, .modal-crew-user .user-modal-details .user-panel li.selected.hol-panel-md .situation-item .item-selected {
          color: blue; }
        .modal-crew-user .user-modal-details .user-panel li.selected.hol-clickable .situation-item input, .modal-crew-user .user-modal-details .user-panel li.selected.hol-panel-md .situation-item input {
          max-width: 120px; }
        .modal-crew-user .user-modal-details .user-panel li.selected.hol-clickable .situation-item .edit-status-text, .modal-crew-user .user-modal-details .user-panel li.selected.hol-panel-md .situation-item .edit-status-text {
          margin: 5px 0px; }
        .modal-crew-user .user-modal-details .user-panel li.selected.hol-clickable .situation-item .action-holder, .modal-crew-user .user-modal-details .user-panel li.selected.hol-panel-md .situation-item .action-holder {
          height: 50px; }
          .modal-crew-user .user-modal-details .user-panel li.selected.hol-clickable .situation-item .action-holder .btn-square, .modal-crew-user .user-modal-details .user-panel li.selected.hol-panel-md .situation-item .action-holder .btn-square {
            min-width: 0px;
            width: 40px; }
        .modal-crew-user .user-modal-details .user-panel li.selected.hol-clickable .situation-item .situation-textarea, .modal-crew-user .user-modal-details .user-panel li.selected.hol-panel-md .situation-item .situation-textarea {
          margin: 5px 0px; }
      .modal-crew-user .user-modal-details .user-panel .collapse-container {
        text-align: right;
        height: 2em; }
        .modal-crew-user .user-modal-details .user-panel .collapse-container .btn-collapse {
          background: transparent; }
          .modal-crew-user .user-modal-details .user-panel .collapse-container .btn-collapse i {
            transform: rotateZ(180deg); }
          .modal-crew-user .user-modal-details .user-panel .collapse-container .btn-collapse.collapsed i {
            transform: rotateZ(0deg); }
    .modal-crew-user .user-modal-details .form-group {
      flex: 6;
      display: flex;
      flex-direction: column;
      min-width: 60%;
      max-width: 100%;
      margin: 0; }
      .modal-crew-user .user-modal-details .form-group .situation-panel {
        flex: 6;
        display: flex;
        flex-direction: column;
        min-width: 60%;
        max-width: 100%;
        padding: 0;
        margin: 0; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .form-group:not(:last-of-type) {
          border-bottom: 1px solid #c9cbcb;
          padding-bottom: 10px; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .direction .hol-radio-btn-group {
          display: flex;
          align-items: center;
          flex-wrap: nowrap; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .direction .hol-radio-btn-group .md-button {
            flex-grow: 1; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .direction .hol-radio-btn-group .consultation {
            flex: auto; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .direction .text-container {
          font-weight: normal;
          color: #2d2d2d;
          line-height: 1.2em; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .direction .text-container small {
            font-size: 11px; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .direction .text-container strong {
            color: #3f6185;
            font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .consultation {
          background-color: #f1f5f5; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .consultation-footer {
          font-size: 1em;
          padding-right: 20px;
          padding-bottom: 20px;
          margin: 0;
          text-align: right;
          background-color: #f1f5f5; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .consultation-status-text {
          color: #aaaaaa;
          text-align: center;
          font-size: 12px; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .consultation-text-area {
          border: 0px;
          min-height: 1em;
          align-content: center; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .consultation-question-container {
          margin: 10px 0;
          display: flex; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .consultation-question-container .question {
            width: 80px;
            padding-top: 6px;
            margin-right: 10px;
            color: #aaaaaa;
            text-transform: uppercase;
            font-size: 12px;
            text-align: right;
            font-weight: bold; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .consultation-question-container .response {
            flex: 1;
            background: white;
            padding: 6px; }
            .modal-crew-user .user-modal-details .form-group .situation-panel .consultation-question-container .response.date {
              text-align: center; }
            .modal-crew-user .user-modal-details .form-group .situation-panel .consultation-question-container .response.big {
              min-height: 72px; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .consultation-question-container .readonly-status-indicator {
            align-self: stretch;
            display: flex;
            align-items: center; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .info textarea:not(:disabled) {
          min-height: 150px;
          padding-bottom: 51px; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .info .help-btn-container {
          margin-top: -51px;
          border-top: 1px solid #c9cbcb;
          display: flex;
          overflow: hidden; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .info .help-btn-container .help-btn {
            text-overflow: ellipsis;
            text-transform: none;
            font-size: 12px;
            font-weight: normal;
            background: white;
            color: #2d2d2d;
            border: 1px solid #c9cbcb;
            min-width: 0; }
            .modal-crew-user .user-modal-details .form-group .situation-panel .info .help-btn-container .help-btn:hover {
              background: #f1f5f5; }
            .modal-crew-user .user-modal-details .form-group .situation-panel .info .help-btn-container .help-btn:not(:last-child) {
              margin-right: 0; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .info.waiting {
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .info.waiting .status-text {
            max-width: calc(100% - 140px);
            display: block;
            flex: 1; }
            .modal-crew-user .user-modal-details .form-group .situation-panel .info.waiting .status-text input:not(:disabled) {
              height: 85px;
              padding-bottom: 51px; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .linked-to-event-container {
          margin-top: 10px; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .linked-to-event-container md-radio-group {
            display: inline-block;
            margin-left: 10px; }
            .modal-crew-user .user-modal-details .form-group .situation-panel .linked-to-event-container md-radio-group md-radio-button {
              display: inline-block;
              margin-top: 0;
              margin-bottom: 10px; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .hol-spaced-line .input-group, .modal-crew-user .user-modal-details .form-group .situation-panel .crew-event-item .event-item__title .input-group, .crew-event-item .modal-crew-user .user-modal-details .form-group .situation-panel .event-item__title .input-group, .modal-crew-user .user-modal-details .form-group .situation-panel .header-component .user-group button.header-button.button-logout .input-group, .header-component .user-group .modal-crew-user .user-modal-details .form-group .situation-panel button.header-button.button-logout .input-group, .modal-crew-user .user-modal-details .form-group .situation-panel .modal-crew-add-event .inline-form-group .input-group, .modal-crew-add-event .modal-crew-user .user-modal-details .form-group .situation-panel .inline-form-group .input-group, .modal-crew-user .user-modal-details .form-group .situation-panel .modal-crew-search-event .inline-form-group .input-group, .modal-crew-search-event .modal-crew-user .user-modal-details .form-group .situation-panel .inline-form-group .input-group {
          flex: 1; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .event-details .event-container {
          margin-bottom: 10px; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .event-details .event-container .select-line {
            align-items: baseline; }
            .modal-crew-user .user-modal-details .form-group .situation-panel .event-details .event-container .select-line select {
              margin-bottom: 5px; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .event-details .input-group:first-child {
          margin-right: 5px; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .event-details .input-group:last-child {
          margin-left: 5px; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .status .status-info > *:not(:first-child) {
          margin-left: 10px; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .status .input-group.date {
          min-width: 130px;
          max-width: 130px; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .status .time {
          min-width: 60px;
          max-width: 60px;
          text-align: center; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .status .hol-radio-btn-group {
          flex-wrap: nowrap; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .status .hol-radio-btn-group .md-button {
            flex-grow: 1; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .actions button {
          margin-right: 0; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .not-authorized-msg {
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          color: #ff0036;
          background: #f1f5f5;
          padding: 10px 20%;
          font-weight: bold; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .readonly-status-indicator {
          padding: 5px 10px;
          font-weight: bold; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .readonly-status-indicator.accepted {
            background: #00c179;
            color: white; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .readonly-status-indicator.refused {
            background: #ff0036;
            color: white; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .readonly-status-indicator.neutral {
            background: #c9cbcb;
            color: #3f6185; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .readonly-status-indicator.waiting {
            background: #f4902f;
            color: white; }
        .modal-crew-user .user-modal-details .form-group .situation-panel .notifications {
          min-width: 200px; }
          .modal-crew-user .user-modal-details .form-group .situation-panel .notifications .notifications-label {
            padding-top: 10px; }

.user-autocomplete-item .fonctions {
  margin-left: 20px; }

.user-autocomplete-item .tiredness-indicator {
  background: transparent;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0; }

.user-autocomplete-item .user-text {
  display: inline-block;
  padding: 0 10px;
  margin: 0; }

.crew-table .iscroll-wrapper > * > .hol-list > li:first-child {
  font-weight: normal; }

.crew-table li.ng-enter-stagger,
.crew-table li.ng-leave-stagger {
  transition-delay: 0.02s; }

.crew-table .created-by {
  margin-left: 5px; }

.crew-table .col-user {
  padding: 5px 10px 3px 10px;
  margin: -2px 10px 0 5px;
  line-height: 1em; }
  .crew-table .col-user:not([class*='filter-color']) {
    background: #f1f5f5; }

.crew-table .situation-event {
  line-height: 1em;
  text-align: center;
  margin-left: 5px; }

.crew-table .date {
  background: none; }

.crew-event-counter {
  padding: 8px 7px 3px 7px;
  font-weight: bold;
  line-height: 2em;
  white-space: nowrap;
  font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }
  .crew-event-counter .icon {
    margin-right: 5px; }

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

@font-face {
  font-family: 'Nunito';
  src: url("/assets/fonts/Nunito-Regular.woff2") format("woff2"), url("/assets/fonts/Nunito-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'AvenirNextLTPro-Cn';
  src: url("/assets/fonts/AvenirNextLTPro-Cn.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Avenir Next Condensed Demi';
  src: url("/assets/fonts/AvenirNextCondensed-DemiBold.woff2") format("woff2"), url("/assets/fonts/AvenirNextCondensed-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Avenir Next Condensed Medium';
  src: url("/assets/fonts/AvenirNextCondensed-Medium.woff2") format("woff2"), url("/assets/fonts/AvenirNextCondensed-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Avenir Next Condensed Bold';
  src: url("/assets/fonts/AvenirNextCondensed-Bold.woff2") format("woff2"), url("/assets/fonts/AvenirNextCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

html {
  background-color: #f1f5f6; }
  html body {
    background-color: #f1f5f6;
    padding: 0;
    font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif;
    overflow: hidden; }

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  margin: 0; }

h2,
h3 {
  font-weight: 200;
  letter-spacing: -0.2px; }

#main {
  position: absolute;
  top: 0;
  left: 80px;
  bottom: 0;
  right: 0;
  background-size: cover;
  color: #2d2d2d;
  -webkit-overflow-scrolling: touch; }
  #main [ui-view] {
    height: 100%; }
    #main [ui-view] .page {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch; }

.col-attachements img[src*='assets/images/note.svg'] {
  width: 25px;
  margin-bottom: 3px; }

.loadingOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(33, 33, 33, 0.6); }

.text-error {
  color: #d9534f; }

.notClickable {
  pointer-events: none !important; }

body.hol-desktop .clickable:hover {
  opacity: 0.7;
  cursor: pointer; }

.contentCentered {
  display: flex;
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-flex-align: center;
  -webkit-box-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  text-align: center; }

.rightContentCentered {
  display: flex;
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-flex-align: center;
  -webkit-box-align: center;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.leftContentCentered {
  display: flex;
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-flex-align: center;
  -webkit-box-align: center;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start; }

.modal button.close {
  font-size: 36px; }

.modal .modal-header:not(.task) {
  color: #fff;
  background-color: #215e83; }

.nopadding {
  padding: 0 !important;
  margin: 0 !important; }

.unfocusable {
  outline: 0 !important; }

/* Remove text selection */
* {
  /* Remove blue border for focus */ }
  *:focus {
    outline: 0 !important; }

/* Reset text selection for input and textarea */
input,
textarea {
  -webkit-touch-callout: default;
  font-family: Arial, sans-serif; }
  input:focus:not(.mat-input-element),
  textarea:focus:not(.mat-input-element) {
    outline: 5px auto -webkit-focus-ring-color !important; }

.unseen-pastille {
  width: 8px;
  height: 8px;
  background-color: #ff0036;
  border-radius: 4px;
  position: absolute;
  top: 5px;
  left: 8px; }

@keyframes pastille_leave_animation {
  from {
    transform: rotateY(0deg); }
  to {
    transform: rotateY(90deg);
    opacity: 0; } }

@keyframes pastille_enter_animation {
  from {
    transform: rotateY(90deg); }
  to {
    transform: rotateY(0deg); } }
  .unseen-pastille.ng-leave {
    animation: 0.5s pastille_leave_animation; }
  .unseen-pastille.ng-enter {
    animation: 0.5s pastille_enter_animation; }

nav .blink-item {
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite; }

@keyframes blink {
  50% {
    background-color: #eeeeee; } }

@keyframes blinkText {
  50% {
    color: #000000; } }
  nav .blink-item a {
    animation-name: blinkText;
    animation-duration: 2s;
    animation-iteration-count: infinite; }

.responsiveIcon .icon {
  display: none; }

.responsiveIcon .text {
  display: initial; }

@media (max-width: 1024px) {
  .responsiveIcon .icon {
    display: initial; }
  .responsiveIcon .text {
    display: none; } }

.title-box-occ,
.title-box-crew,
.title-box-goc,
.title-box-erp,
.title-box-mcc {
  font-family: AvenirNextLTPro-Cn, sans-serif !important;
  font-size: 22px !important; }

.title-card-vacation {
  font-family: AvenirNextLTPro-Cn, sans-serif !important;
  font-size: 18px !important; }

.title-scenario-checklist-event,
.title-checklist-vacation {
  font-family: AvenirNextLTPro-Cn, sans-serif !important;
  font-size: 16px !important; }

.crisis-dashboad-title {
  font-family: AvenirNextLTPro-Cn, sans-serif !important;
  font-size: 20px !important;
  font-weight: bold; }

.flex-direction-column {
  flex-direction: column !important; }

.flex-align-items-start {
  align-items: flex-start; }

.ppv-no-warning {
  background: #aaaaaa; }

.ppv-affected {
  background: #00c179; }

.ppv-in-progress {
  background: #f4902f; }

.ppv-no-affected {
  background: #ff0036; }

.ppv-no-5o {
  background: #aaaaaa;
  font-style: italic; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 1rem !important; }

.ml-1 {
  margin-left: 1rem !important; }

.mt-1 {
  margin-top: 1rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.align-items-center {
  align-items: center; }

.d-flex {
  display: flex; }

.flex-1 {
  flex: 1; }

.justify-content-between {
  justify-content: space-between; }

nav {
  background-color: #2d2d2d;
  text-transform: uppercase;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80px;
  text-align: center;
  z-index: 20;
  font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }
  nav .hol-main-logo {
    width: 100%; }
  nav .crisis-background {
    width: 80px; }
    nav .crisis-background.crisis {
      background-color: #ff6166; }
      nav .crisis-background.crisis.training {
        background-color: #f4902f; }
    nav .crisis-background ul {
      list-style: none;
      padding: 0;
      margin: 10px 0; }
      nav .crisis-background ul li {
        cursor: pointer;
        position: relative;
        height: 70px;
        margin: 5px;
        border-radius: 4px; }
        nav .crisis-background ul li.active {
          background-color: rgba(45, 45, 45, 0.6); }
        nav .crisis-background ul li:hover {
          background-color: rgba(45, 45, 45, 0.2); }
        nav .crisis-background ul li.disabled {
          opacity: 0.3;
          pointer-events: none;
          cursor: no-drop; }
        nav .crisis-background ul li a {
          color: #ffffff;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          nav .crisis-background ul li a:hover, nav .crisis-background ul li a:active, nav .crisis-background ul li a:focus {
            text-decoration: none !important; }
            nav .crisis-background ul li a:hover *, nav .crisis-background ul li a:active *, nav .crisis-background ul li a:focus * {
              text-decoration: none !important; }
          nav .crisis-background ul li a .icon {
            display: block;
            font-size: 20px;
            font-style: normal;
            line-height: 1em; }
            nav .crisis-background ul li a .icon.new-crisis {
              font-size: 40px;
              font-style: normal;
              line-height: 1em; }
          nav .crisis-background ul li a .link {
            height: 14px;
            display: block;
            margin-top: 6px; }

#page-team .team-content {
  font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif; }

#page-team .hol-row .team-box .hol-box__header .team-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

#page-team .hol-row .team-box .hol-box__header .filters {
  z-index: 20;
  text-align: center; }
  #page-team .hol-row .team-box .hol-box__header .filters ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right; }
    #page-team .hol-row .team-box .hol-box__header .filters ul li {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      background-color: #dedede;
      color: #808080;
      font-weight: 600;
      font-size: 12px; }
      #page-team .hol-row .team-box .hol-box__header .filters ul li.active {
        color: white;
        background-color: #395f88; }
      #page-team .hol-row .team-box .hol-box__header .filters ul li.ng-click-active {
        color: white;
        background-color: #4878ac; }

#page-team .hol-row .team-box .hol-box__content .iscroll-wrapper {
  top: 30px; }
  #page-team .hol-row .team-box .hol-box__content .iscroll-wrapper .iscroll-scroller {
    padding: 2px; }
  #page-team .hol-row .team-box .hol-box__content .iscroll-wrapper .tag {
    display: block;
    font-size: 12px; }
  #page-team .hol-row .team-box .hol-box__content .iscroll-wrapper .panel-group {
    margin: 0; }
  #page-team .hol-row .team-box .hol-box__content .iscroll-wrapper .function-panel {
    margin: 2px 0; }

#page-team .hol-row .team-box .hol-box__content .function-panel,
#page-team .hol-row .team-box .hol-box__content .name-panel {
  transition: color 0.15s, background-color 0.15s; }
  #page-team .hol-row .team-box .hol-box__content .function-panel:not(.panel-open) .fullTitle,
  #page-team .hol-row .team-box .hol-box__content .name-panel:not(.panel-open) .fullTitle {
    color: #416794; }
  #page-team .hol-row .team-box .hol-box__content .function-panel.panel-open .team-line-countTODO,
  #page-team .hol-row .team-box .hol-box__content .name-panel.panel-open .team-line-countTODO {
    color: white; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .chevron-block,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .chevron-block {
    width: 12px;
    margin-right: 10px; }
    #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .chevron-block i,
    #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .chevron-block i {
      font-size: xx-small;
      font-weight: bold; }
      #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .chevron-block i.icon-arrow_bottom,
      #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .chevron-block i.icon-arrow_bottom {
        font-size: 6px; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .functionTitle,
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .userTitle,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .functionTitle,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .userTitle {
    font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.84em;
    letter-spacing: -0.7px; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .functionTitle,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .functionTitle {
    font-weight: 500; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .userTitle,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .userTitle {
    font-weight: initial; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .notify-btn,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .notify-btn {
    margin: -15px 0; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .functionShortTitle,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .functionShortTitle {
    transition: all 0.3s;
    margin-left: 10px;
    color: #062530; }
    #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .functionShortTitle.not-low-key,
    #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .functionShortTitle.not-low-key {
      color: #d4f0fa;
      font-weight: bold; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .functions,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .functions {
    max-width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.3em; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .nobody,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .nobody {
    color: #f0636c; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .affected,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .affected {
    color: #3f84cf; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .holder,
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .nobody,
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .affected,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .holder,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .nobody,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .affected {
    font-size: 14px;
    text-align: right; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .text-muted,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .text-muted {
    color: #aaaaaa; }
    #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .text-muted *,
    #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .text-muted * {
      background-color: #dddddd; }
    #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .text-muted .functionTitle .fullTitle,
    #page-team .hol-row .team-box .hol-box__content .function-panel .panel-heading .text-muted .functionTitle .dash,
    #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .text-muted .functionTitle .fullTitle,
    #page-team .hol-row .team-box .hol-box__content .name-panel .panel-heading .text-muted .functionTitle .dash {
      color: #aaaaaa; }
  #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body,
  #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body {
    border: none;
    padding: 0; }
    #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body .team,
    #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body .team {
      list-style: none;
      margin: 0;
      padding: 0; }
      #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body .team li,
      #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body .team li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #2a4057;
        font-size: 14px;
        position: relative;
        background: #d8f0fc;
        transition: background-color 0.2s ease-in;
        justify-content: space-between;
        padding: 10px;
        margin-top: 1px; }
        #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body .team li.ng-click-active, #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body .team li:hover,
        #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body .team li.ng-click-active,
        #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body .team li:hover {
          background-color: white; }
        #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body .team li.options,
        #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body .team li.options {
          justify-content: center;
          background-color: transparent;
          color: #787878;
          font-style: italic; }
    #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body .actions,
    #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body .actions {
      border-top: 1px solid white;
      background-color: #2a4057;
      display: flex;
      display: -moz-box;
      /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;
      /* TWEENER - IE 10 */
      display: -webkit-box;
      /* OLD - iOS 6-, Safari 3.1-6 */
      display: -webkit-flex;
      align-items: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -webkit-flex-align: center;
      -webkit-box-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      justify-content: space-around;
      height: 35px; }
      #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body .actions .action,
      #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body .actions .action {
        display: inline-block;
        width: 100%;
        height: 20px; }
        #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body .actions .action button,
        #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body .actions .action button {
          box-shadow: none;
          border: none;
          border-radius: 0;
          background-color: transparent;
          color: white;
          height: 35px;
          min-height: 35px;
          line-height: 35px;
          margin: -7px 0 0 0;
          min-width: 0;
          width: 100%; }
          #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body .actions .action button md-icon,
          #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body .actions .action button md-icon {
            width: 16px;
            height: 16px; }
        #page-team .hol-row .team-box .hol-box__content .function-panel .panel-body .actions .action:not(:last-child),
        #page-team .hol-row .team-box .hol-box__content .name-panel .panel-body .actions .action:not(:last-child) {
          border-right: 1px solid white; }

#page-team .hol-row .team-box .hol-box__content .team-line,
#page-team .hol-row .team-box .hol-box__content .function-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px; }
  #page-team .hol-row .team-box .hol-box__content .team-line.team-line-head, #page-team .hol-row .team-box .hol-box__content .team-line.function-line-head,
  #page-team .hol-row .team-box .hol-box__content .function-line.team-line-head,
  #page-team .hol-row .team-box .hol-box__content .function-line.function-line-head {
    padding: 0 10px;
    font-size: 0.9em; }
  #page-team .hol-row .team-box .hol-box__content .team-line .line-title,
  #page-team .hol-row .team-box .hol-box__content .function-line .line-title {
    flex: 2.4;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
  #page-team .hol-row .team-box .hol-box__content .team-line .team-line-countTODO,
  #page-team .hol-row .team-box .hol-box__content .team-line .team-line-countFROZEN,
  #page-team .hol-row .team-box .hol-box__content .function-line .team-line-countTODO,
  #page-team .hol-row .team-box .hol-box__content .function-line .team-line-countFROZEN {
    width: 35px;
    text-align: center; }
  #page-team .hol-row .team-box .hol-box__content .team-line .team-line-countTODO,
  #page-team .hol-row .team-box .hol-box__content .function-line .team-line-countTODO {
    color: #202739; }
  #page-team .hol-row .team-box .hol-box__content .team-line .team-line-countFROZEN,
  #page-team .hol-row .team-box .hol-box__content .function-line .team-line-countFROZEN {
    color: #ff0036;
    font-weight: bold; }
  #page-team .hol-row .team-box .hol-box__content .team-line .team-line-onDuty,
  #page-team .hol-row .team-box .hol-box__content .function-line .team-line-onDuty {
    flex: 1;
    text-align: right; }
  #page-team .hol-row .team-box .hol-box__content .team-line .function-line-name,
  #page-team .hol-row .team-box .hol-box__content .function-line .function-line-name {
    width: 50%; }
  #page-team .hol-row .team-box .hol-box__content .team-line .function-line-affectedFunctions,
  #page-team .hol-row .team-box .hol-box__content .function-line .function-line-affectedFunctions {
    width: 50%;
    text-align: right; }

#page-team .hol-row .detail-col {
  height: 100%;
  padding-left: 8px;
  padding-right: 0px; }
  #page-team .hol-row .detail-col .detail-col-wrapper {
    height: 100%; }
    #page-team .hol-row .detail-col .detail-col-wrapper > div {
      height: 100%;
      padding-left: 10px;
      padding-right: 0; }
      #page-team .hol-row .detail-col .detail-col-wrapper > div:first-child {
        padding-left: 0; }
  #page-team .hol-row .detail-col .detail-item-loading {
    text-align: center; }
  #page-team .hol-row .detail-col .crisis-image-container {
    height: calc(100% / 3); }
    #page-team .hol-row .detail-col .crisis-image-container .crisis-image {
      height: 100%;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
  #page-team .hol-row .detail-col .right-columns-container {
    height: calc(100% * 2 / 3);
    padding-top: 10px; }
    #page-team .hol-row .detail-col .right-columns-container .hol-box__content .iscroll-wrapper {
      height: 100%; }
      #page-team .hol-row .detail-col .right-columns-container .hol-box__content .iscroll-wrapper ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        #page-team .hol-row .detail-col .right-columns-container .hol-box__content .iscroll-wrapper ul li {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          justify-content: space-between;
          height: 38px;
          font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif;
          font-size: 14px;
          border-bottom: 1px solid #e7e7e7;
          padding: 0 10px; }
          #page-team .hol-row .detail-col .right-columns-container .hol-box__content .iscroll-wrapper ul li .line-center {
            flex: 1; }
          #page-team .hol-row .detail-col .right-columns-container .hol-box__content .iscroll-wrapper ul li .line-right {
            text-align: right; }
  #page-team .hol-row .detail-col .tasks-box .iscroll-wrapper .iscroll-scroller {
    padding: 2px; }
  #page-team .hol-row .detail-col .tasks-box .iscroll-wrapper .tasks {
    margin-left: 0;
    margin-right: 0;
    border: 1px solid #c9cbcb;
    border-bottom: none; }
  #page-team .hol-row .detail-col .members-box .iscroll-wrapper .iscroll-scroller {
    padding: 2px; }
  #page-team .hol-row .detail-col .members-box .iscroll-wrapper .name-panel {
    margin: 2px 0; }
  #page-team .hol-row .detail-col .members-box .iscroll-wrapper .funcUsers {
    margin-left: 0px;
    margin-right: 0px;
    list-style: none;
    padding: 0; }
    #page-team .hol-row .detail-col .members-box .iscroll-wrapper .funcUsers .panel-heading {
      background-color: #2a4057;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      justify-content: space-between;
      padding: 10px; }
      #page-team .hol-row .detail-col .members-box .iscroll-wrapper .funcUsers .panel-heading.affected {
        color: #2a4057;
        background-color: #d8f0fc; }
    #page-team .hol-row .detail-col .members-box .iscroll-wrapper .funcUsers .chevron-block {
      margin-right: 10px; }
      #page-team .hol-row .detail-col .members-box .iscroll-wrapper .funcUsers .chevron-block .icon.icon-quit {
        transition: transform 300ms ease-in-out;
        font-size: 0.7em;
        font-weight: bold;
        display: inline-block; }
        #page-team .hol-row .detail-col .members-box .iscroll-wrapper .funcUsers .chevron-block .icon.icon-quit.add {
          transform: rotate(45deg); }
        #page-team .hol-row .detail-col .members-box .iscroll-wrapper .funcUsers .chevron-block .icon.icon-quit.remove {
          transform: rotate(0deg); }

@keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
        #page-team .hol-row .detail-col .members-box .iscroll-wrapper .funcUsers .chevron-block .icon.icon-quit.working {
          animation: rotating 1s ease-in-out infinite; }

#page-team .tasks {
  list-style: none;
  padding: 0; }
  #page-team .tasks > li {
    border-bottom: 1px solid #e7e7e7;
    position: relative;
    background-color: white;
    transition: background-color 0.2s ease-in;
    transform-origin: 100% 0;
    font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center; }
    #page-team .tasks > li.hol-clickable, #page-team .tasks > li.hol-panel-md {
      margin: 2px; }
    #page-team .tasks > li.TODO.orange .title {
      border-color: #f4902f; }
    #page-team .tasks > li.TODO.orange .order {
      color: #f4902f; }
    #page-team .tasks > li.DONE {
      background-color: #e3fccf; }
      #page-team .tasks > li.DONE .title {
        border-color: #00c179; }
      #page-team .tasks > li.DONE .order {
        color: #00c179; }
    #page-team .tasks > li.FROZEN .title {
      border-color: #ff0036; }
    #page-team .tasks > li.FROZEN .order {
      color: #ff0036; }
    #page-team .tasks > li.NOT_APPLICABLE {
      background-color: #dedede; }
      #page-team .tasks > li.NOT_APPLICABLE .title {
        border-color: #aaaaaa; }
      #page-team .tasks > li.NOT_APPLICABLE .order,
      #page-team .tasks > li.NOT_APPLICABLE .title {
        color: #aaaaaa; }

@keyframes highlight_anim {
  0% {
    opacity: 0.6; }
  100% {
    opacity: 0.9; } }
    #page-team .tasks > li.highlight {
      animation: 0.3s highlight_anim infinite; }
    #page-team .tasks > li .order {
      padding: 10px 5px;
      font-size: 28px;
      transition: color 0.5s ease-in;
      text-align: right;
      min-width: 102px;
      max-width: 102px;
      white-space: nowrap;
      overflow: hidden;
      height: 100%; }
      #page-team .tasks > li .order .edit {
        font-size: 0.7em; }
      #page-team .tasks > li .order .label-short-title {
        font-size: 0.7em; }
      #page-team .tasks > li .order .label-order {
        font-size: 0.9em; }
    #page-team .tasks > li .title {
      border-left: 6px solid #c9cbcb;
      min-height: 60px;
      display: flex;
      transition: border-color 0.5s ease-in;
      padding: 10px;
      color: black;
      font-weight: 400;
      vertical-align: middle;
      flex: 1; }
      #page-team .tasks > li .title a {
        border: 1px solid #e7e7e7;
        display: inline-block;
        padding: 4px 40px 2px 6px;
        background-image: url("/assets/images/external-link.svg");
        background-repeat: no-repeat;
        background-position-x: 97%;
        background-position-y: center;
        background-size: 20px 20px;
        margin-right: 5px;
        margin-top: 8px; }
        #page-team .tasks > li .title a:hover {
          background-color: white;
          border-color: #dddddd;
          text-decoration: underline; }
        #page-team .tasks > li .title a.ng-click-active {
          border-color: #dddddd;
          background-color: rgba(200, 200, 200, 0.8); }
      #page-team .tasks > li .title .title-wrapper {
        display: flex;
        display: -moz-box;
        /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;
        /* TWEENER - IE 10 */
        display: -webkit-box;
        /* OLD - iOS 6-, Safari 3.1-6 */
        display: -webkit-flex;
        align-items: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-flex-align: center;
        -webkit-box-align: center;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        font-size: 1.2em; }
        #page-team .tasks > li .title .title-wrapper ul li,
        #page-team .tasks > li .title .title-wrapper ol li {
          border: none;
          background-color: transparent; }
    #page-team .tasks > li.item-infiniscroll {
      text-align: center;
      border: none;
      padding: 20px;
      background-color: transparent;
      transition: all 0s; }

.createMemberModal .ng-invalid,
.duplicateFunctionModal .ng-invalid {
  border-color: #ff0036 !important; }

.createMemberModal .loading-overlay,
.duplicateFunctionModal .loading-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(33, 33, 33, 0.6);
  display: flex;
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-flex-align: center;
  -webkit-box-align: center;
  justify-content: center;
  -webkit-justify-content: center; }

.createMemberModal .explained,
.createMemberModal .error,
.duplicateFunctionModal .explained,
.duplicateFunctionModal .error {
  font-style: normal;
  font-weight: 200;
  display: block;
  font-size: 1em;
  margin: 24px 0;
  text-align: center; }
  .createMemberModal .explained.error,
  .createMemberModal .error.error,
  .duplicateFunctionModal .explained.error,
  .duplicateFunctionModal .error.error {
    color: red; }

.createMemberModal .external-box .help-block,
.duplicateFunctionModal .external-box .help-block {
  margin-left: 40px;
  margin-top: -14px; }

.team .name .material-icons {
  margin-left: 5px;
  height: 25px;
  vertical-align: middle; }

.loadingContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-flex-align: center;
  -webkit-box-align: center;
  justify-content: center;
  -webkit-justify-content: center; }

.full-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c8c8c8;
  z-index: 50;
  transition: opacity 0.5s;
  opacity: 0.8; }
  .full-loader.ng-enter {
    opacity: 0; }
    .full-loader.ng-enter.ng-enter-active {
      opacity: 0.8; }
  .full-loader.ng-leave {
    opacity: 0.8; }
    .full-loader.ng-leave.ng-leave-active {
      opacity: 0; }

.iscroll-shadow-container {
  position: absolute;
  bottom: -5px;
  top: 0;
  left: -5px;
  right: -5px;
  overflow: hidden;
  pointer-events: none; }
  .iscroll-shadow-container.no-scroll {
    display: none; }
  .iscroll-shadow-container .iscroll-shadow {
    height: 40px;
    width: 100%;
    position: absolute;
    transition: opacity, background 500ms ease-in-out;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b7b7b7+0,2a4057+99&0.4+0,0+73 */
    background: -moz-radial-gradient(ellipse at center, rgba(183, 183, 183, 0.4) 0%, rgba(79, 95, 112, 0) 73%, rgba(42, 64, 87, 0) 99%);
    /* FF3.6-15 */
    background: -webkit-radial-gradient(ellipse at center, rgba(183, 183, 183, 0.4) 0%, rgba(79, 95, 112, 0) 73%, rgba(42, 64, 87, 0) 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, rgba(183, 183, 183, 0.4) 0%, rgba(79, 95, 112, 0) 73%, rgba(42, 64, 87, 0) 99%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66b7b7b7', endColorstr='#002a4057',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
    .iscroll-shadow-container .iscroll-shadow.bottom {
      bottom: -20px; }
    .iscroll-shadow-container .iscroll-shadow.top {
      top: -20px; }
    .iscroll-shadow-container .iscroll-shadow.hidden {
      opacity: 0; }
    .iscroll-shadow-container .iscroll-shadow.full {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3f6185+0,2a4057+100&0.55+0,0+68 */
      background: -moz-radial-gradient(ellipse at center, rgba(63, 97, 133, 0.55) 0%, rgba(49, 75, 102, 0) 68%, rgba(42, 64, 87, 0) 100%);
      /* FF3.6-15 */
      background: -webkit-radial-gradient(ellipse at center, rgba(63, 97, 133, 0.55) 0%, rgba(49, 75, 102, 0) 68%, rgba(42, 64, 87, 0) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(ellipse at center, rgba(63, 97, 133, 0.55) 0%, rgba(49, 75, 102, 0) 68%, rgba(42, 64, 87, 0) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8c3f6185', endColorstr='#002a4057',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }

body.hol-mobile .clickable.ng-click-active {
  opacity: 0.7; }

body.hol-mobile #main {
  position: absolute;
  left: 0;
  margin-bottom: 0;
  padding: 0; }
  body.hol-mobile #main .hol-row {
    overflow: auto;
    margin-left: 0;
    margin-right: 0;
    padding: 5px 0; }
    body.hol-mobile #main .hol-row > .col-xs-12 {
      padding: 0;
      margin-bottom: 10px;
      height: auto; }
  body.hol-mobile #main .mcc-row {
    background: white;
    padding: 0; }
    body.hol-mobile #main .mcc-row .last-importdate-container {
      width: 100%;
      max-height: 50px;
      min-height: 50px;
      font-size: 13px;
      display: flex;
      align-items: center;
      padding-left: 40px; }
      body.hol-mobile #main .mcc-row .last-importdate-container .importDate-responsive {
        margin-left: 10px; }
  body.hol-mobile #main .mcc-li {
    padding: 5px 0; }
    body.hol-mobile #main .mcc-li:first-child {
      padding-top: 0; }
  body.hol-mobile #main .hol-box .hol-box__content .iscroll-wrapper {
    position: relative;
    top: initial !important;
    left: initial;
    right: initial;
    bottom: initial; }
  body.hol-mobile #main .status-decision-container .history-title {
    font-weight: bold;
    color: #3f6185;
    margin-bottom: 10px; }

body.hol-mobile .md-dialog-container md-dialog {
  margin-left: 0;
  min-width: 100%;
  max-width: 100%;
  height: 100%; }
  body.hol-mobile .md-dialog-container md-dialog form {
    height: 100%; }
  body.hol-mobile .md-dialog-container md-dialog .occ-table .iscroll-wrapper {
    position: relative;
    top: initial !important;
    left: initial;
    right: initial;
    bottom: initial; }

body.hol-mobile .cdk-global-overlay-wrapper .cdk-overlay-pane {
  max-width: 100% !important;
  height: 100%;
  width: 100%; }
  body.hol-mobile .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-dialog-container {
    width: 100%;
    height: 100%; }
    body.hol-mobile .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-dialog-container .hol-dialog-wrapper {
      min-width: 100%;
      max-width: 100%; }
      body.hol-mobile .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-dialog-container .hol-dialog-wrapper .mat-dialog-content {
        flex: 1;
        max-height: 100%; }

body.hol-mobile .cdk-overlay-container .hol-full-modal {
  margin-left: initial;
  min-width: initial; }

.modal-markdown-editor .dialog-content textarea {
  height: 400px;
  min-height: 400px; }

button.more-small {
  margin-top: 5px;
  font-size: 12px;
  padding: 5px;
  height: 20px;
  line-height: 0px !important; }
  button.more-small .mat-button-wrapper {
    display: flex;
    align-items: center; }
    button.more-small .mat-button-wrapper span {
      margin-bottom: 1px; }

mat-button-toggle.indicator-button,
button.indicator-button {
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 11px;
  color: #7f7f7f;
  background-color: white;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  font-weight: bolder; }
  mat-button-toggle.indicator-button .mat-button-toggle-label-content,
  button.indicator-button .mat-button-toggle-label-content {
    padding: 0 3px; }
  mat-button-toggle.indicator-button.indicator-color-0,
  button.indicator-button.indicator-color-0 {
    background-color: #cd2072;
    border: 1px solid #cd2072;
    color: white; }
  mat-button-toggle.indicator-button.indicator-color-invert-0,
  button.indicator-button.indicator-color-invert-0 {
    background-color: white;
    border: 1px solid #cd2072;
    color: #cd2072; }
  mat-button-toggle.indicator-button.indicator-color-1,
  button.indicator-button.indicator-color-1 {
    background-color: #ff2600;
    border: 1px solid #ff2600;
    color: white; }
  mat-button-toggle.indicator-button.indicator-color-invert-1,
  button.indicator-button.indicator-color-invert-1 {
    background-color: white;
    border: 1px solid #ff2600;
    color: #ff2600; }
  mat-button-toggle.indicator-button.indicator-color-2,
  button.indicator-button.indicator-color-2 {
    background-color: #fb9190;
    border: 1px solid #fb9190;
    color: white; }
  mat-button-toggle.indicator-button.indicator-color-invert-2,
  button.indicator-button.indicator-color-invert-2 {
    background-color: white;
    border: 1px solid #fb9190;
    color: #fb9190; }
  mat-button-toggle.indicator-button.indicator-color-3,
  button.indicator-button.indicator-color-3 {
    background-color: #ffd479;
    border: 1px solid #ffd479;
    color: white; }
  mat-button-toggle.indicator-button.indicator-color-invert-3,
  button.indicator-button.indicator-color-invert-3 {
    background-color: white;
    border: 1px solid #ffd479;
    color: #ffd479; }
  mat-button-toggle.indicator-button.indicator-color-4,
  button.indicator-button.indicator-color-4 {
    background-color: #7f7f7f;
    border: 1px solid #7f7f7f;
    color: white; }
  mat-button-toggle.indicator-button.indicator-color-invert-4,
  button.indicator-button.indicator-color-invert-4 {
    background-color: white;
    border: 1px solid #7f7f7f;
    color: #7f7f7f; }

button.mat-icon-button.add-button {
  background-color: #3f6185;
  color: white;
  width: 35px;
  height: 35px;
  margin-top: -8px;
  margin-right: 20px; }
  button.mat-icon-button.add-button:hover {
    background-color: #110e4b; }
  button.mat-icon-button.add-button mat-icon {
    color: white;
    line-height: 18px; }

button.mat-stroked-button:not([disabled]) {
  border-color: currentColor; }

button.mat-button.hol-archive-btn,
button.mat-raised-button.hol-archive-btn {
  color: grey !important; }

button.mat-button.hol-validate-btn,
button.mat-raised-button.hol-validate-btn {
  background-color: #00c179;
  color: white;
  border-radius: 60px; }

button.mat-button.hol-validate-btn-secondary,
button.mat-raised-button.hol-validate-btn-secondary {
  background-color: #00c179;
  color: white; }

button.mat-button.hol-archive-btn-secondary,
button.mat-raised-button.hol-archive-btn-secondary {
  background-color: #d70303;
  color: white;
  border-radius: 2px;
  box-shadow: none; }

button.mat-button.hol-duplicate-btn,
button.mat-raised-button.hol-duplicate-btn {
  background-color: #3f6185;
  color: white;
  border-radius: 2px;
  box-shadow: none; }

button.mat-button.hol-cancel, button.mat-button.hol-cancel:not([disabled]):hover, button.mat-button.hol-cancel:not([disabled]).md-focused,
button.mat-raised-button.hol-cancel,
button.mat-raised-button.hol-cancel:not([disabled]):hover,
button.mat-raised-button.hol-cancel:not([disabled]).md-focused {
  color: white !important;
  background-color: #ff0036 !important; }

button.mat-button.hol-cancel:not([disabled]):hover,
button.mat-raised-button.hol-cancel:not([disabled]):hover {
  background-color: #cc002b !important; }

button.mat-button.hol-attachment-btn,
button.mat-raised-button.hol-attachment-btn {
  background-color: #3f6185;
  color: #ffffff;
  min-width: 40px;
  padding: 0px 10px; }
  button.mat-button.hol-attachment-btn.has-content,
  button.mat-raised-button.hol-attachment-btn.has-content {
    color: #3f6185;
    background-color: #ffffff;
    border: 1px solid #3f6185; }
  button.mat-button.hol-attachment-btn .mat-button-wrapper,
  button.mat-raised-button.hol-attachment-btn .mat-button-wrapper {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center; }
  button.mat-button.hol-attachment-btn .btn-label,
  button.mat-raised-button.hol-attachment-btn .btn-label {
    margin-left: 4px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis; }
  button.mat-button.hol-attachment-btn .icon-quit,
  button.mat-raised-button.hol-attachment-btn .icon-quit {
    margin-left: 4px; }

button.hol-wide {
  width: 100%; }

button.hol-rounded-btn {
  border-radius: 60px; }

mat-button-toggle-group.tab-buttons {
  height: 30px;
  text-align: center;
  vertical-align: middle;
  margin: 0 10px;
  align-items: center;
  font-size: 11pt;
  line-height: 11pt; }
  mat-button-toggle-group.tab-buttons mat-button-toggle {
    min-width: 100px;
    background: #c9cbcb;
    color: #3f6185; }
    mat-button-toggle-group.tab-buttons mat-button-toggle.mat-button-toggle-checked {
      background: #3f6185;
      color: white; }

mat-button-toggle-group.roles-buttons {
  box-shadow: none;
  font-weight: bold; }
  mat-button-toggle-group.roles-buttons .mat-button-toggle {
    border-width: 2px;
    border-style: solid;
    height: 28px;
    border-radius: 14px; }
    mat-button-toggle-group.roles-buttons .mat-button-toggle:not(:last-child) {
      margin-right: 10px; }
    mat-button-toggle-group.roles-buttons .mat-button-toggle .mat-button-toggle-label-content {
      line-height: 24px;
      padding: 0 10px; }
    mat-button-toggle-group.roles-buttons .mat-button-toggle.mat-button-toggle-disabled {
      opacity: 0.2; }

.upload-button {
  color: #3f6185;
  border: solid 1px #3f6185;
  margin: 0 5px; }
  .upload-button span {
    margin-left: 5px;
    text-transform: uppercase; }

.displayAllText {
  margin-left: 5px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 150px;
  align-content: center; }
  .displayAllText label {
    display: flex;
    align-items: center;
    height: 10px; }
    .displayAllText label strong {
      margin-right: 5px; }
    .displayAllText label md-checkbox.md-checked .md-icon {
      background-color: #3f6185 !important; }
    .displayAllText label md-checkbox.md-checked .md-ink-ripple {
      color: #3f6185 !important; }
    .displayAllText label .md-ripple-container {
      display: none !important; }
    .displayAllText label .md-container:before {
      display: none !important; }

.mat-menu-panel.indicator-menu .mat-menu-content {
  padding: 5px 5px; }

.mat-menu-panel.indicator-menu .mat-menu-item {
  padding: 0 3px; }
  .mat-menu-panel.indicator-menu .mat-menu-item:not(:last-child) {
    margin-bottom: 5px; }

/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.md-dialog-container {
  z-index: 1000; }

.uib-datepicker-popup {
  z-index: 1001; }

.full-dialog {
  left: 80px;
  right: 0;
  top: 10%;
  bottom: 10%;
  margin: auto;
  position: absolute !important;
  max-width: 1024px !important; }
  .full-dialog .mat-dialog-content {
    height: 100%; }

.mat-dialog-container {
  padding: 0 !important; }
  .mat-dialog-container .hol-dialog-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-flow: column nowrap;
    min-width: 630px; }
    .mat-dialog-container .hol-dialog-wrapper .mat-toolbar {
      background: #eeeeee;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
      padding: 16px 24px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .mat-dialog-container .hol-dialog-wrapper .mat-toolbar-alt {
        background: #ffffff;
        box-shadow: none;
        padding: 16px 24px 16px 44px; }
      .mat-dialog-container .hol-dialog-wrapper .mat-toolbar .mat-dialog-title {
        font-family: AvenirNextLTPro-Cn, Helvetica, Arial, sans-serif;
        font-size: 20px;
        font-weight: bold;
        margin: 3px 0 0 0;
        white-space: normal; }
      .mat-dialog-container .hol-dialog-wrapper .mat-toolbar i {
        margin-right: 10px; }
      .mat-dialog-container .hol-dialog-wrapper .mat-toolbar [mat-dialog-close] {
        color: #3f6185; }
    .mat-dialog-container .hol-dialog-wrapper .mat-dialog-content {
      margin: 0;
      padding: 24px;
      overflow: auto; }
      .mat-dialog-container .hol-dialog-wrapper .mat-dialog-content .hol-dialog-section {
        margin-bottom: 20px;
        display: flex;
        flex-flow: row nowrap; }
        .mat-dialog-container .hol-dialog-wrapper .mat-dialog-content .hol-dialog-section .icon-container {
          width: 25px;
          text-align: center;
          margin-right: 10px; }
          .mat-dialog-container .hol-dialog-wrapper .mat-dialog-content .hol-dialog-section .icon-container i {
            font-size: 24px; }
        .mat-dialog-container .hol-dialog-wrapper .mat-dialog-content .hol-dialog-section .title {
          margin-bottom: 14px;
          flex: 1 100%; }
        .mat-dialog-container .hol-dialog-wrapper .mat-dialog-content .hol-dialog-section .mat-form-field-infix {
          border-top-width: 0; }
    .mat-dialog-container .hol-dialog-wrapper .mat-dialog-actions {
      margin: 0;
      padding: 16px 24px;
      box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.2);
      z-index: 1; }
      .mat-dialog-container .hol-dialog-wrapper .mat-dialog-actions .acl-selector {
        width: 100%;
        border-bottom: 1px solid #c9c9c9;
        margin: -16px -24px 16px -24px;
        padding: 8px 24px;
        box-sizing: content-box; }
      .mat-dialog-container .hol-dialog-wrapper .mat-dialog-actions .fill-space {
        flex: 1 1 auto; }
      .mat-dialog-container .hol-dialog-wrapper .mat-dialog-actions > *:not(:first-child) {
        margin-left: 14px; }

mat-dialog-container .file-and-image-upload figure img {
  max-width: 600px; }

.mat-form-field.mat-form-field-should-float.no-label .mat-form-field-label {
  display: none; }

mat-form-field.full-width {
  width: 100%; }

mat-form-field.description-form-field {
  font-weight: bold;
  font-size: 1.5em; }
  mat-form-field.description-form-field textarea {
    min-height: 27px; }

mat-form-field.risk .mat-form-field-wrapper {
  margin-right: 30px; }

mat-form-field.scenario {
  min-width: 300px; }

ngx-hm-carousel.hol-carousel .transition {
  transition: all 0.3s ease-in-out !important; }

ngx-hm-carousel.hol-carousel [ngx-hm-carousel-container] {
  display: flex;
  padding-bottom: 40px; }

ngx-hm-carousel.hol-carousel .ball {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
  border: 1px solid #3f6185; }
  ngx-hm-carousel.hol-carousel .ball.selected {
    background: #3f6185; }

ngx-hm-carousel.hol-carousel .direction {
  opacity: 0; }

ngx-hm-carousel.hol-carousel:hover .direction {
  opacity: 0.7; }
  ngx-hm-carousel.hol-carousel:hover .direction:hover {
    opacity: 1; }

mat-expansion-panel mat-expansion-panel-header.event-group-header .mat-expansion-indicator {
  display: none;
  color: transparent;
  background: transparent; }

mat-expansion-panel .mat-expansion-panel-body {
  padding: 0; }

mat-select.indicator-color-0 .mat-select-value {
  color: #7f7f7f; }

mat-select.indicator-color-1 .mat-select-value {
  color: #ffd479; }

mat-select.indicator-color-2 .mat-select-value {
  color: #fb9190; }

mat-select.indicator-color-3 .mat-select-value {
  color: #ff2600; }

mat-select.indicator-color-4 .mat-select-value {
  color: #cd2072; }

.mat-select-panel .mat-option.custom-option-departure.custom-option-departure {
  margin: 0;
  padding: 0 0 0 20px;
  height: 2.5rem;
  font-size: 14px; }

.mat-select-panel .mat-option.custom-option-indicators.custom-option-indicators {
  margin: 0;
  padding: 0 0 0 20px;
  height: 2.5rem;
  font-size: 14px; }
  .mat-select-panel .mat-option.custom-option-indicators.custom-option-indicators.indicator-color-0 {
    color: #7f7f7f; }
  .mat-select-panel .mat-option.custom-option-indicators.custom-option-indicators.indicator-color-1 {
    color: #ffd479; }
  .mat-select-panel .mat-option.custom-option-indicators.custom-option-indicators.indicator-color-2 {
    color: #fb9190; }
  .mat-select-panel .mat-option.custom-option-indicators.custom-option-indicators.indicator-color-3 {
    color: #ff2600; }
  .mat-select-panel .mat-option.custom-option-indicators.custom-option-indicators.indicator-color-4 {
    color: #cd2072; }

.cdk-overlay-connected-position-bounding-box {
  z-index: 10001; }

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  z-index: 1000000; }

@media only print {
  .cdk-global-scrollblock.cdk-global-scrollblock {
    position: initial; }
  .left-menu,
  header-component,
  .form-group,
  button,
  nav,
  .hide-for-print {
    display: none !important; }
  #main {
    left: 0; }
  .iscroll-wrapper,
  .hol-box {
    overflow: visible !important;
    position: relative !important; }
  .hol-row {
    padding: 0 !important; }
  .time-slot {
    page-break-after: always; }
  a[href]:after {
    content: none !important; }
  html {
    height: auto !important; }
  body {
    height: auto !important;
    overflow: scroll !important; }
    body #main {
      position: relative !important;
      left: 0; }
      body #main [ui-view] {
        height: auto !important; }
        body #main [ui-view] .page {
          height: auto !important; } }
