@import 'variables';

@mixin colorButton($backgroundColor, $color: white) {
  &,
  &:not([disabled]):hover,
  &:not([disabled]).md-focused {
    color: $color !important;
    background-color: $backgroundColor !important;
  }
  &:not([disabled]):hover {
    background-color: darken($backgroundColor, 10%) !important;
  }
}

button.md-raised,
md-radio-button.md-raised {
  &.md-button:not(.md-icon-button) {
    padding: 0 $content-padding;
    md-icon {
      margin-right: 5px;
    }
  }

  &.md-button,
  &.md-icon-button {
    font-weight: 600;
    &:not(.ng-leave) {
      transition: opacity 0.2s, background-color 0.2s;
    }
    &,
    &:not([disabled]):hover,
    &:not([disabled]).md-focused {
      color: white;
      background-color: $primary-color;
    }
    &:not([disabled]):hover {
      background-color: darken($primary-color, 10%);
    }
    &.inactive,
    &:disabled {
      opacity: 0.3;
    }

    &.hol-crisis {
      @include colorButton($crisis-color);
    }
    &.hol-training {
      @include colorButton($training-color);
    }
    &.hol-invert {
      @include colorButton(white, $primary-color);
      border: 1px solid $primary-color;
    }
    &.hol-invert-no-border {
      @include colorButton($background-secondary, $primary-color);
    }
    &.hol-frozen {
      @include colorButton($frozen-color);
    }
    &.hol-done {
      @include colorButton($done-color);
    }
    &.hol-todo {
      @include colorButton($todo-color);
    }
    &.hol-backtodo {
      @include colorButton($backtodo-color);
    }
    &.hol-online {
      @include colorButton($online-color);
    }
    &.hol-offline {
      @include colorButton($offline-color);
    }
    &.hol-available-offline {
      @include colorButton($available-offline-color);
    }
    &.hol-validate {
      @include colorButton($validate-color);
    }
    &.hol-cancel {
      @include colorButton($cancel-color);
    }
  }
}

button.hol-delete {
  color: $error-color;
  font-size: 22px;
}

button.hol-save {
  background-color: $hol-green;
  color: white;
}

.dropdown {
  .caret {
    margin-left: 5px;
    transition: 0.3s;
    transform-origin: 50% 50%;
    transform: scaleY(1);
  }
  &.open {
    .caret {
      transform: scaleY(-1);
    }
  }
}

.tag-selector {
  button.mat-raised-button {
    margin-bottom: $content-padding;
  }

  button.mat-button {
    align-self: start;
  }
}

.mat-icon-button:not(.mat-calendar-previous-button).mat-icon-button:not(.mat-calendar-previous-button),
.mat-icon-button:not(.mat-calendar-next-button).mat-icon-button:not(.mat-calendar-next-button),
.md-icon-button.md-icon-button {
  width: 31px;
  height: 31px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: auto;
  border-radius: 50%;
}

.mat-calendar-previous-button.mat-calendar-previous-button,
.mat-calendar-next-button.mat-calendar-next-button {
  width: 40px !important;
  height: 40px !important;
}
