@import '~@angular/material/theming';
@import '~src/styles/ui-kit/variables';
@import '~src/styles/new-ui-kit/variables';
// Plus imports for other components in your app.

// palettes generated from http://mcg.mbitson.com/
$md-holis-primary: (
  50: #e8ecf0,
  100: #c5d0da,
  200: #9fb0c2,
  300: #7990aa,
  400: #5c7997,
  500: #3f6185,
  600: #39597d,
  700: #314f72,
  800: #294568,
  900: #1b3355,
  A100: #94beff,
  A200: #619eff,
  A400: #2e7fff,
  A700: #1470ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$md-holis-accent: (
  50: #fef2e6,
  100: #fcdec1,
  200: #fac897,
  300: #f7b16d,
  400: #f6a14e,
  500: #f4902f,
  600: #f3882a,
  700: #f17d23,
  800: #ef731d,
  900: #ec6112,
  A100: #ffffff,
  A200: #ffeee6,
  A400: #ffccb3,
  A700: #ffbb9a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-holis-error: (
  50: #ffe0e7,
  100: #ffb3c3,
  200: #ff809b,
  300: #ff4d72,
  400: #ff2654,
  500: #ff0036,
  600: #ff0030,
  700: #ff0029,
  800: #ff0022,
  900: #ff0016,
  A100: #ffffff,
  A200: #fff2f3,
  A400: #ffbfc2,
  A700: #ffa6aa,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$holis-app-primary: mat-palette($md-holis-primary);
$holis-app-accent: mat-palette($md-holis-accent);

// The warn palette is optional (defaults to red).
$holis-app-warn: mat-palette($md-holis-error);

// Create the theme object (a Sass map containing all of the palettes).
$holis-app-theme: mat-light-theme($holis-app-primary, $holis-app-accent, $holis-app-warn);

$custom-typography: mat-typography-config(
  $font-family: 'Nunito',
  $display-4: mat-typography-level(112px, 112px, 300),
  $display-3: mat-typography-level(56px, 56px, 400),
  $display-2: mat-typography-level(45px, 48px, 400),
  $display-1: mat-typography-level(34px, 40px, 400),
  $headline: mat-typography-level(24px, 32px, 400),
  $title: mat-typography-level(20px, 32px, 500),
  $subheading-2: mat-typography-level(16px, 28px, 400),
  $subheading-1: mat-typography-level(15px, 24px, 400),
  $body-2: mat-typography-level(14px, 24px, 500),
  $body-1: mat-typography-level(14px, 20px, 400),
  $caption: mat-typography-level(12px, 20px, 400),
  $button: mat-typography-level(14px, 14px, 500),
  $input: mat-typography-level(inherit, 1.125, 400),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($holis-app-theme);

// Define an alternate dark theme. => /!\ NEW HOLIS THEME - TODO - NEW DESIGN DO REFECTO
// Dark theme & palette colors
$holis-new-primary: mat-palette($md-hol-primary2, 900, A100);
$holis-new-accent: mat-palette($md-hol-accent2, 900);
$holis-new-warn: mat-palette($md-holis-warn2, 700);
$holis-new-theme: mat-light-theme($holis-new-primary, $holis-new-accent, $holis-new-warn);
// Include the dark color styles (forr now new holis design) inside of a block with a CSS class.
.new-holis-theme {
  $custom-typography: mat-typography-config(
    $font-family: 'AvenirNextLTPro-Cn',
    $display-4: mat-typography-level(112px, 112px, 300),
    $display-3: mat-typography-level(56px, 56px, 400),
    $display-2: mat-typography-level(45px, 48px, 400),
    $display-1: mat-typography-level(34px, 40px, 400),
    $headline: mat-typography-level(24px, 32px, 400),
    $title: mat-typography-level(20px, 32px, 500),
    $subheading-2: mat-typography-level(16px, 28px, 400),
    $subheading-1: mat-typography-level(15px, 24px, 400),
    $body-2: mat-typography-level(14px, 24px, 500),
    $body-1: mat-typography-level(14px, 20px, 400),
    $caption: mat-typography-level(12px, 20px, 400),
    $button: mat-typography-level(12px, 16px, 600),
    $input: mat-typography-level(inherit, 1.125, 400),
  );
  @include mat-core($custom-typography);
  @include angular-material-theme($holis-new-theme);
}
