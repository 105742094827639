@import '../ui-kit/variables';
@import '../mixins';

.md-sidenav-left {
  .header {
    height: 16.67%;
    .company-logo {
      margin-left: 2.4 * $content-padding;
      margin-top: $content-padding;
      margin-bottom: $content-padding / 2;
      height: calc(100% - 15px);
    }
    .close-button {
      position: absolute;
      top: 2 * $content-padding;
      right: $content-padding;
      color: $primary-color;
      padding: 0;
      &:hover {
        background-color: #cccccc;
      }

      .mat-icon {
        @include md-icon-size(40px);
      }
    }
  }
  .line {
    background: #d8d8d8;
    width: 85%;
    height: 1px;
    margin: 0 auto;
  }
  .vertical-menu {
    padding: 1.2 * $content-padding 0;
    a {
      display: block; /* Make the links appear below each other */
      padding: 1.2 * $content-padding 4.8 * $content-padding; /* Add some padding */
      text-decoration: none; /* Remove underline from links */
      font-family: $condensed-font-family;
      font-weight: bold;
      font-size: 22px;
      height: 4.8 * $content-padding;
    }
  }
  .menu-occ {
    color: $occ-color;
  }
  .menu-ecl {
    color: $ecl-color;
  }
  .menu-ops {
    color: $ops-color;
  }
  .menu-crew {
    color: $crew-color;
  }
  .menu-mcc {
    color: $mcc-color;
  }
  a:hover {
    background: #bdbdbd; /* Dark grey background on mouse-over */
  }
  a.active {
    background-color: #4caf50; /* Add a green color to the "active/current" link */
    color: white;
  }
  .vertical-menu-settings a {
    display: flex; /* Make the links appear below each other */
    align-items: center;
    padding: 1.4 * $content-padding 4.8 * $content-padding; /* Add some padding */
    text-decoration: none; /* Remove underline from links */
    font-family: $condensed-font-family;
    font-weight: lighter;
    font-size: 13px;
    color: #2d2d2d;
    height: 4.3 * $content-padding;

    i,
    img {
      position: absolute;
      right: 4 * $content-padding;

      &.managers {
        right: 3.3 * $content-padding;
      }
    }
  }
}

md-sidenav,
md-backdrop {
  position: fixed;
}

.isOpen {
  overflow: hidden;
}
