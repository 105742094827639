@import '../ui-kit/variables';
@import '../new-ui-kit/variables';
@import '../mixins';

app-ocl-decisions-list-item > div,
app-ocl-logbook-list-item > div,
app-ops-decisions-list-item > div,
app-ops-logbook-list-item > div,
app-goc-decisions-list-item > div,
app-goc-logbook-list-item > div {
  position: relative;
  display: block;
  .occ-linked-button {
    top: 2px;
    right: 2px;
  }
}

app-ocl-event-item > div,
app-ops-event-item > div,
app-goc-event-item > div {
  position: relative;
  display: block;
  padding-right: 20px;

  .occ-linked-button {
    top: 5px;
    right: 5px;
  }
}

.occ-linked-button {
  &.selected {
    border: solid 1px $primary-color;
    background: $primary-color;
  }

  &:disabled {
    border: solid 1px #80808096;
    background: #80808096;
  }

  background: white;
  border: solid 1px #80808096;

  position: absolute;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .occ-linked-icon {
    width: 13px !important;
    height: 13px !important;
    transform: rotate(-90deg);
    transform-origin: center center;
  }
}
.pinned-item-button {
  // TODO - A supprimer qd tout sera remplacé
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #eaeaea;
  .mat-checkbox-inner-container {
    display: none !important;
  }
  .pinned-item-icon {
    width: 27px;
    margin-left: 3px;
    margin-top: 5px;
  }
}

.pinned-item-btn {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $hol-light-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $hol-dark_grey;
  font-size: 18px;
  .mat-checkbox-inner-container {
    display: none !important;
  }
  label {
    margin-bottom: 0;
  }
  &.isPinned {
    color: mat-color($md-hol-primary2, 900);
    background-color: mat-color($md-hol-primary2, 50);
  }
  .pinned-item-icon {
    width: 27px;
    margin-left: 3px;
    margin-top: 5px;
  }
}

#page-occ-dashboard,
#page-flt-dashboard {
  .status-decision-container {
    display: block;
    height: calc(50% - #{$content-padding} / 2);
    margin-bottom: $content-padding;
  }

  .operation-logbook-container {
    display: block;
    height: calc(50% - #{$content-padding} / 2);
  }
}

#page-occ-dashboard,
#page-ecl-dashboard,
#page-flt-dashboard {
  .page-content {
    display: flex;
    flex-direction: column;
    flex: 1;

    .occ-header {
      .upload-flight {
        color: $primary-color;
        border: solid 1px $primary-color;
        margin: 0 5px;

        span {
          margin-left: 5px;
          text-transform: uppercase;
        }
      }

      .displayAllText {
        margin-left: 5px;
        display: flex;
        align-items: center;
        height: 100%;
        width: 150px;
        align-content: center;

        label {
          display: flex;
          align-items: center;
          height: 10px;

          strong {
            margin-right: 5px;
          }

          md-checkbox.md-checked .md-icon {
            background-color: rgb(63, 97, 133) !important;
          }

          md-checkbox.md-checked .md-ink-ripple {
            color: rgb(63, 97, 133) !important;
          }

          .md-ripple-container {
            display: none !important;
          }

          .md-container:before {
            display: none !important;
          }
        }
      }
    }

    .content-addon {
      height: 100%;
      .hol-row {
        height: 100%;
      }
    }
  }

  .hol-search-container {
    .icon {
      height: 12px;
    }
  }

  .item-text-content {
    flex: 1;
    margin-top: 2px;
  }

  .on-duty-managers-title {
    font-weight: bold;
  }

  .box-container {
    height: 100%;
    padding: 0;

    &:not(:last-child) {
      padding-right: $content-padding;
    }
  }
}

.occ-table {
  font-family: $condensed-font-family;
  min-height: 50px;
  width: 100%;

  .col-at {
    line-height: 1em;
    margin-right: 5px;
    color: $text-color--light;
    text-align: center;

    > * {
      white-space: pre;
    }
  }

  .col-info {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .col-next-info {
    line-height: 1em;
    margin-left: 5px;
    text-align: center;

    &.almost-elapsed {
      font-weight: bold;
      color: $warning-color;
    }

    &.elapsed {
      font-weight: bold;
      color: $error-color;
    }

    > * {
      white-space: pre;

      &:before {
        content: '►';
      }
    }
  }

  .occ-table-header {
    padding: 0 12px;
  }

  .col-done {
    color: lighten($text-color--light, 20%);
    transition: color 0.2s;

    button {
      padding: 0;
      height: 40px;
      line-height: 0;
      min-height: 0;
      margin: -20px 0;
    }

    &.hol-done button i {
      font-weight: bold;
    }
  }

  .iscroll-wrapper {
    > * > .hol-list {
      > li:first-child {
        .col-info {
          white-space: normal;
          word-break: normal;
        }
      }
    }
  }

  .col-attachements {
    text-align: right;

    &.next-info {
      display: flex;
      align-items: center;
    }
  }

  .col-linked-event {
    color: $primary-color;
    background: $background-secondary;
    padding: 5px 10px 3px 10px;
    margin-top: -5px;
    margin-right: 5px;
    line-height: 1em;
  }
}

.modal-dashboard-event-add {
  .disabled-form {
    opacity: 0.1;
  }

  .scenario-container {
    align-items: flex-end;

    .md-button {
      margin-bottom: 0;
    }
  }
}

.modal-dashboard-event-edit {
  .dialog-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 70vh;
    position: relative;

    .iscroll-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      overflow: auto;
    }

    .occ-table {
      position: relative;
    }

    .description {
      font-weight: bold;
      font-size: 1.5em;
    }
  }

  .row {
    display: flex;
    width: 100;
    justify-content: center;

    .archive {
      font-weight: bold;
      display: block;
      margin: 2em auto 1em;
      padding: 3em;
      background-color: $background-secondary;
    }
  }
}

.occ-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px;

  tag-selector {
    width: calc(100% - 400px);
  }

  app-tag-selector {
    width: calc(100% - 400px);
  }

  .md-button {
    width: 100px;
    margin: 0;
  }

  md-button {
    margin-top: 0;
    height: 36px;
  }
}

.modal-dashboard-occ-status-decisions,
.modal-dashboard-occ-logbook {
  .linked-flight {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .top {
      display: flex;
    }

    .bottom {
      display: flex;
      align-items: center;
      align-content: center;

      .date {
        width: 200px;
        display: flex;
        margin-right: 50px;

        .input-group-addon {
          height: 34px;
          width: 44px;
        }
      }

      .flight {
        display: flex;
        width: 300px;

        .input-group-addon {
          width: 44px;
          display: flex;
          align-items: center;
        }

        md-autocomplete[disabled] {
          background: rgb(245, 245, 245);
        }

        md-autocomplete {
          width: 250px;
          background: white;

          md-autocomplete-wrap {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.item-dasboard-occ-flight-linked {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;

  span {
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px;
    height: 20px;
    line-height: 12px;
    border: 1px solid #3f6185;
    color: #3f6185;
    font-weight: bold;
    font-size: 12px;
  }
}

.linked-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title-read-only {
    margin-bottom: 5px;
    font-weight: bold;
    display: flex;
  }

  &__top {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    .left {
      width: 90%;

      mat-form-field {
        width: 100%;
      }
    }

    .right {
      width: 30px;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__row {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      border: solid 1px $border-color;
      padding: 0 5px;
      margin-bottom: 5px;
      min-height: 35px;

      .left {
        width: 90%;
        color: $primary-color;
      }

      .right {
        width: 30px;

        mat-icon {
          font-size: 18px;
        }
      }
    }
  }
}

.item-dashboard-applicability {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;

  app-flt-applicability-summary {
    > div {
      text-transform: uppercase;
      display: table;
      padding: 5px;
      height: 20px;
      line-height: 12px;
      border: 1px solid #3f6185;
      background-color: #fff;
      color: #3f6185;
      font-weight: bold;
      font-size: 12px;
    }
    &.selected {
      > div {
        background-color: #3f6185;
        color: #fff;
      }
    }
  }
}
