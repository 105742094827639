@import '~src/styles/new-ui-kit/variables';
@import '../mixins';

.hol-search-box {
  position: relative;

  input {
    padding-right: 44px;
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid $primary-color;
    border-radius: 0;

    &:focus {
      outline: none !important;
    }
  }

  .form-control-feedback {
    height: 100%;
    text-align: right;
    width: auto;
    pointer-events: initial;

    i {
      padding: 3px;
      vertical-align: super;
    }
  }
}

.hol-search-container {
  height: 50px;
  max-width: 300px;
  margin: auto;
  position: relative;
  &--align-left {
    margin-left: 20px;
  }
  &.sm {
    height: 32px;
    max-width: 250px;

    input {
      border-radius: 20px;
    }
  }

  input {
    &:focus:not(.mat-input-element) {
      outline: none !important;
    }

    width: 100%;
    height: 100%;
    border-radius: 25px;
    border: 1px solid $primary-color;
    padding-left: 10px;
    padding-right: 35px;
    background: #f2f2f2;

    &:not(:placeholder-shown),
    &:focus {
      background: white;
    }

    &::placeholder {
      color: $primary-color;
      font-family: $default-font-family;
    }
  }

  mat-icon,
  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    color: $primary-color;
    margin: auto 13px auto 0;
  }
}

.data-time-input {
  position: relative;

  input.form-control {
    display: inline-block;
  }

  table {
    vertical-align: middle;
    display: inline-block;
  }

  .uib-daypicker {
    button.uib-title,
    td.uib-day button {
      border: none;
    }
  }
}

.date-time-input-responsive {
  display: inline-flex;
  margin-bottom: 3.2 * $content-padding;

  .date {
    max-width: 130px;
    min-widht: 130px;
    margin-right: 10px;
  }

  .time {
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    margin-right: 10px;
  }
}

.ng-invalid {
  &.ng-dirty,
  &.ng-touched {
    &:not(:focus) {
      border-color: $error_color !important;
    }
  }
}

.hol-show-errors {
  .ng-invalid:not(:focus) {
    border-color: $error_color !important;
  }
}

textarea {
  resize: none;
}

input[type='time'],
input[type='date'] {
  &::-webkit-clear-button {
    /* Removes blue cross */
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }
}

md-radio-group {
  &.md-focused:not(:empty) .md-checked .md-container:before {
    background-color: rgba(0, 0, 0, 0.12);
  }

  &:not([disabled]) {
    md-radio-button {
      .md-container {
        .md-off {
          border-color: rgba(0, 0, 0, 0.54);
        }

        .md-on {
          background-color: $primary-color;
        }
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.7;

        .md-container {
          cursor: not-allowed;
        }
      }
    }
  }

  &.hol-radio-btn-group {
    margin-bottom: $content-padding;
    display: flex;
    flex-wrap: wrap;

    &.ng-not-empty {
      md-radio-button:not(.md-checked):not(:hover):not([disabled]) {
        opacity: 0.7;
      }
    }

    md-radio-button[disabled]:not(.md-checked) {
      opacity: 0.3;
      cursor: not-allowed;
    }

    > * {
      margin: 0 0 ($content-padding / 2) 0;
    }

    > *:not(:last-child) {
      margin-right: $content-padding;
    }

    md-radio-button {
      min-height: 1em;
      background: $background-secondary;
      margin-top: 0;

      .md-label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-transform: none;
        text-align: left;
        line-height: 1.5em;
        margin: 0;

        .text-container {
          margin: 5px 0 5px 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          * {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .md-container {
        display: none;
      }

      &.md-raised.md-checked {
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
}

input,
textarea {
  &.hol-invisible-input:not(.ng-invalid) {
    border-width: 0 !important;
    box-shadow: none !important;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    &:focus {
      border-width: 1px !important;
      background: white;
    }
  }
}

button.md-button {
  &.md-primary {
    color: $primary-color;
  }
}

.form-group.with-icon {
  position: relative;

  .icon {
    font-size: 2em;
    position: absolute;
    top: 5px;
  }

  label,
  .form-control,
  .form-value {
    margin-left: 30px;
    width: calc(100% - 30px);
  }
}

.checkbox {
  margin-top: 10px;
  margin-bottom: 10px;

  label:not(.form-check-label) {
    padding: 0;

    md-checkbox {
      margin-left: 5px;

      &.md-checked .md-icon {
        background-color: #254c6e;

        &:after {
          border-color: white;
        }
      }
    }
  }
}
